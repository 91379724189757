import {
  Segment,
  Grid,
  Input,
  Search,
  Icon,
  Button,
  Form,
  Modal,
  Divider,
  Dropdown,
} from 'semantic-ui-react';
import React, { Component } from 'react';
import Label from 'reactstrap/lib/Label';
import { debounce, get, isEmpty, find } from '../../../../utils/lodash';
import {
  activityInviteTypeValidation,
  initialValues,
} from '../../../../validations/activityInviteValidation';
import moment from 'moment';
import {
  ContractorCreate,
  VenueCreate,
  VenueUpdate,
  ContractorUpdate,
} from '../../../organization/forms';
import { RESTRICTION_TYPES, permissionTypes } from '../../../../utils/constant';
import { Formik } from 'formik';
import {
  CustomTextArea,
  UserPermissionsInput,
} from '../../../../components/form';
import {
  getOrgVenues,
  getVenueTypes,
  getOrgVenuesTypes,
  getOccupationTypes,
  getOccupation,
  getOrgContractors,
  createOrgContractor,
  updateOrgContractor,
  createOrgVenues,
  updateOrgVenues,
  assignProjectVenue,
  assignProjectContractor,
  updateProjectInputs,
  deleteProjectVenue,
  deleteProjectContractor,
  updateProjectContractorCost,
  deleteProjectPartner,
  updateProjectPartner,
  putActivity,
  getOrgPartnersByKeywords,
  assignProjectPartner,
  getGuestContractors,
  getGuestVenues,
  addActivityMember,
} from '../../../../store/actions';
import {
  CustomTable,
  CustomTooltip,
  ViewMoreText,
  // EmptyContainer
} from '../../../../components';
import {
  datePickerEndTitle,
  datePickerStartTitle,
  getPageDisable,
  toSqlDate,
  threeDotSeparator,
} from '../../../../utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../activity.scss';
import thousands from 'thousands';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {
  PartnerCreateFromActivity,
  PartnerUpdate,
  PartnerUpdateFromActivity,
} from '../../../organization/forms/contacts/partners';
import {
  isExternalLinkedActivity,
  isInternalLinkedActivity,
  isNetworkRelationship,
} from '../../../../utils/activity';
import { includes, cloneDeep } from '../../../../utils/lodash';
import { getPermissionType } from '../../../../utils/activity/userPermission';

moment.locale('en-AU');

class ContractorInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cost: thousands(props.item.cost) || '',
    };
  }

  handleChangeContractorCost(value, item) {
    var isNum = /^-?\d+\.?\d*$/.test(value);
    if (isNum) {
      this.setState({
        cost: value,
      });
    } else if (value === '') {
      this.setState({
        cost: value,
      });
    }
  }

  async onFocusContractorCost(value) {
    value = value.replace(/,/g, '');
    this.setState({
      cost: value,
    });
  }

  handleContractorCost = async (value, item) => {
    const { cost } = this.state;
    if (cost) {
      let value = cost.replace(/,/g, '');
      value = Number(value).toFixed(2);
      this.props.ContractorCost(value, item);
      this.setState({ cost: thousands(value) });
    } else if (cost === '') {
      this.props.ContractorCost(0, item);
      this.setState({ cost: 0 });
    }
  };

  render() {
    const { cost } = this.state;
    const { item, currentActivity } = this.props;

    return (
      <>
        <Input
          label={{
            basic: true,
            content: (
              <Icon
                circular
                inverted
                name={get(
                  currentActivity,
                  'currencyType.iconName',
                  'dollar sign'
                )}
                className="infoText"
              />
            ),
          }}
          labelPosition="left"
          className={
            item.contractorCategory === 'projected'
              ? 'projected-contractor-cost'
              : 'input-icon-border-right'
          }
          onChange={(e, data) =>
            this.handleChangeContractorCost(e.target.value, item.id)
          }
          onFocus={(e, data) =>
            this.onFocusContractorCost(e.target.value, item.id)
          }
          onBlur={(e, data) =>
            this.handleContractorCost(e.target.value, item.id)
          }
          value={cost}
        />
      </>
    );
  }
}

class PartnerInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cost: thousands(props.item.cost) || '',
    };
  }

  handleChangePartnerCost(value, item) {
    var isNum = /^-?\d+\.?\d*$/.test(value);
    if (isNum) {
      this.setState({
        cost: value,
      });
    } else if (value === '') {
      this.setState({
        cost: value,
      });
    }
  }

  async onFocusPartnerCost(value) {
    value = value.replace(/,/g, '');
    this.setState({
      cost: value,
    });
  }

  handlePartnerCost = async (value, item) => {
    const { cost } = this.state;
    if (cost) {
      let value = cost.replace(/,/g, '');
      value = Number(value).toFixed(2);
      this.props.PartnerCost(value, item);
      this.setState({ cost: thousands(value) });
    } else if (cost === '') {
      this.props.PartnerCost(0, item);
      this.setState({ cost: 0 });
    }
  };

  render() {
    const { cost } = this.state;
    const { item, currencyType } = this.props;

    return (
      <>
        <Input
          label={{
            basic: true,
            content: (
              <Icon
                circular
                inverted
                name={get(currencyType, 'iconName', 'dollar sign')}
                className="infoText"
              />
            ),
          }}
          labelPosition="left"
          className={
            item.supportType === 'IN_KIND'
              ? 'inKindSupport-partner-cost'
              : 'input-icon-border-right'
          }
          onChange={(e, data) => {
            this.handleChangePartnerCost(e.target.value, item.id);
          }}
          onFocus={(e, data) => {
            this.onFocusPartnerCost(e.target.value, item.id);
          }}
          onBlur={(e, data) => {
            this.handlePartnerCost(e.target.value, item.id);
          }}
          value={cost}
          disabled={isExternalLinkedActivity(item)}
        />
      </>
    );
  }
}

class ContractorView extends Component {
  constructor(props) {
    super(props);
    const { activity, actual, currentState, auth, helpTips } = this.props;
    this.state = {
      columns: [
        {
          width: 5,
          title: 'Contractor',
          render: (data) => {
            const occupationTitle = get(data, 'occupation.title', null);
            return (
              <div className="current-member-text">
                {data
                  ? data.companyName
                    ? threeDotSeparator({
                        name: data.companyName,
                        length: 45,
                        isPopUp: true,
                      })
                    : occupationTitle
                      ? threeDotSeparator({
                          name: occupationTitle,
                          length: 45,
                          isPopUp: true,
                        })
                      : null
                  : null}
                <div className="mt-1 mb-2">
                  {data.contractorCategory === 'projected' && (
                    <div className="projected-lbl">Projected</div>
                  )}
                </div>
              </div>
            );
          },
        },
        {
          width: 4,
          title: 'Address',
          render: (data) => {
            const address = data
              ? `${data.streetAddress !== null ? data.streetAddress : ''} ${
                  data.streetAddress2 !== null ? data.streetAddress2 : ''
                } ${data.suburb !== null ? data.suburb : ''} ${
                  data.state !== null ? data.state : ''
                } ${data.postCode !== null ? data.postCode : ''}`
              : '';

            return (
              <div className="current-member-text">
                {address.trim().length !== 0 ? address : ' - '}
              </div>
            );
          },
        },
        {
          width: 3,
          title: 'Contractor Cost',
          render: (data) => {
            return (
              <div className="disabled-padding pb-3">
                <Segment
                  disabled={getPageDisable(
                    activity.currentActivity,
                    auth.user,
                    currentState,
                    actual
                  )}
                >
                  <ContractorInput
                    // key={i}
                    currentActivity={activity.currentActivity}
                    item={data}
                    ContractorCost={this.handleContractorCost}
                    ContractorDelete={this.handleContractorDelete}
                    {...this.props}
                  />
                </Segment>
              </div>
            );
          },
        },
        {
          width: 2,
          render: (data) => {
            return (
              <div className="disabled-padding">
                <Segment
                  disabled={getPageDisable(
                    activity.currentActivity,
                    auth.user,
                    currentState,
                    actual
                  )}
                >
                  <div className="current-member-text">
                    <ContractorUpdate
                      selectedContractor={data}
                      {...this.props}
                      projectId={activity.currentActivity.id}
                    />
                  </div>
                </Segment>
              </div>
            );
          },
        },
        {
          width: 2,
          render: (data) => {
            return (
              <div className="disabled-padding">
                <Segment
                  disabled={getPageDisable(
                    activity.currentActivity,
                    auth.user,
                    currentState,
                    actual
                  )}
                >
                  <CustomTooltip
                    state={get(auth, 'user.options.tips')}
                    contentObject={{
                      data: helpTips,
                      key: '102',
                    }}
                    // position="bottom left"
                    // wide="very"
                  >
                    <label
                      className="activity-actions-error-custom  mb-0"
                      onClick={() => this.handleContractorDelete(data.id)}
                    >
                      Remove
                    </label>
                  </CustomTooltip>
                </Segment>
              </div>
            );
          },
        },
      ],
    };
  }

  handleContractorDelete = async (id) => {
    const { deleteProjectContractor, activity } = this.props;
    deleteProjectContractor('projectInput', activity.currentActivity.id, id);
  };

  handleContractorCost = async (value, id) => {
    const { updateProjectContractorCost, activity } = this.props;
    const form = {
      cost: value,
    };
    updateProjectContractorCost(
      'projectInput',
      activity.currentActivity.id,
      id,
      form
    );
  };

  render() {
    const { contractors } = this.props.activity.currentActivity;
    const { columns } = this.state;

    return (
      <CustomTable
        header
        columns={columns}
        data={contractors}
        emptyMessage="No contractors"
        keyExtractor={(item, index) => item.id}
      />
    );
  }
}

class PartnerView extends Component {
  constructor(props) {
    super(props);
    const { activity, actual, currentState, auth } = this.props;
    const disabled = getPageDisable(
      activity.currentActivity,
      auth.user,
      currentState,
      actual
    );

    this.state = {
      columns: [
        {
          width: 3,
          title: 'Partner Name',
          render: (data) => {
            const fullName =
              data && `${get(data, 'firstName')} ${get(data, 'secondName')}`;
            return (
              <>
                <div className="current-member-text pl-1 pt-1">
                  {data
                    ? data.isAnonymous
                      ? 'Anonymous Donor'
                      : fullName.trim() && fullName
                    : null}
                </div>
                <div className="current-member-text pl-1 text-muted report-year-text-partner">
                  {data
                    ? data.isAnonymous
                      ? null
                      : data.organisationName && data.organisationName
                    : null}
                </div>
                {activity?.currentActivity?.parentActivity?.reportYear
                  ? isExternalLinkedActivity(data) && (
                      <div className="current-member-text pl-1 text-muted report-year-text-partner">
                        <div className="report-label">Reporting Date: </div>
                        <div className="report-label report-space">
                          {activity.currentActivity.parentActivity
                            ? moment(
                                activity.currentActivity.parentActivity
                                  .reportYear
                              ).format('DD MMM YYYY')
                            : null}
                        </div>
                      </div>
                    )
                  : null}
              </>
            );
          },
        },
        {
          width: 5,
          title: 'Description',
          render: (data) => {
            return (
              <div className="project-partner-description">
                {data.description ? (
                  <ViewMoreText length={150} text={data.description} />
                ) : (
                  '-'
                )}
              </div>
            );
          },
        },
        {
          width: 3,
          title: 'Contribution',
          render: (data) => {
            return (
              <div className="disabled-padding pb-3">
                <Segment disabled={disabled}>
                  {!isNetworkRelationship(data.supportType) && (
                    <CustomTooltip
                      state={get(auth, 'user.options.tips')}
                      content="Estimated value of partner's contribution"
                      position="right center"
                    >
                      <span className="p-0 m-0">
                        <PartnerInput
                          item={data}
                          PartnerCost={this.handlePartnerCost}
                          PartnerDelete={this.handlePartnerDelete}
                          currencyType={get(
                            activity,
                            'currentActivity.currencyType'
                          )}
                          {...this.props}
                        />
                      </span>
                    </CustomTooltip>
                  )}
                </Segment>
              </div>
            );
          },
        },
        {
          width: 1,
          render: (data) => {
            return (
              <div className="disabled-padding">
                <div className="current-member-text">
                  <PartnerUpdate
                    selectedPartner={data}
                    viewOnly={isExternalLinkedActivity(data)}
                    {...this.props}
                    isFromActivity={true}
                    projectId={activity.currentActivity.id}
                  />
                </div>
              </div>
            );
          },
        },

        {
          width: 1,
          title: (
            <div className="user-act-heading-container">
              <strong></strong>
            </div>
          ),
          render: (data) => {
            const ownerId = activity.currentActivity.owner.id;
            const members = activity.currentActivity.members;
            let isAlreadyMember = undefined;
            if (!isEmpty(data.userData)) {
              isAlreadyMember = find(
                members,
                (item) => item.userId === data.userData.id
              );
            }
            const dropDownView =
              (!data.isAnonymous &&
                isAlreadyMember === undefined &&
                ownerId === auth.user.id) ||
              !isExternalLinkedActivity(data);

            return (
              <div className="org-users-text-partner">
                <div className="owned">
                  {dropDownView ? (
                    <Dropdown
                      icon="ellipsis vertical"
                      direction="left"
                      inline
                      selectOnBlur={false}
                    >
                      <Dropdown.Menu>
                        {!data.isAnonymous && isAlreadyMember === undefined ? (
                          <Dropdown.Item>
                            <InviteActivityMembers
                              data={data}
                              form={data}
                              {...this.props}
                              toggle={() => this.toggle()}
                            />
                          </Dropdown.Item>
                        ) : null}
                        {/* Edit-user without 'takso' tag */}
                        {!isExternalLinkedActivity(data) && (
                          <Dropdown.Item>
                            <div className="segment-change">
                              <Segment disabled={disabled}>
                                <div className="current-member-text">
                                  <CustomTooltip
                                    state={get(auth, 'user.options.tips')}
                                    content="Remove partner from an activity"
                                  >
                                    <label
                                      className="lable-center"
                                      onClick={() =>
                                        this.handlePartnerDelete(data.id)
                                      }
                                    >
                                      Remove
                                    </label>
                                  </CustomTooltip>
                                </div>
                              </Segment>
                            </div>
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}
                </div>
              </div>
            );
          },
        },
      ],
    };
  }

  handlePartnerDelete = async (id) => {
    const { deleteProjectPartner, activity } = this.props;
    deleteProjectPartner('projectInput', activity.currentActivity.id, id);
  };

  handlePartnerCost = async (value, id) => {
    const { updateProjectPartner, activity } = this.props;
    const form = {
      cost: value,
    };
    updateProjectPartner('projectInput', activity.currentActivity.id, id, form);
  };

  calculateTotalContribution = () => {
    const { partners } = this.props.activity.currentActivity;
    let total = 0;
    for (const partner of partners) {
      if (partner.cost) {
        total += parseFloat(partner.cost);
      }
    }
    return total;
  };

  render() {
    const { partners, currencyType } = this.props.activity.currentActivity;
    const { columns } = this.state;
    const totalContribution = this.calculateTotalContribution();

    return (
      <CustomTable
        header
        columns={columns}
        footerName="Total Partner Contribution"
        total={totalContribution}
        data={partners}
        emptyMessage="No Partners"
        keyExtractor={(item, index) => item.id}
        currencySymbol={get(currencyType, 'sign', '$')}
      />
    );
  }
}

class InviteActivityMembers extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      searchQuery: '',
      form: {
        firstName: '',
        surName: '',
        email: '',
        message: '',
      },
      permissionType: undefined,
      radioType: undefined,
      collaborator: false,
      documentor: false,
      reportOnly: false,
      permissions: [],
      permissionError: false,
      modal: false,
      dropdownValuePermission: '',
      isCustomNotEmpty: true,
      isPermissionLabelVisible: false,
    };
  }

  componentDidMount() {
    let permissionArray = permissionTypes.map((permissionType) => {
      return {
        name: permissionType.title,
        permission: {
          read: false,
          write: false,
        },
        state: permissionType.state,
      };
    });
    this.setState({ permissionArray });
  }

  componentWillMount() {
    this.resetComponent();
  }

  resetComponent = () =>
    this.setState({
      isLoading: false,
      data: [],
      searchQuery: '',
    });

  PERMISSION_OPTIONS = [
    {
      key: 'collaborator',
      text: <span>Collaborator</span>,
      value: 'collaborator',
    },
    {
      key: 'documentor',
      text: <span>Documentor</span>,
      value: 'documentor',
    },
    {
      key: 'reportOnly',
      text: <span>Report Only</span>,
      value: 'reportOnly',
    },

    {
      key: 'custom',
      text: <span>Custom</span>,
      value: 'custom',
    },
  ];

  selectPermissionType(value, name, checked) {
    if (value === 'collaborator') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: true,
            write: RESTRICTION_TYPES.includes(permissionType.state)
              ? false
              : true,
          },
          state: permissionType.state,
        };
      });
      this.setState({
        permissionArray,
        permissionType: 'Collaborator',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'collaborator',
        isCustomNotEmpty: true,
        isPermissionLabelVisible: false,
      });
    } else if (value === 'documentor') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: permissionType.documentor ? true : false,
            write: permissionType.documentor
              ? RESTRICTION_TYPES.includes(permissionType.state)
                ? false
                : true
              : false,
          },
          state: permissionType.state,
        };
      });
      this.setState({
        permissionArray,
        permissionType: 'Documentor',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'documentor',
        isCustomNotEmpty: true,
        isPermissionLabelVisible: false,
      });
    } else if (value === 'reportOnly') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: permissionType.reportOnly ? true : false,
            write: permissionType.reportOnly
              ? RESTRICTION_TYPES.includes(permissionType.state)
                ? false
                : true
              : false,
          },
          state: permissionType.state,
        };
      });
      this.setState({
        permissionArray,
        permissionType: 'Report Only',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'reportOnly',
        isCustomNotEmpty: true,
        isPermissionLabelVisible: false,
      });
    } else if (value === 'custom') {
      let permissionArray = permissionTypes.map((permissionType) => {
        return {
          name: permissionType.title,
          permission: {
            read: false,
            write: false,
          },
          state: permissionType.state,
        };
      });

      let isCustomEmpty = permissionArray.map((item) => {
        return item.permission.read === false && item.permission.write === false
          ? true
          : false;
      });

      this.setState({
        permissionArray,
        permissionType: 'Custom',
        radioType: value,
        permissionError: false,
        dropdownValuePermission: 'custom',
        isCustomNotEmpty: includes(isCustomEmpty, false),
        isPermissionLabelVisible: false,
      });
    }
  }

  selectPermissionOption(checked, value, id, type) {
    this.setState((preState) => {
      let permissionArray = cloneDeep(preState.permissionArray);
      if (!checked && type === 'read') {
        permissionArray[id].permission.write = false;
      }
      permissionArray[id].permission[type] =
        !preState.permissionArray[id].permission[type];

      let dropdownValue = getPermissionType(permissionArray);

      let isCustomEmpty = permissionArray.map((item) => {
        return item.permission.read === false && item.permission.write === false
          ? true
          : false;
      });

      return {
        ...preState,
        permissionArray: permissionArray,
        permissionType: 'Custom',
        radioType: undefined,
        permissionError: false,
        dropdownValuePermission: dropdownValue,
        isCustomNotEmpty: includes(isCustomEmpty, false),
        isPermissionLabelVisible: false,
      };
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      permissionType: undefined,
      dropdownValuePermission: '',
    }));
  }

  async onSubmit(values) {
    try {
      const { addActivityMember, data, activity } = this.props;
      const firstName = !isEmpty(data.userData)
        ? data.userData.firstName
        : data.firstName;
      const surname = !isEmpty(data.surName)
        ? data.userData.secondName
        : data.secondName;
      const email = data.email ? data.email : data.userData.email;
      const projectId = activity.currentActivity.id;

      const { permissionType, ...newValues } = {
        ...values,
        firstName,
        surname,
        email,
        projectId,
      };
      await addActivityMember(newValues);
      this.toggle();
    } catch (error) {
      console.error('error: ', error);
    }
  }

  render() {
    const { modal, dropdownValuePermission } = this.state;
    return (
      <div className="button-outcome">
        <Modal
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="contentOrganization-edit"
          size="small"
          trigger={
            <Dropdown.Item
              text="Invite to Activity Member"
              className="segment-change"
            ></Dropdown.Item>
          }
        >
          <Modal.Header>Invite to Activity Member</Modal.Header>
          <Modal.Content>
            <div className="new-member-segment-custom">
              <Formik
                onSubmit={(values) => this.onSubmit(values)}
                initialValues={initialValues()}
                validationSchema={activityInviteTypeValidation}
              >
                {({
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  errors,
                  values,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className="container">
                        <CustomTextArea
                          id="message"
                          name="message"
                          placeholder=""
                          labelText="Message for Invited user"
                          requiredStar
                        />
                        <UserPermissionsInput
                          search
                          selection
                          requiredStar
                          name="permissionType"
                          id="permissionType"
                          placeholder="Select permission type"
                          labelText="Permissions"
                          options={this.PERMISSION_OPTIONS}
                          getValue={dropdownValuePermission}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className="actions-invite-activity">
                        <Button.Group floated="right">
                          <div className="actions-invite-activity">
                            <Button
                              type="submit"
                              className="Primary-Button"
                              loading={isSubmitting}
                              // disabled={venueTypeLoading}
                            >
                              Invite
                            </Button>
                          </div>
                        </Button.Group>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export class ActivityInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenditure: !props.actual
        ? thousands(props.activity.currentActivity.projectedCost) || ''
        : thousands(props.activity.currentActivity.actualCost) || '',
      grantAmount: undefined,
      startDate: null,
      endDate: null,
      greenLightNotifyDate: '',
      venueLoading: false,
      venueResults: [],
      venueValue: '',
      contractorLoading: false,
      contractorResults: [],
      contractorValue: '',
      expenditureError: '',
      grantAmountError: '',
      startDateError: '',
      endDateError: '',
      greenLightDateError: '',
      contractorCost: '',
      guestUserContractorsList: '',
      guestUserValue: '',
      guestUserVenuesList: '',
      guestUserVenueValue: '',
      contractorGuestLoading: false,
      venueGuestLoading: false,
      initialStartStateSettings: {
        autoApply: true,
        singleDatePicker: true,
        showDropdowns: true,
        startDate: props.activity.currentActivity.eventStartDate
          ? moment(props.activity.currentActivity.eventStartDate)
          : new Date(),
        maxDate: props.activity.currentActivity.eventEndDate
          ? moment(props.activity.currentActivity.eventEndDate)
          : null,
        minYear: 2017,
        maxYear: parseInt(moment().format('YYYY'), 10) + 10,
      },
      initialEndDateStateSettings: {
        autoApply: true,
        singleDatePicker: true,
        showDropdowns: true,
        startDate: props.activity.currentActivity.eventEndDate
          ? moment(props.activity.currentActivity.eventEndDate)
          : new Date(),
        minDate: props.activity.currentActivity.eventStartDate
          ? moment(props.activity.currentActivity.eventStartDate)
          : null,
        minYear: 2017,
        maxYear: parseInt(moment().format('YYYY'), 10) + 10,
      },
      partnerLoading: false,
      partnerValue: '',
      partnerResults: [],
      addPartnerModal: false,
      selectedPartner: {},
    };

    this.keyStartDateRef = React.createRef(Date.now());
    this.keyEndDateRef = React.createRef(Date.now());
  }

  async componentDidMount() {
    this.resetComponent();
    const {
      // auth,
      // getOrgContractors,
      getOccupationTypes,
      getOccupation,
      getOrgVenuesTypes,
      getVenueTypes,
      // getOrgVenues,
      actual,
    } = this.props;

    const { currentActivity } = this.props.activity;

    getOrgVenuesTypes();
    getOccupationTypes();
    getOccupation();
    getVenueTypes();

    // if (!isEmpty(auth.user) && auth.user.organisation) {
    // getOrgVenues(auth.user.organisation.id);
    // getOrgContractors(auth.user.organisation.id);
    // }

    if (currentActivity.projectedCost || currentActivity.actualCost) {
      await this.setState({
        expenditure: !actual
          ? thousands(currentActivity.projectedCost)
          : thousands(currentActivity.actualCost),
        grantAmount: !actual
          ? thousands(currentActivity.projectedGrantAmount)
          : thousands(currentActivity.actualGrantAmount),
        greenLightNotifyDate: currentActivity.greenLightNotifyDate
          ? moment(currentActivity.greenLightNotifyDate).format('DD MMM YYYY')
          : currentActivity.greenLightNotifyDate,
        startDate: currentActivity.eventStartDate
          ? moment(currentActivity.eventStartDate).format('DD MMM YYYY')
          : null,
        endDate: currentActivity.eventEndDate
          ? moment(currentActivity.eventEndDate).format('DD MMM YYYY')
          : null,
        actualVolunteers: currentActivity.actualVolunteers || '0',
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentActivity } = this.props.activity;
    const { actual } = this.props;
    if (
      prevProps.activity.currentActivity !== currentActivity ||
      prevProps.actual !== actual
    ) {
      await this.setState({
        expenditure: !actual
          ? thousands(currentActivity.projectedCost)
          : thousands(currentActivity.actualCost),
        grantAmount: !actual
          ? thousands(currentActivity.projectedGrantAmount)
          : thousands(currentActivity.actualGrantAmount),
        greenLightNotifyDate: currentActivity.greenLightNotifyDate
          ? moment(currentActivity.greenLightNotifyDate).format('DD MMM YYYY')
          : currentActivity.greenLightNotifyDate,
        startDate: currentActivity.eventStartDate
          ? moment(currentActivity.eventStartDate).format('DD MMM YYYY')
          : null,
        endDate: currentActivity.eventEndDate
          ? moment(currentActivity.eventEndDate).format('DD MMM YYYY')
          : null,
        actualVolunteers: currentActivity.actualVolunteers || '0',
        expenditureError: '',
        grantAmountError: '',
        startDateError: '',
        endDateError: '',
        greenLightDateError: '',
      });
    }
  }

  resetComponent = (type) => {
    if (type === 'venue') {
      this.setState({
        venueLoading: false,
        venueResults: [],
        venueValue: '',
        venueGuestLoading: false,
      });
    } else if (type === 'contractor') {
      this.setState({
        contractorLoading: false,
        contractorResults: [],
        contractorValue: '',
        contractorGuestLoading: false,
      });
    }
  };

  handleResultSelect = (e, { result }, type) => {
    this.resetComponent(type);
    const { assignProjectVenue, assignProjectContractor, activity } =
      this.props;

    delete result.createdAt;
    delete result.updatedAt;
    if (type === 'venue') {
      if (result.dName) delete result.dName;
      if (result.dStreetAddress) delete result.dStreetAddress;
      assignProjectVenue('projectInput', activity.currentActivity.id, {
        venue: result,
      });
    } else if (type === 'contractor') {
      delete result.createdBy;
      if (result.dFirstName) delete result.dFirstName;
      if (result.dSecondName) delete result.dSecondName;
      if (result.dCompanyName) delete result.dCompanyName;
      if (result.dEmail) delete result.dEmail;
      assignProjectContractor('projectInput', activity.currentActivity.id, {
        contractor: result,
      });
    }
  };

  handleGuestSearch = async (e, { value }, type) => {
    try {
      const {
        getGuestContractors,
        getGuestVenues,
        activity: { currentActivity },
      } = this.props;

      if (type === 'contractor') {
        this.setState({
          contractorGuestLoading: true,
          guestUserValue: value,
        });
        await getGuestContractors(currentActivity.ownerId, value);
        const { guestUserContractors } = this.props.organization;
        setTimeout(() => {
          if (this.state.guestUserValue.length < 1)
            return this.resetComponent(type);
          this.setState({
            contractorGuestLoading: false,
            guestUserContractorsList: guestUserContractors,
          });
        }, 300);
      } else {
        this.setState({
          venueGuestLoading: true,
          guestUserVenueValue: value,
        });
        await getGuestVenues(currentActivity.ownerId, value);
        const { guestUserVenues } = this.props.organization;
        setTimeout(() => {
          if (this.state.guestUserVenueValue.length < 1)
            return this.resetComponent(type);
          this.setState({
            venueGuestLoading: false,
            guestUserVenuesList: guestUserVenues,
          });
        }, 300);
      }
    } catch (error) {
      // console.error('handleSearchChange -> error', error);
    }
  };

  getUserVenueContractorPartnerAccess = () => {
    const {
      activity: { currentActivity },
      user,
    } = this.props;

    // activity owner organisation id
    let data = {
      create: false,
      guestUserId: null,
      search: false,
      organisationId: null,
    };

    const allUserPermissions = currentActivity.members;
    const currentUserPermissions = allUserPermissions.find(
      (item) => item.userId === user.id
    );

    // if member doesn't have any permission to this activity
    if (!currentUserPermissions) {
      return data;
    }

    const activityPlanPermission = currentUserPermissions.permissions.find(
      (item) => item.state === 'project.edit.activityPlan'
    );

    if (!activityPlanPermission.permission.write) {
      return data;
    }

    const isActivityOwner = currentActivity.ownerId === user.id;

    const isGuestUser = !user.organisationId;

    // check activity is linked (exclude single activity)
    const isLinkedActivity =
      currentActivity.isLinkedActivity &&
      currentActivity.activityPlanTypeId === 1;

    // FIXME: need to check ser has permission to activity plan
    // Current user is not a guest
    if (!isGuestUser) {
      const isSameOrganisation =
        currentActivity.owner.organisationId === user.organisationId;

      /*
       * organisation data means venues, contractors, partners
       */

      // current user in same organisation
      if (isSameOrganisation) {
        // able to add and search organisation data
        data.create = true; // able to create organisation data to activity owned organisation
        data.search = true; // able to search organisation data to activity owned organisation
        data.organisationId = user.organisationId; // eq currentActivity.owner.organisationId
      } else {
        // current user is from different organisation

        // activity is linked activity
        if (isLinkedActivity) {
          // if linked activity is owned by different organisation user
          if (isActivityOwner) {
            // current user is the owner
            data.create = true; // able to create organisation data to activity owner organisation (different organisation)
            data.search = true; // able to search organisation data to activity owner organisation (different organisation)
            data.organisationId = user.organisationId; // current user organisation id (different organisation id)
          } else {
            // current user is collaborator
            data.create = true; // create organisation data by guest user id
            data.guestUserId = currentActivity.owner.id; // is user is guest
            data.search = false; // Unable to access organisation data
            data.organisationId = null;
          }
        } else {
          // not activity owner (collaborator)

          // single + program activity
          if (isActivityOwner) {
            // current user is the owner
            data.create = true; // able to create organisation data to activity owned organisation
            data.search = true; // able to search organisation data to activity owned organisation
            data.organisationId = user.organisationId; // eq currentActivity.owner.organisationId
          } else {
            // current user is collaborator
            data.create = true; // able to create organisation data to activity owned organisation
            data.search = true; // able to search organisation data to activity owned organisation
            data.organisationId = currentActivity.owner.organisationId; // eq currentActivity.owner.organisationId
          }
        }
      }
    } else {
      // is current user is guest

      // activity is linked activity
      if (isLinkedActivity) {
        /*
         * if guest user is the owner of the linked activity or
         * if guest user is collaborator of the linked activity
         */
        data.create = true; // create organisation data by guest user id
        data.guestUserId = currentActivity.owner.id; // is user is guest
        data.search = false;
        data.organisationId = null;
      } else {
        // NOTE: Activity owner can not be guest users cannot create activities
        /*
         * if guest user is collaborator of the single + program activity
         */
        data.create = true;
        data.search = true;
        data.organisationId = currentActivity.owner.organisationId;
      }
    }

    return data;
  };

  handleSearchChange = async (e, { value }, type, organisationId) => {
    try {
      const {
        getOrgContractors,
        getOrgVenues,
        activity: { currentActivity },
      } = this.props;

      if (type === 'venue') {
        this.setState({ venueLoading: true, venueValue: value });
        await getOrgVenues(value, currentActivity.id);
        const { orgVenues } = this.props.organization;
        setTimeout(() => {
          if (this.state.venueValue.length < 1)
            return this.resetComponent(type);
          this.setState({
            venueLoading: false,
            venueResults: orgVenues,
          });
        }, 300);
      } else if (type === 'contractor') {
        this.setState({
          contractorLoading: true,
          contractorValue: value,
        });
        await getOrgContractors(value, currentActivity.id);
        const { orgContractors } = this.props.organization;
        setTimeout(() => {
          if (this.state.contractorValue.length < 1)
            return this.resetComponent(type);
          this.setState({
            contractorLoading: false,
            contractorResults: orgContractors,
          });
        }, 300);
      }
    } catch (error) {
      // console.error('handleSearchChange -> error', error);
    }
  };

  handlePartnerSearchChange = async (e, { value }, organisationId) => {
    const { getOrgPartnersByKeywords } = this.props;
    this.setState({
      partnerLoading: true,
      partnerValue: value,
    });

    await getOrgPartnersByKeywords(value);

    const { orgPartners } = this.props.organization;
    setTimeout(() => {
      if (this.state.partnerValue.length < 1) {
        this.setState({
          partnerLoading: false,
          partnerResults: [],
          partnerValue: '',
        });
        return;
      }
      this.setState({
        partnerLoading: false,
        partnerResults: orgPartners,
      });
    }, 300);
  };

  handlePartnerResultSelect = (e, { result }, type) => {
    this.resetComponent(type);
    this.setState({ addPartnerModal: true });
  };

  async onChange(value, data, type) {
    if (type === 'expenditure') {
      var isNum = /^-?\d+\.?\d*$/.test(value);
      if (!isNum) {
        this.setState({
          expenditureError: 'Only numbers can be entered',
        });
      } else {
        this.setState({
          expenditureError: '',
        });
      }
      this.setState({
        expenditure: value,
      });
    }
    if (type === 'grantAmount') {
      let isNum = /^-?\d+\.?\d*$/.test(value);
      if (!isNum) {
        this.setState({
          grantAmountError: 'Only numbers can be entered',
        });
      } else {
        this.setState({
          grantAmountError: '',
        });
      }
      this.setState({
        grantAmount: value,
      });
    }
  }

  async onFocus(value, data, type) {
    if (type === 'expenditure') {
      value = value.replace(/,/g, '');
      this.setState({
        expenditure: value,
      });
    }
    if (type === 'grantAmount') {
      value = value.replace(/,/g, '');
      this.setState({
        grantAmount: value,
      });
    }
  }

  handleBlur = (type) => {
    const { updateProjectInputs, activity, actual } = this.props;
    if (type === 'expenditure') {
      const { expenditure } = this.state;
      if (!expenditure) {
        this.setState({
          expenditureError: 'Expenditure value is required',
        });
      } else {
        let value = expenditure.replace(/,/g, '');
        var isNum = /^-?\d+\.?\d*$/.test(value);
        if (isNum) {
          const data = {
            completionProgress: activity.currentActivity.completionProgress,
          };
          if (!actual) {
            data.projectedCost = value;
          } else {
            data.actualCost = value;
          }
          updateProjectInputs('projectInput', activity.currentActivity.id, {
            data,
          });
        }
      }
    }
    if (type === 'grantAmount') {
      const { grantAmount } = this.state;
      if (!grantAmount) {
        this.setState({
          grantAmountError: `Program Contribution value is required`,
        });
      } else {
        let value = grantAmount.replace(/,/g, '');
        let isNum = /^-?\d+\.?\d*$/.test(value);
        if (isNum) {
          const data = {
            completionProgress: activity.currentActivity.completionProgress,
          };
          if (!actual) {
            data.projectedGrantAmount = value;
          } else {
            data.actualGrantAmount = value;
          }
          updateProjectInputs('projectInput', activity.currentActivity.id, {
            data,
          });
        }
      }
    }
  };

  getColumns(currentActivity, auth, currentState, actual, helpTips) {
    return [
      {
        title: 'Venue Name',
        render: (data) => {
          return (
            <div className="current-member-text">
              {data.createdBySystem ? (
                <CustomTooltip
                  state={get(auth, 'user.options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '220',
                  }}
                  position="bottom left"
                  wide="very"
                >
                  <label>{data ? (data.name ? data.name : '-') : '-'}</label>
                </CustomTooltip>
              ) : data ? (
                data.name ? (
                  data.name
                ) : (
                  '-'
                )
              ) : (
                '-'
              )}
            </div>
          );
        },
      },
      {
        title: 'Address',
        render: (data) => {
          const address =
            data &&
            `
          ${data.streetAddress !== null ? data.streetAddress : ''} ${
            data.streetAddress2 !== null ? data.streetAddress2 : ''
          } ${data.suburb !== null ? data.suburb : ''} ${
            data.state !== null ? data.state : ''
          } ${data.postCode !== null ? data.postCode : ''}
        `;
          return <div>{data.streetAddress !== null ? address : '-'}</div>;
        },
      },
      {
        title: 'Venue Type',
        render: (data) => {
          return (
            <div className="current-member-label">
              {data.venueType
                ? data.venueType.name
                  ? data.venueType.name
                  : null
                : null}
            </div>
          );
        },
      },
      // {
      //   title: '',
      //   render: data => {
      //     return (
      //       <div className="current-member-label">
      //         <Segment
      //           disabled={getPageDisable(
      //             currentActivity,
      //             auth.user,
      //             currentState,
      //             actual
      //           )}
      //         >
      //           <VenueUpdate
      //             venue={data}
      //             {...this.props}
      //             projectId={currentActivity.id}
      //           />
      //         </Segment>
      //       </div>
      //     );
      //   },
      // },
      {
        title: '',
        render: (data) => {
          if (!data.createdBySystem) {
            return (
              <div className="current-member-label">
                <Segment
                  disabled={getPageDisable(
                    currentActivity,
                    auth.user,
                    currentState,
                    actual
                  )}
                  className="action-container"
                >
                  <VenueUpdate
                    venue={data}
                    {...this.props}
                    projectId={currentActivity.id}
                  />
                  <CustomTooltip
                    state={get(auth, 'user.options.tips')}
                    contentObject={{
                      data: helpTips,
                      key: '101',
                    }}
                    position="bottom left"
                    wide="very"
                  >
                    <label
                      className="activity-actions-error first-down mb-0"
                      onClick={() => {
                        this.handleVenueDelete(data.id);
                      }}
                    >
                      Remove
                    </label>
                  </CustomTooltip>
                </Segment>
              </div>
            );
          } else {
            return null;
          }
        },
      },
    ];
  }

  async handleVenueDelete(id) {
    const { deleteProjectVenue, activity } = this.props;
    deleteProjectVenue('projectInput', activity.currentActivity.id, id);
  }

  renderVenueCard(userContractorVenuePartnerData) {
    const { auth, helpTips, activity, actual, currentState } = this.props;

    const { venues } = this.props.activity.currentActivity;
    return (
      <CustomTable
        header
        columns={this.getColumns(
          activity.currentActivity,
          auth,
          currentState,
          actual,
          helpTips,
          userContractorVenuePartnerData
        )}
        data={venues}
        customClass="venue-table"
        emptyMessage="No Venues"
        keyExtractor={(item, index) => item.id}
      />
    );
  }

  handleContractorDelete = async (id) => {
    const { deleteProjectContractor, activity } = this.props;
    deleteProjectContractor('projectInput', activity.currentActivity.id, id);
  };

  handleContractorCost = async (value, id) => {
    const { updateProjectContractorCost, activity } = this.props;
    const form = {
      cost: value,
    };
    updateProjectContractorCost(
      'projectInput',
      activity.currentActivity.id,
      id,
      form
    );
  };

  onChangeInput(value, type) {
    var isNum = /^-?\d+\.?\d*$/.test(value);
    if (isNum) {
      this.setState({ [type]: value });
    } else if (value === '') {
      this.setState({ [type]: value });
    }
  }

  onBlurInput(type) {
    const { putActivity } = this.props;
    let value = this.state[type] ? this.state[type] : 0;
    putActivity('projectInput', { [type]: value });
  }

  handleGreenLightDateChange = (event, value) => {
    const isValidDate = moment(value.startDate, 'DD-MMM-YYYY', true).isValid();
    if (isValidDate) {
      const { updateProjectInputs, activity } = this.props;
      updateProjectInputs('projectInput', activity.currentActivity.id, {
        data: {
          completionProgress: activity.currentActivity.completionProgress,
          greenLightNotifyDate: value.startDate,
        },
      });
    }
  };

  handleActivityStartDateChange = (event, value) => {
    const isValidDate = moment(value.startDate, 'DD-MMM-YYYY', true).isValid();
    if (isValidDate) {
      const { initialEndDateStateSettings, initialStartStateSettings } =
        this.state;
      const { updateProjectInputs, activity } = this.props;

      updateProjectInputs('projectInput', activity.currentActivity.id, {
        data: {
          eventStartDate: value.startDate,
        },
      });
      this.keyEndDateRef.current = value.startDate;

      this.setState({
        initialStartStateSettings: {
          ...initialStartStateSettings,
          startDate: value.startDate,
        },
        initialEndDateStateSettings: {
          ...initialEndDateStateSettings,
          minDate: value.startDate,
        },
      });
    }
  };

  handleActivityEndDateChange = (event, value) => {
    const isValidDate = moment(value.startDate, 'DD-MMM-YYYY', true).isValid();

    if (isValidDate) {
      const { initialStartStateSettings, initialEndDateStateSettings } =
        this.state;
      const { updateProjectInputs, activity } = this.props;

      updateProjectInputs('projectInput', activity.currentActivity.id, {
        data: {
          completionProgress: activity.currentActivity.completionProgress,
          eventEndDate: value.startDate,
        },
      });

      this.keyStartDateRef.current = value.endDate;
      this.setState({
        initialStartStateSettings: {
          ...initialStartStateSettings,
          maxDate: value.endDate,
        },
        initialEndDateStateSettings: {
          ...initialEndDateStateSettings,
          startDate: value.endDate,
        },
      });
    }
  };

  datePicker = async (event, picker) => {
    const { updateProjectInputs, activity } = this.props;
    let startDate = picker.startDate;
    let endDate = picker.endDate;
    if (startDate && endDate) {
      updateProjectInputs('projectInput', activity.currentActivity.id, {
        data: {
          completionProgress: activity.currentActivity.completionProgress,
          eventStartDate: toSqlDate(
            toSqlDate(moment(startDate).format('YYYY-MMM-DD'))
          ),
          eventEndDate: toSqlDate(
            toSqlDate(moment(endDate).format('YYYY-MMM-DD'))
          ),
        },
      });
      this.setState({
        startDate,
        endDate,
      });
    }
  };

  render() {
    const {
      venueLoading,
      venueResults,
      venueValue,
      expenditureError,
      grantAmountError,
      startDateError,
      endDateError,
      greenLightDateError,
      actualVolunteers,
      greenLightNotifyDate,
      expenditure,
      grantAmount,
      // contractorGuestLoading,
      startDate,
      endDate,
      initialStartStateSettings,
      initialEndDateStateSettings,
      // partnerDescriptionModal,
      partnerResults,
      partnerValue,
      partnerLoading,
      addPartnerModal,
      selectedPartner,
      contractorLoading,
      contractorResults,
      contractorValue,
    } = this.state;

    const { actual, activity, auth, helpTips, currentState } = this.props;

    let exist = {};
    if (
      activity.currentActivity.eventStartDate &&
      activity.currentActivity.eventEndDate
    ) {
      exist.value = `${moment(startDate).format('DD MMM YYYY')} - ${moment(
        endDate
      ).format('DD MMM YYYY')}`;
    }

    let existGreenLightNotifyDate = { value: '' };
    if (greenLightNotifyDate) {
      existGreenLightNotifyDate.value = `${moment(greenLightNotifyDate).format(
        'DD MMM YYYY'
      )}`;
    } else if (activity.currentActivity.greenLightDateError) {
      existGreenLightNotifyDate.value = `${moment(greenLightDateError).format(
        'DD MMM YYYY'
      )}`;
    }

    let existStartDate = { value: '' };
    if (startDate) {
      existStartDate.value = `${moment(startDate).format('DD MMM YYYY')}`;
    } else if (activity.currentActivity.startDateError) {
      existStartDate.value = `${moment(startDateError).format('DD MMM YYYY')}`;
    }

    let existEndDate = { value: '' };
    if (endDate) {
      existEndDate.value = `${moment(endDate).format('DD MMM YYYY')}`;
    } else if (activity.endDateError) {
      existEndDate.value = `${moment(endDateError).format('DD MMM YYYY')}`;
    }

    const userContractorVenuePartnerData =
      this.getUserVenueContractorPartnerAccess();

    return (
      <div className="document-input">
        <Segment className="inputs-segment">
          <Grid columns={2} stackable>
            {/* {activity.currentActivity.parentId &&
              !activity.currentActivity.isParent && (
                <Grid.Row className="inputs-row">
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={4}
                    className="extra-padding"
                  >
                    <div className="disabled-padding">
                      <Segment
                        disabled={getPageDisable(
                          activity.currentActivity,
                          auth.user,
                          currentState,
                          actual
                        )}
                      >
                        <div className="expenditure-div">
                          <Label>
                            {actual ? 'Actual' : 'Projected'}{' '}
                            {activity.currentActivity.activityPlanTypeId === 4
                              ? 'Grant Amount'
                              : 'Program Contribution'}
                          </Label>
                          <InputGroup className="icon-inputs-style-custom">
                            <InputGroupAddon addonType="prepend">
                              <Icon
                                circular
                                inverted
                                name="dollar sign"
                                color="grey"
                              />
                            </InputGroupAddon>
                            <Input
                              className="expenditure-input expenditure-input-custom"
                              onChange={(e, data) =>
                                this.onChange(
                                  e.target.value,
                                  data,
                                  'grantAmount'
                                )
                              }
                              onFocus={(e, data) =>
                                this.onFocus(
                                  e.target.value,
                                  data,
                                  'grantAmount'
                                )
                              }
                              onBlur={() => this.handleBlur('grantAmount')}
                              defaultValue={
                                !actual
                                  ? thousands(
                                      activity.currentActivity
                                        .projectedGrantAmount
                                    ) || ''
                                  : thousands(
                                      activity.currentActivity.actualGrantAmount
                                    ) || ''
                              }
                              value={grantAmount}
                              placeholder="0.00"
                            />
                          </InputGroup>

                          <div
                            className={
                              grantAmountError
                                ? 'error-msg-div'
                                : 'error-msg-hidden'
                            }
                          >
                            {grantAmountError}
                          </div>
                        </div>
                      </Segment>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )} */}
            {activity.currentActivity.isLinkedActivity &&
              isInternalLinkedActivity(activity.currentActivity) && (
                <Grid.Row className="inputs-row">
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={4}
                    className="extra-padding"
                  >
                    <div className="disabled-padding">
                      <Segment
                        disabled={getPageDisable(
                          activity.currentActivity,
                          auth.user,
                          currentState,
                          actual
                        )}
                      >
                        <div className="expenditure-div">
                          <Label>
                            {actual ? 'Actual ' : null} {'Program Contribution'}
                          </Label>

                          <CustomTooltip
                            state={get(auth, 'user.options.tips')}
                            contentObject={{
                              data: helpTips,
                              key:
                                activity.currentActivity.activityPlanTypeId !==
                                  4 && !actual
                                  ? 5000
                                  : null,
                            }}
                            position="bottom left"
                            wide="very"
                          >
                            <InputGroup className="icon-inputs-style-custom">
                              <InputGroupAddon addonType="prepend">
                                <Icon
                                  circular
                                  inverted
                                  name={get(
                                    activity,
                                    'currentActivity.currencyType.iconName',
                                    'dollar sign'
                                  )}
                                  color="grey"
                                />
                              </InputGroupAddon>
                              <Input
                                className="expenditure-input expenditure-input-custom"
                                onChange={(e, data) =>
                                  this.onChange(
                                    e.target.value,
                                    data,
                                    'grantAmount'
                                  )
                                }
                                onFocus={(e, data) =>
                                  this.onFocus(
                                    e.target.value,
                                    data,
                                    'grantAmount'
                                  )
                                }
                                onBlur={() => this.handleBlur('grantAmount')}
                                defaultValue={
                                  !actual
                                    ? thousands(
                                        activity.currentActivity
                                          .projectedGrantAmount
                                      ) || ''
                                    : thousands(
                                        activity.currentActivity
                                          .actualGrantAmount
                                      ) || ''
                                }
                                value={grantAmount}
                                placeholder="0.00"
                                disabled={
                                  activity.currentActivity.statusId !== 2
                                }
                              />
                            </InputGroup>
                            {actual && (
                              <label className="projected-value">
                                Projected Program Contribution :{' '}
                                {thousands(
                                  activity.currentActivity.projectedGrantAmount
                                )}{' '}
                              </label>
                            )}
                          </CustomTooltip>

                          <div
                            className={
                              grantAmountError
                                ? 'error-msg-div'
                                : 'error-msg-hidden'
                            }
                          >
                            {grantAmountError}
                          </div>
                        </div>
                      </Segment>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )}
            <Grid.Row className="inputs-row">
              <Grid.Column
                mobile={16}
                tablet={actual ? 16 : 5}
                computer={4}
                className="date-padding extra-padding date-input-row"
              >
                <div className="disabled-padding">
                  <Segment
                    disabled={getPageDisable(
                      activity.currentActivity,
                      auth.user,
                      currentState,
                      actual
                    )}
                  >
                    <div className="expenditure-div">
                      <Label className="plan-input-label">
                        Expenditure
                        {/* {actual ? 'Expenditure' : 'Projected Gross Expenditure'} */}
                      </Label>

                      <CustomTooltip
                        state={get(auth, 'user.options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: !actual ? '38' : '62',
                        }}
                        position="bottom left"
                        wide="very"
                      >
                        <InputGroup className="icon-inputs-style-custom">
                          <InputGroupAddon addonType="prepend">
                            <Icon
                              circular
                              inverted
                              name={get(
                                activity.currentActivity,
                                'currencyType.iconName',
                                'dollar sign'
                              )}
                              color="grey"
                            />
                          </InputGroupAddon>
                          <Input
                            className={
                              expenditureError
                                ? 'expenditure-input-err expenditure-input-custom '
                                : 'expenditure-input expenditure-input-custom '
                            }
                            onChange={(e, data) =>
                              this.onChange(e.target.value, data, 'expenditure')
                            }
                            onFocus={(e, data) =>
                              this.onFocus(e.target.value, data, 'expenditure')
                            }
                            onBlur={() => this.handleBlur('expenditure')}
                            value={expenditure}
                          />
                        </InputGroup>
                      </CustomTooltip>
                      {actual && (
                        <label className="projected-value">
                          Projected Expenditure :{' '}
                          {thousands(activity.currentActivity.projectedCost)}{' '}
                        </label>
                      )}
                      <div
                        className={
                          expenditureError
                            ? 'error-msg-div'
                            : 'error-msg-hidden'
                        }
                      >
                        {expenditureError}
                      </div>
                    </div>
                  </Segment>
                </div>
              </Grid.Column>
              {actual ? null : (
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={4}
                  className="date-input-row extra-padding"
                >
                  <div className="disabled-padding">
                    <Segment
                      disabled={getPageDisable(
                        activity.currentActivity,
                        auth.user,
                        currentState,
                        actual
                      )}
                    >
                      <CustomTooltip
                        state={get(auth, 'user.options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: '108',
                        }}
                        position="top left"
                      >
                        <Label className="plan-input-label">
                          Green Light Date
                        </Label>
                      </CustomTooltip>
                      <form autoComplete="off">
                        <div className="date-range-container">
                          <DateRangePicker
                            initialSettings={{
                              drops: 'auto',
                              autoApply: true,
                              singleDatePicker: true,
                              showDropdowns: true,
                              startDate: new Date(),
                              minYear: 2017,
                              maxYear:
                                parseInt(moment().format('YYYY'), 10) + 10,
                            }}
                            onApply={this.handleGreenLightDateChange}
                            value={greenLightNotifyDate}
                          >
                            <div className="date-range-chart p-1">
                              <input
                                {...existGreenLightNotifyDate}
                                placeholder="DD MM YYYY"
                                type="text"
                                readOnly
                                className="date-range-chart-date"
                              />
                              <Icon name="calendar alternate outline" />
                            </div>
                          </DateRangePicker>
                        </div>
                      </form>
                      <div
                        className={
                          greenLightDateError
                            ? 'error-msg-div'
                            : 'error-msg-hidden'
                        }
                      >
                        {greenLightDateError}
                      </div>
                    </Segment>
                  </div>
                </Grid.Column>
              )}

              <Grid.Column
                mobile={16}
                tablet={8}
                computer={4}
                className="date-padding date-input-row extra-padding"
              >
                <div className="disabled-padding">
                  <Segment
                    disabled={getPageDisable(
                      activity.currentActivity,
                      auth.user,
                      currentState,
                      actual
                    )}
                  >
                    <CustomTooltip
                      state={get(auth, 'user.options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: '74',
                      }}
                      position="top left"
                    >
                      <Label className="plan-input-label">
                        {datePickerStartTitle(activity.currentActivity)}
                      </Label>
                    </CustomTooltip>
                    <form autoComplete="off">
                      <div className="date-range-container">
                        <DateRangePicker
                          key={this.keyStartDateRef.current}
                          initialSettings={{
                            drops: 'auto',
                            ...initialStartStateSettings,
                          }}
                          onApply={this.handleActivityStartDateChange}
                          value={startDate}
                        >
                          <div className="date-range-chart p-1">
                            <input
                              {...existStartDate}
                              placeholder="DD MM YYYY"
                              type="text"
                              readOnly
                              className="date-range-chart-date"
                            />
                            <Icon name="calendar alternate outline" />
                          </div>
                        </DateRangePicker>
                      </div>
                    </form>
                  </Segment>
                </div>
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={8}
                computer={4}
                className="date-padding date-input-row extra-padding"
              >
                <div className="disabled-padding">
                  <Segment
                    disabled={getPageDisable(
                      activity.currentActivity,
                      auth.user,
                      currentState,
                      actual
                    )}
                  >
                    <CustomTooltip
                      state={get(auth, 'user.options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: '74',
                      }}
                      position="top left"
                    >
                      <Label className="plan-input-label">
                        {datePickerEndTitle(activity.currentActivity)}
                      </Label>
                    </CustomTooltip>
                    <form autoComplete="off">
                      <div className="date-range-container">
                        <DateRangePicker
                          key={this.keyEndDateRef.current}
                          initialSettings={{
                            drops: 'auto',
                            ...initialEndDateStateSettings,
                          }}
                          onApply={this.handleActivityEndDateChange}
                          value={endDate}
                        >
                          <div className="date-range-chart p-1">
                            <input
                              {...existEndDate}
                              placeholder="DD MM YYYY"
                              type="text"
                              readOnly
                              className="date-range-chart-date"
                            />
                            <Icon name="calendar alternate outline" />
                          </div>
                        </DateRangePicker>
                      </div>
                    </form>
                  </Segment>
                </div>
              </Grid.Column>

              {actual ? (
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={4}
                  className="extra-padding"
                >
                  <div className="disabled-padding">
                    <Segment
                      disabled={getPageDisable(
                        activity.currentActivity,
                        auth.user,
                        currentState,
                        actual
                      )}
                    >
                      <div className="expenditure-div">
                        <CustomTooltip
                          state={get(auth, 'user.options.tips')}
                          contentObject={{
                            data: helpTips,
                            key: '133',
                          }}
                          position="top left"
                          wide="very"
                        >
                          <Label className="plan-input-label">
                            Total Volunteers
                          </Label>
                        </CustomTooltip>
                        <Input
                          className={'end-date volunteer-input'}
                          name="actualVolunteers"
                          type="text"
                          value={actualVolunteers}
                          defaultValue={
                            activity.currentActivity
                              ? activity.currentActivity.actualVolunteers
                              : '0'
                          }
                          onChange={(e) =>
                            this.onChangeInput(
                              e.target.value,
                              'actualVolunteers'
                            )
                          }
                          onBlur={() => this.onBlurInput('actualVolunteers')}
                        />
                      </div>
                    </Segment>
                  </div>
                </Grid.Column>
              ) : //   </Grid.Row>
              // </Grid>
              // </Grid.Column>
              null}
            </Grid.Row>
            <Divider section />
            <Grid.Row>
              {/* <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                className="hr-extra-padding"
              >
                <hr></hr>
              </Grid.Column> */}
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <label className="venue-custom">Venue</label>

                <div className="disabled-padding">
                  <Segment
                    disabled={getPageDisable(
                      activity.currentActivity,
                      auth.user,
                      currentState,
                      actual
                    )}
                  >
                    {userContractorVenuePartnerData.search && (
                      <div className="venue-label-div">
                        <CustomTooltip
                          state={get(auth, 'user.options.tips')}
                          contentObject={
                            actual
                              ? {
                                  data: helpTips,
                                  key: '134',
                                }
                              : {
                                  data: helpTips,
                                  key: '126',
                                }
                          }
                          position="top left"
                        >
                          <label className="add-venue-text  plan-input-label">
                            Add Venue
                          </label>
                        </CustomTooltip>
                      </div>
                    )}

                    <div className="search-bar-wrapper">
                      <Grid.Column
                        mobile={16}
                        tablet={8}
                        computer={4}
                        className="extra-padding"
                      >
                        {userContractorVenuePartnerData.search ? (
                          <Search
                            className="search-field-custom search-wrapper"
                            loading={venueLoading}
                            onResultSelect={(e, obj) =>
                              this.handleResultSelect(e, obj, 'venue')
                            }
                            onSearchChange={debounce(
                              (e, obj) =>
                                this.handleSearchChange(
                                  e,
                                  obj,
                                  'venue',
                                  userContractorVenuePartnerData.organisationId
                                ),
                              500,
                              {
                                leading: true,
                              }
                            )}
                            results={venueResults}
                            value={venueValue}
                            resultRenderer={({ name }) => <>{name}</>}
                            placeholder="Search for existing venues in the organisation new"
                            disabled={getPageDisable(
                              activity.currentActivity,
                              auth.user,
                              currentState,
                              actual
                            )}
                            // {...this.props}
                          />
                        ) : (
                          <div></div>
                        )}
                      </Grid.Column>
                      <VenueCreate
                        {...this.props}
                        projectId={activity.currentActivity.id}
                        userVenueData={userContractorVenuePartnerData}
                      />
                    </div>
                  </Segment>
                </div>
              </Grid.Column>
            </Grid.Row>
            {this.renderVenueCard(userContractorVenuePartnerData)}
            <Grid.Row>
              <Grid.Column //contractor info starts here
                mobile={16}
                tablet={16}
                computer={16}
              >
                <div className="disabled-padding">
                  <Segment
                    disabled={getPageDisable(
                      activity.currentActivity,
                      auth.user,
                      currentState,
                      actual
                    )}
                  >
                    <Label className="contact-name">Contractor</Label>
                  </Segment>
                </div>
                <div className="disabled-padding">
                  <Segment
                    disabled={getPageDisable(
                      activity.currentActivity,
                      auth.user,
                      currentState,
                      actual
                    )}
                  >
                    {auth.user && auth.user.role === 'guest' ? null : (
                      <div className="venue-label-div">
                        <CustomTooltip
                          state={get(auth, 'user.options.tips')}
                          contentObject={
                            actual
                              ? {
                                  data: helpTips,
                                  key: '135',
                                }
                              : {
                                  data: helpTips,
                                  key: '127',
                                }
                          }
                          position="top left"
                        >
                          <label className="add-contact-name plan-input-label">
                            Add Contractor
                          </label>
                        </CustomTooltip>
                      </div>
                    )}
                    <div className="search-bar-wrapper">
                      {/* {
                         auth.user &&
                        auth.user.role === 'guest' &&
                        !checkGuestCollaborator(activity, auth) ? (
                          <Search
                            className="search-field-custom search-wrapper"
                            loading={contractorGuestLoading}
                            onResultSelect={(e, obj) =>
                              this.handleResultSelect(e, obj, 'contractor')
                            }
                            onSearchChange={debounce(
                              (e, obj) =>
                                this.handleGuestSearch(e, obj, 'contractor'),
                              500,
                              {
                                leading: true,
                              }
                            )}
                            results={this.state.guestUserContractorsList}
                            value={this.state.guestUserValue}
                            placeholder="Search for existing contractors in the guest user"
                            resultRenderer={({
                              firstName,
                              secondName,
                              companyName,
                            }) => (
                              <div className="contractor-search-results-c">
                                <label className="company">
                                  <b>{companyName}</b>
                                </label>
                                <div>
                                  <p className="group">
                                    {firstName} {secondName}
                                  </p>
                                </div>
                              </div>
                            )}
                            disabled={getPageDisable(
                              activity.currentActivity,
                              auth.user,
                              currentState,
                              actual
                            )}
                          />
                        ) : (
                        }
                        */}
                      {userContractorVenuePartnerData.search ? (
                        <Search
                          className="search-field-custom search-wrapper"
                          loading={contractorLoading}
                          onResultSelect={(e, obj) =>
                            this.handleResultSelect(e, obj, 'contractor')
                          }
                          onSearchChange={debounce(
                            (e, obj) =>
                              this.handleSearchChange(
                                e,
                                obj,
                                'contractor',
                                userContractorVenuePartnerData.organisationId
                              ),
                            500,
                            {
                              leading: true,
                            }
                          )}
                          results={contractorResults}
                          value={contractorValue}
                          placeholder="Search for existing contractors in the organisation"
                          resultRenderer={({
                            firstName,
                            secondName,
                            companyName,
                          }) => (
                            <div className="contact-search-results-c">
                              <label className="company">
                                <b>{companyName}</b>
                              </label>
                              <div>
                                <p className="group">
                                  {firstName} {secondName}
                                </p>
                              </div>
                            </div>
                          )}
                          disabled={getPageDisable(
                            activity.currentActivity,
                            auth.user,
                            currentState,
                            actual
                          )}
                        />
                      ) : (
                        <div></div>
                      )}
                      {/* )  } */}
                      <>
                        <ContractorCreate
                          {...this.props}
                          projectId={activity.currentActivity.id}
                          userContractorData={userContractorVenuePartnerData}
                        />
                      </>
                    </div>
                  </Segment>
                </div>
              </Grid.Column>

              {/* contractor data table */}
            </Grid.Row>{' '}
          </Grid>
          <ContractorView
            {...this.props}
            userContractorData={userContractorVenuePartnerData}
          />
          <Grid>
            <Grid.Row>
              <Grid.Column //partner info starts here
                mobile={16}
                tablet={16}
                computer={16}
              >
                <div className="disabled-padding">
                  <Segment
                    disabled={getPageDisable(
                      activity.currentActivity,
                      auth.user,
                      currentState,
                      actual
                    )}
                  >
                    <Label className="contact-name">Partner</Label>
                    {auth.user && auth.user.role === 'guest' ? null : (
                      <div className="venue-label-div">
                        <CustomTooltip
                          state={get(auth, 'user.options.tips')}
                          content="Add Partner"
                          position="top left"
                          contentObject={{
                            data: helpTips,
                            key: '5001',
                          }}
                        >
                          <label className="add-contact-name plan-input-label">
                            Add Partner
                          </label>
                        </CustomTooltip>
                      </div>
                    )}
                    <div className="search-bar-wrapper">
                      {userContractorVenuePartnerData.search ? (
                        <Search
                          className="search-field-custom search-wrapper"
                          loading={partnerLoading}
                          onResultSelect={(e, obj) => {
                            this.setState(
                              { selectedPartner: obj.result },
                              () => {
                                this.handlePartnerResultSelect(
                                  e,
                                  obj,
                                  'partner'
                                );
                              }
                            );
                          }}
                          onSearchChange={debounce(
                            (e, obj) =>
                              this.handlePartnerSearchChange(
                                e,
                                obj,
                                userContractorVenuePartnerData.organisationId
                              ),
                            500,
                            {
                              leading: true,
                            }
                          )}
                          results={partnerResults}
                          value={partnerValue}
                          placeholder="Search for existing partners in the organisation"
                          resultRenderer={({
                            firstName,
                            secondName,
                            organisationName,
                          }) => (
                            <div className="contact-search-results-c">
                              <label className="company">
                                <b>{organisationName}</b>
                              </label>
                              <div>
                                <p className="group">
                                  {firstName} {secondName}
                                </p>
                              </div>
                            </div>
                          )}
                          disabled={getPageDisable(
                            activity.currentActivity,
                            auth.user,
                            currentState,
                            actual
                          )}
                        />
                      ) : (
                        <div></div>
                      )}
                      {/* {auth.user && auth.user.role === 'guest' ? null : ( */}
                      <>
                        <PartnerCreateFromActivity
                          {...this.props}
                          userPartnerData={userContractorVenuePartnerData}
                          projectId={activity.currentActivity.id}
                        />
                        <PartnerUpdateFromActivity
                          selectedPartner={selectedPartner}
                          userPartnerData={userContractorVenuePartnerData}
                          projectId={activity.currentActivity.id}
                          modal={addPartnerModal}
                          setModal={(value) => {
                            this.setState({ addPartnerModal: value });
                          }}
                          {...this.props}
                        />
                      </>
                      {/* )} */}
                    </div>
                  </Segment>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* Partner data table */}
          <PartnerView {...this.props} />
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
    extra: state.extra,
    user: state.authentication.user,
    activity: state.activity,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrgVenues,
      getOrgVenuesTypes,
      getVenueTypes,
      getOccupationTypes,
      getOccupation,
      getOrgContractors,
      assignProjectVenue,
      assignProjectContractor,
      updateProjectInputs,
      createOrgContractor,
      updateOrgContractor,
      createOrgVenues,
      updateOrgVenues,
      deleteProjectVenue,
      deleteProjectContractor,
      updateProjectContractorCost,
      deleteProjectPartner,
      updateProjectPartner,
      putActivity,
      getOrgPartnersByKeywords,
      assignProjectPartner,
      getGuestContractors,
      getGuestVenues,
      addActivityMember,
    },
    dispatch
  );
};

ActivityInput = connect(mapStateToProps, mapDispatchToProps)(ActivityInput);

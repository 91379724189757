import './auth.scss';

import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Dimmer, Grid, Loader } from 'semantic-ui-react';

import { SuccessContainer } from '../../components';
import { APP_NAME } from '../../utils/constant';
import { checkMailVerified } from '../../store/actions';

class MailVerification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = `Email Verification | ${APP_NAME}`;
    const {
      checkMailVerified,
      match: {
        params: { token = null },
      },
    } = this.props;
    checkMailVerified(token);
  }

  render() {
    const { emailVerifyLoading, emailVerify } = this.props;
    // const emailVerify = true;
    return (
      <Grid.Row>
        <Container className="email-verification-container">
          <Grid>
            <Grid.Row>
              {emailVerifyLoading ? null : (
                <SuccessContainer
                  noBgEffects
                  header={emailVerify ? 'Thank you for verifying your' : ''}
                  msg={
                    emailVerify
                      ? 'email address.'
                      : 'This link is no longer active.'
                  }
                  description={
                    emailVerify
                      ? null
                      : [
                          <span>
                            Looks like this link is broken or has already been
                            used.{' '}
                          </span>,
                          <br />,
                        ]
                  }
                  linkExpired={!emailVerify}
                  success={emailVerify}
                  button={emailVerify ? 'Return to Takso' : 'Return to Sign in'}
                  buttonRoute="/dashboard"
                  overRideBtn={!emailVerify ? true : false}
                />
              )}
            </Grid.Row>
          </Grid>
          <Dimmer active={emailVerifyLoading} inverted>
            <Loader content="Loading" />
          </Dimmer>
        </Container>
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  const { emailVerify, emailVerifyLoading } = state.authentication;
  return {
    emailVerify,
    emailVerifyLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      checkMailVerified,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MailVerification);

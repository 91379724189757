/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useField } from 'formik';
import { Form } from 'semantic-ui-react';

export const CustomRadioGroup = ({
  labelText,
  grouped,
  inline,
  options,
  getValue,
  setDefaultValue = undefined, // Note: do not use true, false for setting default value
  disabled = false,
  ...props
}) => {
  const [, meta, helpers] = useField(props);
  const { value: mainValue } = meta;
  const { setValue } = helpers;
  useEffect(() => {
    setDefaultValue && setValue(setDefaultValue);
  }, []);
  return (
    <div className="formik-custom-field act-inputs-new">
      <Form.Group grouped={grouped} inline={inline}>
        <label className="label">{labelText}</label>
        {options.map((option, key) => (
          <Form.Field
            key={key}
            className="custom-radio"
            id={props.id}
            name={props.name}
            label={option.label}
            control="input"
            type={props.type}
            checked={option.value === mainValue}
            disabled={disabled}
            onChange={() => {
              setValue(option.value);

              getValue && getValue(option.value);
            }}
          />
        ))}
        {/* {meta.touched && meta.error && (
          <div className="invalid-feedback">{meta.error}</div>
        )} */}
      </Form.Group>
    </div>
  );
};

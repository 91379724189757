import { isEmpty } from 'lodash';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Loader, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { CustomSegment, CustomTooltip } from '../../../../components';
import { get } from 'lodash';
import { ActivityChecklist } from '../activityChecklist';
import { ActivityInput } from './activityInput';
import { ActivityOutput } from './activityOutput';
import { AggregateReports } from '../aggregateReports';
import { DocumentNotes, FinalActivityDescription } from '../documentationNotes';
import { DocumentAndMedia } from '../documentMedia';
import { LinkedSettings } from '../linkedSettings';
import { isParentProgramActivity } from '../../../../utils/activity';
import { getPageDisable } from '../../../../utils';
import ProjectText from '../../../../components/projectText';
import { useSelector, useDispatch } from 'react-redux';
import {
  setParentAdvancedFilter as setParentAdvancedFilter,
  setParentReportType,
} from '../../../../store/actions';
import { SIDEBAR_TYPES } from '../../../../utils/constant';

const Documentation = ({
  currentActivity,
  actPut,
  user,
  helpTips,
  ...props
}) => {
  const dispatch = useDispatch();

  const parentReportType = useSelector(
    (state) => state.report.parentReportType
  );

  const pageDisabled = getPageDisable(
    currentActivity,
    user,
    'project.edit.documentation'
  );

  const isAdvancedFilter =
    useSelector((state) => state.sidebar.sideBarType) === SIDEBAR_TYPES[2];

  const isVisibleSidebar = useSelector(
    (state) => state.sidebar.isVisibleSidebar
  );
  const advancedToggle = isAdvancedFilter && isVisibleSidebar;

  const [reportType, setReportType] = useState('activities');

  useEffect(() => {
    if (reportType !== parentReportType) {
      dispatch(setParentReportType(reportType));
    }
  }, [reportType]);

  return (
    <div>
      <CustomSegment
        className="final-activity-description"
        title={`Final ${
          isParentProgramActivity(currentActivity.activityPlanTypeId)
            ? 'Program'
            : 'Activity'
        } Description`}
        subTitleStyle
        subTitle={`Please ensure that the description accurately reflects ${
          isParentProgramActivity(currentActivity.activityPlanTypeId)
            ? 'program'
            : 'the activity'
        } delivered. This will be added to the final activity report.`}
        children={
          <FinalActivityDescription
            currentState="project.edit.documentation"
            pageDisabled={pageDisabled}
          />
        }
        disabled={pageDisabled}
        extraMargin
      />
      <CustomSegment
        className="document-and-media"
        title="Documents & Media"
        children={
          <DocumentAndMedia currentState="project.edit.documentation" />
        }
        extraMargin
        disabled={pageDisabled}
      />
      <CustomSegment
        className="actual-inputs"
        title={
          <CustomTooltip
            state={get(user, 'options.tips')}
            contentObject={{
              data: [],
              key: 5004,
            }}
            position="top left"
            wide="very"
          >
            <div>Actual Inputs </div>
          </CustomTooltip>
        }
        children={
          <ActivityInput
            actual={true}
            currentState="project.edit.documentation"
          />
        }
        rightHeader={
          <Loader
            active={actPut.projectInput ? actPut.projectInput : false}
            inline
          />
        }
        extraMargin
        disabled={pageDisabled}
      />
      {isParentProgramActivity(currentActivity.activityPlanTypeId) ? null : (
        <Fragment>
          <CustomSegment
            className="actual-outputs"
            title={
              <CustomTooltip
                state={get(user, 'options.tips')}
                contentObject={{
                  data: [],
                  key: 5005,
                }}
                position="top left"
                wide="very"
              >
                <div>Actual Outputs </div>
              </CustomTooltip>
            }
            children={
              <ActivityOutput
                actual={true}
                currentState="project.edit.documentation"
              />
            }
            rightHeader={
              <Loader
                active={
                  actPut.projectedOutputs ? actPut.projectedOutputs : false
                }
                inline
              />
            }
            disabled={pageDisabled}
            extraMargin
          />
        </Fragment>
      )}
      {isParentProgramActivity(currentActivity.activityPlanTypeId) && (
        <div>
          <CustomSegment
            title="Program Reports"
            className={
              advancedToggle ? 'program-report open-panel' : 'program-report'
            }
            children={
              <AggregateReports
                currentState="project.edit.documentation"
                setReportType={setReportType}
                visible={advancedToggle}
              />
            }
            tooltip={{
              user: user,
              helpTips: helpTips,
              id: '122',
            }}
            extraMargin
            disabled={pageDisabled}
          />
          {/* </Sidebar.Pusher>
          </Sidebar.Pushable> */}
        </div>
      )}
      <CustomSegment
        className="analysis-and-reflection"
        title="Analysis and Reflection"
        subTitleStyle
        subTitle={
          <ProjectText
            defaultText="Provide notes about the activity"
            textId={8}
          />
        }
        children={
          <DocumentNotes
            currentState="project.edit.documentation"
            pageDisabled={pageDisabled}
          />
        }
        rightHeader={
          <Loader active={actPut.projectNotes ? true : false} inline />
        }
        extraMargin
        disabled={pageDisabled}
      />
      {!isParentProgramActivity(currentActivity.activityPlanTypeId) &&
        !isEmpty(currentActivity.activityReportCheckList) && (
          <CustomSegment
            title="Checklist"
            children={
              <ActivityChecklist
                documentation
                currentState="project.edit.documentation"
                pageDisabled={pageDisabled}
              />
            }
            rightHeader={
              <Loader
                active={actPut.checklistReport ? actPut.checklistReport : false}
                inline
              />
            }
            extraMargin
            disabled={pageDisabled}
          />
        )}
      {currentActivity.parentId && (
        <CustomSegment
          title="Settings"
          children={
            <LinkedSettings
              actPut={actPut}
              currentState="project.edit.documentation"
            />
          }
          extraMargin
          disabled={pageDisabled}
        />
      )}
    </div>
  );
};

export default Documentation;

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import './organization.scss';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Grid,
  Icon,
  Label,
  Modal,
  Segment,
  Table,
  Dropdown,
  Loader,
} from 'semantic-ui-react';

import {
  BrowseFile,
  CustomTable,
  EmptyContainer,
  CustomSegment,
} from '../../../components';
import {
  cloneDeep,
  filter,
  isEmpty,
  last,
  map,
  debounce,
  get,
  find,
  size,
} from '../../../utils/lodash';
import {
  createOrganizationGoal,
  deleteOrganizationGoal,
  getOrganization,
  getOrgGoals,
  getPolicies,
  updateOrganizationGoal,
  uploadOrgDocuments,
  postOrgDocuments,
  getOrgStrategicDocuments,
} from '../../../store/actions';
import { ViewListModal } from '../../../components/viewListModal';
import { ALLOWED_DOCUMENTS_TYPES } from '../../../utils/constant';

class GoalsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      primaryOption: null,
      secondaryOption: new Map(),
      error: null,
      isDisabled: false,
    };
  }

  async componentWillMount() {
    const { selectedGoal, isNew } = this.props;
    if (!isNew) {
      const secondaryOption = new Map();
      await map(selectedGoal.secondaryPolicies, (item) => {
        secondaryOption.set(item.id, true);
      });
      let disabled = selectedGoal.primaryPolicyId != 1;

      await this.setState({
        primaryOption: selectedGoal.primaryPolicyId,
        secondaryOption: secondaryOption,
        isDisabled: disabled,
      });
    }
  }

  async handleValidSubmit(event, values) {
    const { primaryOption, secondaryOption } = this.state;
    const {
      organization,
      selectedGoal,
      createOrganizationGoal,
      updateOrganizationGoal,
      resetPage,
      reportingDocumentId,
      currentPage,
      getOrgGoals,
    } = this.props;
    if (!primaryOption) {
      this.setState({
        error: true,
      });
      return;
    } else {
      const lastIndex = last([...secondaryOption.keys()]);
      let secondaryPolicyId = null;
      const secondaryPolicies = map([...secondaryOption.keys()], (item) => {
        const policy = filter(this.props.policies, {
          id: item,
        });
        if (lastIndex === item) {
          secondaryPolicyId = item;
        }
        return policy[0];
      });

      values.primaryPolicyId = primaryOption;
      values.secondaryPolicyId = secondaryPolicyId;
      values.secondaryPolicies = secondaryPolicies;

      //selected documentId from dropdown
      values.goalDocumentId = reportingDocumentId;

      if (this.props.isNew) {
        await createOrganizationGoal(values);
        await getOrgGoals(currentPage, 10, reportingDocumentId);
        if (resetPage) resetPage();
      } else {
        await updateOrganizationGoal(selectedGoal.id, values);
        await getOrgGoals(currentPage, 10, reportingDocumentId);
        if (resetPage) resetPage();
      }

      this.form && this.form.reset();
      this.props.toggle();
    }
  }

  handleonSubmit(event, values) {
    const { primaryOption } = this.state;
    if (!primaryOption) {
      this.setState({
        error: true,
      });
      return;
    }
  }

  handlePrimaryChange(value) {
    const newSecondaryOption = new Map();
    newSecondaryOption.set(1, true);

    let disabled = value != 1;
    this.setState({
      primaryOption: value,
      error: null,
      isDisabled: disabled,
      secondaryOption: disabled ? newSecondaryOption : new Map(),
    });
  }

  async handleSecondaryChange({ target }) {
    const { secondaryOption } = this.state;
    const item = Number(target.value);
    const isChecked = target.checked;
    if (secondaryOption.get(item)) {
      await this.setState((prevState) => {
        const option = cloneDeep(prevState.secondaryOption);
        option.delete(item);
        return {
          ...prevState,
          secondaryOption: option,
        };
      });
    } else {
      await this.setState((prevState) => {
        const option = cloneDeep(prevState.secondaryOption);
        option.set(item, isChecked);
        return {
          ...prevState,
          secondaryOption: option,
        };
      });
    }
  }

  charLengthValidate = debounce((value, ctx, input, cb) => {
    if (value.length > 250) {
      cb('Goal should be less than 250 characters');
    }
    cb(true);
  }, 300);

  render() {
    const { account = false, toggle, selectedGoal } = this.props;
    const { primaryOption, secondaryOption, error, isDisabled } = this.state;
    const { policies } = this.props;
    const defaultValue = selectedGoal;
    return (
      <AvForm
        ref={(c) => (this.form = c)}
        model={defaultValue}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        onSubmit={(event, values) => this.handleonSubmit(event, values)}
      >
        <div className="model-space">
          <AvField
            label="Goal"
            className="org-inputs"
            name="goalTitle"
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: 'Goal is required',
              },
              async: this.charLengthValidate,
            }}
            helpMessage={`You can obtain goals from your ${
              account ? 'account' : 'organisation'
            }’s strategic documents (max 250 characters)`}
          />
          <AvField
            label="Goal Description"
            className="org-inputs-textarea"
            name="goalDescription"
            type="textarea"
            helpMessage="A short description about the goal"
          />
        </div>
        <Table
          basic="very"
          stackable
          structured={false}
          className="org-goals-policy-table"
        >
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>
                Primary Policy Domain for Goal
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Primary</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Secondary
                <br />
                (optional)
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {map(policies, (item, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{`${item.policyName} (i.e. ${item.policyDescription})`}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <input
                      name="primary"
                      type="radio"
                      value={item.id}
                      checked={primaryOption === item.id}
                      onChange={() => this.handlePrimaryChange(item.id)}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center" className="flex-center">
                    {isDisabled && item.id == 1 ? (
                      <input
                        checked={isDisabled}
                        value={item.id}
                        type="checkbox"
                        disabled={isDisabled}
                      />
                    ) : !isDisabled ? (
                      <input
                        checked={secondaryOption.has(item.id)}
                        value={item.id}
                        type="checkbox"
                        onChange={(e) => this.handleSecondaryChange(e)}
                        disabled={isDisabled}
                      />
                    ) : null}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        <div className="error-text">
          {error ? (
            <div className="invalid-policy">Primary Policy is required</div>
          ) : null}
        </div>
        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Save"
            type="submit"
            className="Primary-Button"
            disabled={this.props.organization.loading}
            loading={this.props.organization.loading}
          />
        </div>
      </AvForm>
    );
  }
}

class GoalsDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async handleGoalDelete() {
    const {
      deleteOrganizationGoal,
      selectedGoal,
      resetPage,
      currentPage,
      reportingDocumentId,
    } = this.props;

    let goalTableAPIQueryData = {
      currentPage: currentPage,
      documentId: reportingDocumentId,
    };
    await deleteOrganizationGoal(selectedGoal.id, goalTableAPIQueryData);
    if (resetPage) resetPage();
    this.toggle();
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
        size="mini"
        trigger={<label className="table-delete-tag">Remove</label>}
      >
        <Modal.Header>Delete Your Goal</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete your goal</p>
        </Modal.Content>
        <Modal.Actions>
          <div className="model-buttons">
            <Button className="Secondary-Button" onClick={() => this.toggle()}>
              No
            </Button>
            <Button
              loading={this.props.organization.loading}
              disabled={this.props.organization.loading}
              onClick={() => this.handleGoalDelete()}
              className="Primary-Button"
              content="Yes"
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

class GoalsUpdate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <div>
        <Modal
          trigger={<label className="table-edit-tag">Edit</label>}
          size="small"
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="edit-a-goal"
        >
          <Modal.Header>Edit Goal</Modal.Header>
          <Modal.Content>
            <GoalsForm
              {...this.props}
              isNew={false}
              toggle={() => this.toggle()}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class GoalsCreate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <div className="add-goal">
        <Modal
          trigger={
            <Button onClick={() => this.toggle()} className="Primary-Button">
              <Icon name="plus" />
              Add New Goal
            </Button>
          }
          size="small"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="add-a-new-goal"
        >
          <Modal.Header>Add a New Goal</Modal.Header>
          <Modal.Content>
            <GoalsForm
              {...this.props}
              isNew={true}
              toggle={() => this.toggle()}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const NewStrategicPlan = (props) => {
  const {
    account,
    edit,
    data,
    organization: { postOrgDocLoading, updateOrgDocLoading },
    firstTimeCreation = false,
  } = props;

  const [toggle, setToggle] = useState(false);
  const [isUploadButtonClicked, setIsUploadButtonClicked] = useState(false);
  const [file, setFile] = useState('');
  const [form, setForm] = useState('');
  const [isEmpty, setEmpty] = useState(true);

  useEffect(() => {
    if (edit) {
      setForm({
        title: get(data, 'documentName', ''),
        documentId: get(data, 'organisationDocument.id', ''),
      });
      setFile(data.organisationDocument);
    }
  }, [data]);

  const handleValidSubmit = async (event, values) => {
    if (isEmpty && !edit) {
      setIsUploadButtonClicked(true);
      return;
    }
    const { uploadOrgDocuments, postOrgDocuments, auth, currentPage } = props;

    let formData = new FormData();
    formData.set('document', file);
    formData.set('title', values.title);

    if (edit) {
      await uploadOrgDocuments(formData, form.documentId);
    } else {
      await postOrgDocuments(formData, currentPage);
    }

    setToggle(!toggle);
  };

  const onChangeFile = (file) => {
    setFile(file.file);
  };

  const isDocumentEmpty = () => {
    setEmpty(false);
  };

  return (
    <Modal
      open={toggle}
      onOpen={() => setToggle(true)}
      closeIcon={
        <div
          className="new-activity-modal-close-icon"
          onClick={() => setToggle(false)}
        >
          &times;
        </div>
      }
      size="small"
      trigger={
        <Dropdown.Item className="activity-actions-dropdown-view">
          {firstTimeCreation ? (
            <label className="activity-actions-text-native-color">
              Create strategic document
            </label>
          ) : (
            <label className="activity-actions-text">
              {edit ? 'Edit' : 'New strategic document'}
            </label>
          )}
        </Dropdown.Item>
      }
      className="organisation-strategic-document"
    >
      <Modal.Header>
        <div className="small-modal-header">
          Organisation Strategic Document
        </div>
      </Modal.Header>
      <Modal.Content>
        <AvForm
          className="activity-form"
          onValidSubmit={(event, values) => handleValidSubmit(event, values)}
          autoComplete="off"
          model={form}
        >
          <AvField
            className="program-activities-input"
            label={<div>Document Title</div>}
            // onBlur={(e, value) => onDocumentChange(e, value)}
            name="title"
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: 'Document title is required',
              },
            }}
          />

          <BrowseFile
            label={`Upload ${
              account ? 'Account' : 'Organisation'
            } Strategic Plan`}
            name="strategic"
            helpMessage={`This document will be a reference for your ${
              account ? 'account' : 'organisation'
            } goals`}
            accept={ALLOWED_DOCUMENTS_TYPES}
            buttonText="Browse"
            mediaUpload={true}
            infoMessage="Max file size - 50 MB"
            setFile={onChangeFile}
            isRequiredMessage={isEmpty && isUploadButtonClicked}
            showMsg={true}
            isDocumentEmpty={isDocumentEmpty}
            placeholder={edit ? form.title : ''}
            className="media-modal-avfield"
          />
          <div className="model-buttons">
            <Button
              className="Secondary-Button"
              type="button"
              onClick={() => setToggle(!toggle)}
            >
              <div className="core-cancel-button-text">Cancel</div>
            </Button>
            <Button
              type="submit"
              className="Primary-Button"
              loading={
                edit ? updateOrgDocLoading : !edit ? postOrgDocLoading : false
              }
              disabled={
                edit ? updateOrgDocLoading : !edit ? postOrgDocLoading : false
              }
            >
              <div className="core-upload-button-text">Save</div>
            </Button>
          </div>
        </AvForm>
      </Modal.Content>
    </Modal>
  );
};

class OrganizationGoals extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentPage: 1,
      strategicDocuments: [],
      reportingDocumentId: '',
      limit: 10,
      selectedDocument: {},
    };
  }

  async componentDidUpdate(prevProps) {
    const {
      organization: { strategicDocs },
    } = this.props;
    if (strategicDocs !== prevProps.organization.strategicDocs) {
      await getOrgStrategicDocuments();

      const {
        organization: { strategicDocs },
      } = this.props;
      if (!isEmpty(strategicDocs)) {
        this.setState({
          strategicDocuments: strategicDocs,
          selectedDocument: find(
            strategicDocs,
            (d) =>
              d.strategicDocumentId === strategicDocs[0].strategicDocumentId
          ),
          reportingDocumentId: strategicDocs[0].strategicDocumentId,
        });
      }
    }
  }

  async componentDidMount() {
    const { currentPage, limit } = this.state;
    const {
      getPolicies,
      getOrgGoals,
      getOrganization,
      getOrgStrategicDocuments,
      auth,
    } = this.props;
    getPolicies();
    if (!isEmpty(auth.user) && auth.user.organisation) {
      await Promise.all([
        getOrganization(auth.user.organisation.id),
        getOrgStrategicDocuments(),
      ]);
    }
    const {
      organization: { strategicDocs },
    } = this.props;

    if (!isEmpty(strategicDocs[0])) {
      let selectedDocument = find(
        strategicDocs,
        (d) => d.strategicDocumentId === strategicDocs[0].strategicDocumentId
      );
      await this.setState({
        selectedDocument: selectedDocument,
        strategicDocuments: strategicDocs,
        reportingDocumentId: strategicDocs[0].strategicDocumentId,
      });
      await getOrgGoals(
        currentPage,
        limit,
        strategicDocs[0].strategicDocumentId
      );
    }
  }

  optionArchiveYears() {
    const { strategicDocuments } = this.state;
    if (isEmpty(strategicDocuments)) return;
    return map(strategicDocuments, (item, i) => ({
      key: i,
      value: get(item, 'strategicDocumentId', ''),
      text: `${moment(item.createdAt).format('YYYY MMMM')} - ${
        item.endDate === null
          ? 'Current'
          : moment(item.endDate).format('YYYY MMMM')
      }`,
      content: (
        <div className="dropdown-content-goals">
          <div className="name-dropdown">{item.documentName}</div>
          <div className="year-dropdown">
            {moment(item.createdAt).format('YYYY MMMM')} -{' '}
            {item.endDate === null
              ? 'Current'
              : moment(item.endDate).format('YYYY MMMM')}
          </div>
        </div>
      ),
    }));
  }

  onChangeYearSelection(event, data) {
    const { getOrgGoals, auth } = this.props;
    const { limit, currentPage } = this.state;

    const {
      organization: { strategicDocs },
    } = this.props;

    let selectedDocument = {};
    if (!isEmpty(strategicDocs[0])) {
      selectedDocument = find(
        strategicDocs,
        (d) => d.strategicDocumentId === parseInt(data.value)
      );
    }

    this.setState(
      {
        reportingDocumentId: parseInt(data.value),
        selectedDocument,
      },
      async () => {
        try {
          await getOrgGoals(currentPage, limit, data.value);
        } catch (error) {
          throw error;
        }
      }
    );
  }

  render() {
    const { strategicDocuments, reportingDocumentId } = this.state;
    const {
      organization: { strategicLoading },
    } = this.props;
    return (
      <>
        {strategicLoading ? (
          <Loader active inline className="evaluation-div-loader" />
        ) : (
          <CustomSegment
            title="Organisation Goals"
            className="organisation-goals"
            rightHeader={
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={16}
                textAlign="right"
                floated="right"
                width={8}
              >
                {!isEmpty(strategicDocuments) && (
                  <AvForm>
                    <div className="goals-dropdown-selection">
                      <Dropdown
                        placeholder="Report Year"
                        className="archive-dropdown"
                        fluid
                        selection
                        ma
                        options={this.optionArchiveYears()}
                        direction="left"
                        value={reportingDocumentId}
                        onChange={(event, data) =>
                          this.onChangeYearSelection(event, data)
                        }
                      />
                    </div>
                  </AvForm>
                )}
              </Grid.Column>
            }
            children={
              <GoalsTable
                selectedDocument={this.state.selectedDocument}
                strategicDocuments={strategicDocuments}
                reportingDocumentId={reportingDocumentId}
                {...this.props}
              />
            }
          />
        )}
      </>
    );
  }
}

class GoalsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentPage: 1,
    };
  }

  columns() {
    const { selectedDocument } = this.props;
    return [
      {
        title: <div>Goal</div>,
        width: '5',
        render: (data) => {
          return (
            <label className="goals-heading">
              {data.goalTitle ? data.goalTitle : '-'}
            </label>
          );
        },
      },
      {
        title: <div className="goal-table-header">Policy Domain</div>,
        width: '5',
        render: (data) => {
          return (
            <div className="goals-description">
              <label className="goals-policy">
                <span className="setHeading">Primary :</span>{' '}
                {data.primaryPolicyName ? (
                  <Label className="goal-labels" horizontal>
                    {data.primaryPolicyName}
                  </Label>
                ) : (
                  '-'
                )}
              </label>
              <label className="goals-policy">
                <span className="setHeading">Secondary :</span>{' '}
                {data.primaryPolicyId != 1 ? (
                  <Label className="goal-labels" horizontal>
                    Cultural
                  </Label>
                ) : data && data.secondaryPolicies ? (
                  map(data.secondaryPolicies, (item, i) => {
                    return item && item.policyName ? (
                      <Label className="goal-labels" key={i} horizontal>
                        {item.policyName}
                      </Label>
                    ) : (
                      '-'
                    );
                  })
                ) : (
                  '-'
                )}
              </label>
            </div>
          );
        },
      },
      {
        title: '',
        width: '1',
        render: (data) => {
          return (
            <div>
              {!selectedDocument.isArchived && (
                <div className="goals-actions">
                  <GoalsUpdate
                    currentPage={this.state.currentPage}
                    {...this.props}
                    selectedGoal={data}
                    resetPage={() => this.resetPage()}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: '',
        width: '1',
        render: (data) => {
          return (
            <div>
              {!selectedDocument.isArchived && (
                <div className="goals-actions">
                  <GoalsDelete
                    currentPage={this.state.currentPage}
                    {...this.props}
                    selectedGoal={data}
                    resetPage={() => this.resetPage()}
                  />
                </div>
              )}
            </div>
          );
        },
      },
    ];
  }

  departmentColumns() {
    const { selectedDocument } = this.props;
    return [
      {
        title: <div>Goal</div>,
        width: '5',
        render: (data) => {
          return (
            <label className="goals-heading">
              {data.goalTitle ? data.goalTitle : '-'}
            </label>
          );
        },
      },
      {
        title: <div className="goal-table-header">Policy Domain</div>,
        width: '5',
        render: (data) => {
          return (
            <div className="goals-description">
              <label className="goals-policy">
                <span className="setHeading">Primary :</span>{' '}
                {data.primaryPolicyName ? (
                  <Label className="goal-labels" horizontal>
                    {data.primaryPolicyName}
                  </Label>
                ) : (
                  '-'
                )}
              </label>
              <label className="goals-policy">
                <span className="setHeading">Secondary :</span>{' '}
                {data.primaryPolicyId != 1 ? (
                  <Label className="goal-labels" horizontal>
                    Cultural
                  </Label>
                ) : data && data.secondaryPolicies ? (
                  map(data.secondaryPolicies, (item, i) => {
                    return item && item.policyName ? (
                      <Label className="goal-labels" key={i} horizontal>
                        {item.policyName}
                      </Label>
                    ) : (
                      '-'
                    );
                  })
                ) : (
                  '-'
                )}
              </label>
            </div>
          );
        },
      },
      {
        title: <div className="goal-table-header">Department</div>,
        width: '2',
        render: (data) => {
          return (
            <>
              <div className="reference-container">
                <label>{size(data.departments)}</label>
                {size(data.departments) > 0 && (
                  <ViewListModal
                    departmentList={true}
                    title="Departments"
                    data={data.departments}
                  />
                )}
              </div>
            </>
          );
        },
      },

      {
        title: <div className="goal-table-header">Unit</div>,
        width: '2',
        render: (data) => {
          return (
            <>
              <div className="reference-container">
                <label>{size(data.units)}</label>
                {size(data.units) > 0 && (
                  <ViewListModal
                    departmentList={true}
                    title="Units"
                    data={data.units}
                  />
                )}
              </div>
            </>
          );
        },
      },
      {
        title: '',
        width: '1',
        render: (data) => {
          return (
            <div>
              {!selectedDocument.isArchived && (
                <div className="goals-actions">
                  <GoalsUpdate
                    currentPage={this.state.currentPage}
                    {...this.props}
                    selectedGoal={data}
                    resetPage={() => this.resetPage()}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: '',
        width: '1',
        render: (data) => {
          return (
            <div>
              {!selectedDocument.isArchived && (
                <div className="goals-actions">
                  <GoalsDelete
                    currentPage={this.state.currentPage}
                    {...this.props}
                    selectedGoal={data}
                    resetPage={() => this.resetPage()}
                  />
                </div>
              )}
            </div>
          );
        },
      },
    ];
  }

  pageChange(page) {
    const { auth, getOrgGoals, reportingDocumentId } = this.props;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        if (!isEmpty(auth.user) && auth.user.organisation) {
          getOrgGoals(page, 10, reportingDocumentId);
        }
      }
    );
  }

  resetPage() {
    this.setState({
      currentPage: 1,
    });
  }

  render() {
    const {
      organization: { orgGoals, organization },
      selectedDocument,
      account,
      strategicDocuments,
    } = this.props;

    const [orgSettings] =
      organization && organization.settings
        ? organization.settings
        : [{ department: false }];

    let documentURL = get(
      selectedDocument,
      'organisationDocument.documentUrl',
      ''
    );

    return (
      <Segment
        className="content-segment"
        loading={this.props.organization.loading}
      >
        {isEmpty(strategicDocuments) ? (
          <Grid>
            <EmptyContainer
              msg="No strategic documents have been created"
              childrenComponent={
                <NewStrategicPlan
                  firstTimeCreation={true}
                  {...this.props}
                  account={account}
                  edit={false}
                />
              }
            />
          </Grid>
        ) : (
          <Grid>
            {/* <div className="org-goals-file-row"> */}
            <Segment className="content-segment-settings-goals">
              <Grid className="grid-padding" columns={2} divided stackable>
                <Grid.Row className="first-row">
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    textAlign="left"
                    width={5}
                  >
                    {/* <Grid.Row> */}
                    <label className="org-title">
                      Organisation Strategic Document
                    </label>
                    {/* </Grid.Row> */}
                    {documentURL && (
                      // <Grid.Row>
                      <a
                        href={documentURL}
                        target="_blank"
                        className="href-label"
                      >
                        <Grid.Column>
                          <Icon color="blue" name="download" />
                          <label className="org-linkLabel">
                            {selectedDocument
                              ? get(selectedDocument, 'documentName', '')
                              : ''}
                          </label>
                        </Grid.Column>
                      </a>
                      // </Grid.Row>
                    )}
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    textAlign="right"
                  >
                    <div className="action-new">
                      <Dropdown
                        icon="ellipsis vertical"
                        direction="left"
                        inline
                        selectOnBlur={false}
                      >
                        <Dropdown.Menu>
                          <NewStrategicPlan
                            data={selectedDocument}
                            {...this.props}
                            account={account}
                            edit={true}
                          />
                          {!selectedDocument.isArchived && (
                            <NewStrategicPlan
                              data={selectedDocument}
                              {...this.props}
                              account={account}
                              edit={false}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            {/* </Grid.Row> */}
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                textAlign="right"
              ></Grid.Column>
              {!selectedDocument.isArchived && (
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  textAlign="right"
                >
                  <GoalsCreate
                    {...this.state}
                    {...this.props}
                    resetPage={() => this.resetPage()}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
            {orgGoals.docs && orgGoals.docs.length ? (
              <CustomTable
                header
                columns={
                  orgSettings.department
                    ? this.departmentColumns()
                    : this.columns()
                }
                data={orgGoals.docs}
                customClass="goal-table-segment"
                height={true}
                pagination
                handlePaginationChange={(page) => this.pageChange(page)}
                page={this.state.currentPage}
                noOfPages={orgGoals.pages}
                keyExtractor={(item, index) => item.id}
              />
            ) : (
              <EmptyContainer
                msg={`No ${
                  account ? 'account' : 'organisation'
                } goals have been added…`}
              />
            )}
          </Grid>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
    policies: state.extra.policies,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganization,
      getPolicies,
      getOrgGoals,
      createOrganizationGoal,
      updateOrganizationGoal,
      deleteOrganizationGoal,
      uploadOrgDocuments,
      postOrgDocuments,
      getOrgStrategicDocuments,
    },
    dispatch
  );
};

OrganizationGoals = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationGoals);

export { OrganizationGoals };

/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { Grid, Popup, Tab, Dropdown } from 'semantic-ui-react';
import { Chart } from 'chart.js';
import { Line } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

import {
  maxBy,
  isEmpty,
  sortBy,
  minBy,
  find,
  xor,
  filter,
  map,
  flattenDeep,
  groupBy,
  mapValues,
  get,
  compact,
  max,
  range,
  uniq,
  size,
  indexOf,
  cloneDeep,
} from '../../../utils/lodash';
import './report.scss';
import HeatMap from 'react-light-heatmap';
import html2canvas from 'html2canvas';
import {
  EmptyContainer,
  CustomReportHeader,
  CustomNotification,
  SeeMore,
  CustomSegment,
} from '../../../components';
import {
  getChartType,
  isProgramActivity,
  getProgramActivityTitle,
  checkMethodName,
} from '../../../utils';
import {
  updateOutcomeChart,
  addMultipleProjectOutcomeCharts,
} from '../../../store/actions';
import { OrganizationActivitySummary } from '../reportHome/activitySummary';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

//AggregateChart
class PostSurveyChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalParticipant: 0,
      totalResponse: 0,
      activityCount: 0,
      selfAssessedEvalPlan: 0,
      programsActivityCount: 0,
      linkedActivityCount: 0,
      evalCount: 0,
      lineChart: {
        type: 'line',
        data: {
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          datasets: [],
        },
        options: {
          animation: false,
          responsive: true,
          elements: {
            line: {
              tension: 0.2,
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              custom: function (tooltip) {
                if (!tooltip) return;
                tooltip.displayColors = false;
              },
              callbacks: {
                label: (context) => {
                  return `Post Result - ${context.label} ${
                    props.evalPlan ? ' Evaluation Plan' : ' Activity'
                  } Count - ${context.dataset.activityCount}`;
                },
                title: () => {
                  return '';
                },
              },
              displayColors: false,
            },
          },

          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              display: true,
              ticks: {
                max: 10,
                min: 1,
                stepSize: 1,
                font: {
                  size: 12,
                  family: 'Inter',
                  weight: '400',
                },
                callback: function (value, index, values) {
                  if (value === 1) {
                    return ['1', 'Not at All'];
                  } else if (value === 10) {
                    return ['10', 'Most Imaginable'];
                  }
                  return value;
                },
              },
              title: {
                display: true,
                // text: '1 = not at all through to 10 = most imagined',
                font: {
                  size: 14,
                  family: 'Inter',
                },
              },
            },
            y: {
              display: true,
              ticks: {
                min: 0,
                font: {
                  size: 12,
                  family: 'Inter',
                  weight: '400',
                },
              },
              title: {
                display: true,
                text: 'Number of participants evaluated',
                font: {
                  size: 14,
                  family: 'Inter',
                },
              },
            },
          },
        },
      },
      actualParticipants: '',
    };
  }

  componentDidMount() {
    const { participants } = this.props;
    if (participants) {
      this.createLineChart();
    }
  }

  componentDidUpdate(prevProps) {
    const { updateBase64Img, participants } = this.props;
    if (participants && !updateBase64Img) {
      this.createLineChart();
    }
    if (prevProps.participants !== participants) {
      this.createLineChart();
    }
  }

  average(data, yMax) {
    var totalParticipants = 0;
    var dataAverage = [];
    var average = 0;
    var total = 0;
    var denominator = 0;
    for (var i = 0; i < data.length; i++) {
      totalParticipants += data[i] * 1;
    }
    denominator = 10 * totalParticipants;
    for (var i = 0; i < data.length; i++) {
      total += data[i] * (i + 1);
    }
    average = (total / denominator) * 10;
    average = parseFloat(average).toFixed(2);
    if (isNaN(average)) {
      average = 0;
    }
    dataAverage.push({ x: average, y: 0 });
    dataAverage.push({ x: average, y: yMax });
    return dataAverage;
  }

  async createLineChart() {
    let lines = [];
    let { lineChart } = this.state;
    let { participants, type, method } = this.props;

    if (isEmpty(participants)) {
      lineChart.data.datasets = [];
      this.configureChart(lineChart);
    } else {
      try {
        let actualParticipants = '';
        if (type) {
          participants = filter(participants, (o) => {
            return o.name === type;
          });

          for (const item of participants) {
            actualParticipants = item.totalParticipants;
          }

          // if (type && method && goalType === 'all') {
          if (method === 'all') {
            // remove self-assessed from all options
            // let addResponses = 0,
            //   addParticipants = 0,
            //   byMethod = [],
            //   selfAssessedGroups = '';
            //   m.byMethod = byMethod;
            //   m.totalRespondents = addResponses;
            //   m.totalParticipants = addParticipants;
            //   m.selfAssessedGroups = selfAssessedGroups;
            // }
          } else {
            const change = map(participants, (m) => {
              const getMethod = find(
                m.byMethod,
                (by) => by.methodId === Number(method)
              );
              if (getMethod) {
                return getMethod;
              }
            });

            if (!isEmpty(change)) participants = compact(change) || [];
          }
          // } else if (type && goalType) {
          //   const change = map(participants, m => {
          //     const getType = find(
          //       m.byGoal,
          //       by => by.goalId === Number(goalType)
          //     );
          //     if (getType) {
          //       return getType;
          //     }
          //   });

          //   if (!isEmpty(change)) participants = compact(change) || [];
          // }
        } else {
          participants = [];
        }
        if (isEmpty(participants)) {
          this.setState({
            activityCount: 0,
          });
          return;
        }
        let totalParticipant = 0,
          totalResponse = 0,
          activityCount = 0,
          evalCount = 0,
          programsActivityCount = 0,
          linkedActivityCount = 0;

        participants.forEach((element) => {
          totalParticipant = element.totalParticipants;
          totalResponse += element.totalRespondents;
          activityCount = element.activityCount;
          evalCount = element.evalCount;
          programsActivityCount = element.programsActivityCount;
          linkedActivityCount = element.linkedActivityCount;
          const isIntercept = get(element, 'method', null) === 'Intercept';

          lines.push(
            {
              label: 'Average',
              data: this.average(element.data, max(element.data)),
              fill: true,
              backgroundColor: isIntercept
                ? '#424242'
                : `rgba(209, 100, 0, 0.6)`,
              borderColor: isIntercept ? '#424242' : 'rgba(209, 100, 0)',
              type: 'line',
            },
            {
              label: element.name,
              activityCount: element.activityCount,
              data: map(element.data, (data, key) => {
                return { x: key + 1, y: data };
              }),
              fill: true,
              backgroundColor: isIntercept
                ? 'rgba(211, 211, 211,0.6)'
                : `rgba(253, 143, 88, 0.5)`,
              borderColor: isIntercept
                ? 'rgba(211, 211, 211,0.6)'
                : 'rgba(253, 143, 88,0.1)',
              pointBorderColor: isIntercept
                ? 'rgba(211, 211, 211,0.6)'
                : 'rgba(253, 143, 88, 0.5)',
              pointBackgroundColor: '#fff',
              pointHoverBackgroundColor: isIntercept
                ? 'rgba(211, 211, 211,0.6)'
                : 'rgba(253, 143, 88, 0.5)',
              pointHoverBorderColor: 'rgba(220, 220, 220, 1)',
              type: 'line',
            }
          );
        });

        await this.setState((prevState) => ({
          totalParticipant,
          totalResponse,
          activityCount: activityCount,
          actualParticipants,
          evalCount: evalCount,
          programsActivityCount: programsActivityCount,
          linkedActivityCount: linkedActivityCount,
        }));

        if (activityCount !== 0) {
          lineChart.data.datasets = lines;
          this.configureChart(lineChart);
        }
      } catch (error) {
        lineChart.data.datasets = [];
        this.configureChart(lineChart);
      }
    }
  }

  configureChart = (dataset) => {
    const { updateBase64Img } = this.props;
    if (dataset.data.datasets && dataset.data.datasets.length !== 0) {
      const chartCanvas = ReactDOM.findDOMNode(this.chart);
      // const lineChart = new Chart(chartCanvas, dataset);
      // updateBase64Img(lineChart.toBase64Image());
      new Chart(chartCanvas, dataset);
      if (updateBase64Img) {
        setTimeout(() => {
          const postChart = document.getElementById(`post-chart`);
          if (postChart) {
            html2canvas(postChart).then((canvas) => {
              updateBase64Img(canvas.toDataURL());
            });
          }
        }, 1000);
      }
    }
  };

  render() {
    let {
      totalResponse,
      activityCount,
      actualParticipants,
      totalParticipant,
      evalCount,
      programsActivityCount,
      linkedActivityCount,
    } = this.state;
    let { outcome, evalPlan, date, method } = this.props;

    let summary = {
      totalParticipant: totalParticipant,
      totalResponse: totalResponse,
      activityCount: activityCount,
      evalCount: evalCount,
      programsActivityCount: programsActivityCount,
      linkedActivityCount: linkedActivityCount,
    };
    // DA DATA CREATION PLACE
    let da = { summary };
    //checking the method and passing total participants for aggregrate report
    if (method === 12) {
      da.summary.totalParticipant = actualParticipants;
    } else {
      da.summary.totalParticipant = totalParticipant; // this
    }

    if (activityCount === 0)
      return <EmptyContainer msg="No data in that method." />;
    return (
      <div id={`post-chart`}>
        <div>
          <CustomReportHeader
            date={date}
            outcome={outcome}
            evalPlan={evalPlan}
            method={method === '9' ? true : false}
            isIntercept={method === '12' ? true : false}
            da={da}
            legendResponse={true}
            {...this.props}
          />
        </div>
        <div className="report-border-container">
          {/* <canvas
            ref={chart => {
              this.chart = chart;
            }}
          /> */}
          <Line
            options={this.state.lineChart.options}
            data={this.state.lineChart.data}
          />
          <div className="bottom-info-container">
            <label>1 = Not at all</label>
            <label>10 = Most imagined</label>
          </div>
        </div>
      </div>
    );
  }
}

//SeparateChart
class PostTrendSurveyChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalParticipant: 0,
      totalResponse: 0,
      activityCount: 0,
      programsActivityCount: 0,
      linkedActivityCount: 0,
      evalCount: 0,
      lineChart: {
        type: 'line',
        data: {
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          // datasets: [],
        },
        options: {
          animation: false,
          responsive: true,
          elements: {
            line: {
              tension: 0.2,
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              custom: function (tooltip) {
                if (!tooltip) return;
                tooltip.displayColors = false;
              },

              callbacks: {
                label: (context) => {
                  return `Post Result - ${context.label}  ${
                    props.evalPlan ? 'Evaluation Plan' : 'Activity'
                  } Name - ${
                    context.chart.data.datasets[context.datasetIndex].label
                  }`;
                },
                title: () => {
                  return '';
                },
              },
              displayColors: false,
            },
          },
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              display: true,
              ticks: {
                max: 10,
                min: 1,
                stepSize: 1,
                font: {
                  size: 12,
                  family: 'Inter',
                  weight: '400',
                },
                callback: function (value, index, values) {
                  if (value === 1) {
                    return ['1', 'Not at All'];
                  } else if (value === 10) {
                    return ['10', 'Most Imaginable'];
                  }
                  return value;
                },
              },
              title: {
                display: true,
                // text: '1 = not at all through to 10 = most imagined',
                font: {
                  size: 14,
                  family: 'Inter',
                },
              },
            },
            y: {
              display: true,
              ticks: {
                min: 0,
                font: {
                  size: 12,
                  family: 'Inter',
                  weight: '400',
                },
              },
              title: {
                display: true,
                text: 'Number of participants evaluated',
                font: {
                  size: 14,
                  family: 'Inter',
                },
              },
            },
          },
        },
      },
      actualParticipants: '',
      // chartCanvas: null,
    };
  }

  componentDidMount() {
    const { participants } = this.props;
    if (participants) {
      this.createLineChart();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { updateBase64Img, participants } = this.props;
    if (participants && !updateBase64Img) {
      this.createLineChart();
    }
    if (prevProps.participants !== participants) {
      this.createLineChart();
    }
    const { chartCanvas, lineChart } = this.state;
    if (prevState.chartCanvas !== chartCanvas) {
      this.configureChart(lineChart);
    }
  }

  async createLineChart() {
    let { lineChart } = this.state;
    let { participants, type, method, documentation = false } = this.props;
    let lines = [];
    if (isEmpty(participants)) {
      lineChart.data.datasets = [];
      this.configureChart(lineChart);
    } else {
      try {
        let actualParticipants = '';
        if (type) {
          participants = filter(participants, (o) => {
            return o.name === type;
          });

          for (const item of participants) {
            actualParticipants = item.totalParticipants;
          }
          if (type && method) {
            if (method === 'all') {
              // Uncomment if using all methods
            } else {
              const change = map(participants, (m) => {
                const getMethod = find(
                  m.byMethod,
                  (by) => by.methodId === Number(method)
                );
                if (getMethod) {
                  return getMethod;
                }
              });

              if (!isEmpty(change)) participants = compact(change) || [];
            }
          }
        } else {
          participants = [];
        }
        if (isEmpty(participants)) {
          this.setState({
            activityCount: 0,
          });
          return;
        }
        let totalParticipant = 0,
          totalResponse = 0,
          activityCount = 0,
          programsActivityCount = 0,
          linkedActivityCount = 0,
          evalCount = 0;
        participants.forEach((element) => {
          totalParticipant = element.totalParticipants;
          totalResponse += element.totalRespondents;
          const isIntercept = get(element, 'method', null) === 'Intercept';
          activityCount = element.activityCount;
          evalCount = element.evalCount;
          programsActivityCount = element.programsActivityCount;
          linkedActivityCount = element.linkedActivityCount;

          element.dataAll.forEach((entry) => {
            let opacity = 0.5;
            if (documentation) {
              opacity = 1 / evalCount === 1 ? 0.5 : 1 / evalCount;
            } else {
              opacity = 1 / activityCount === 1 ? 0.5 : 1 / activityCount;
            }

            lines.push({
              label: entry.name,
              evalCount: element.evalCount,
              activityCount: element.activityCount,
              data: map(entry.data, (data, key) => {
                return { x: key + 1, y: data };
              }),
              fill: true,
              backgroundColor: isIntercept
                ? 'rgba(211, 211, 211,0.6)'
                : `rgba(253, 143, 88, ${opacity})`,
              borderColor: isIntercept
                ? 'rgba(211, 211, 211,0.6)'
                : 'rgba(253, 143, 88, 0.1)',
              pointBorderColor: isIntercept
                ? 'rgba(211, 211, 211,0.6)'
                : 'rgba(253, 143, 88, 0.5)',
              pointBackgroundColor: '#fff',
              pointHoverBackgroundColor: isIntercept
                ? 'rgba(211, 211, 211,0.6)'
                : 'rgba(253, 143, 88, 0.5)',
              pointHoverBorderColor: 'rgba(220, 220, 220, 1)',
              type: 'line',
            });
          });
        });

        this.setState({
          totalParticipant,
          totalResponse,
          activityCount,
          actualParticipants,
          evalCount,
          programsActivityCount,
          linkedActivityCount,
        });
        if (activityCount !== 0) {
          lineChart.data.datasets = lines;
          await this.setState({ lineChart });
          this.configureChart(lineChart);
        }
      } catch (error) {
        lineChart.data.datasets = [];
        this.configureChart(lineChart);
      }
    }
  }

  configureChart = (dataset) => {
    try {
      if (dataset.data.datasets && dataset.data.datasets.length !== 0) {
        const { updateBase64Img } = this.props;
        const chartCanvas = ReactDOM.findDOMNode(this.chart);
        this.setState({
          chartCanvas: chartCanvas,
        });
        // const lineChart = new Chart(chartCanvas, dataset);
        // updateBase64Img(lineChart.toBase64Image());
        if (chartCanvas) new Chart(chartCanvas, dataset);
        if (updateBase64Img) {
          setTimeout(() => {
            const postTrendChart = document.getElementById(`post-trend-chart`);
            if (postTrendChart) {
              html2canvas(postTrendChart).then((canvas) => {
                updateBase64Img(canvas.toDataURL());
              });
            }
          }, 1000);
        }
      }
    } catch (error) {
      console.error('Post TREND error', error);
      throw error;
    }
  };

  render() {
    let {
      totalParticipant,
      totalResponse,
      activityCount,
      evalCount,
      programsActivityCount,
      linkedActivityCount,
    } = this.state;

    let { outcome, evalPlan, date, method } = this.props;

    if (activityCount === 0)
      return <EmptyContainer msg="No data in that method." />;

    // DA DATA CREATION PLACE
    let summary = {
      totalParticipant: totalParticipant, // FIXME: need to investigate this
      totalResponse: totalResponse,
      activityCount: activityCount,
      evalCount: evalCount,
      programsActivityCount: programsActivityCount,
      linkedActivityCount: linkedActivityCount,
    };
    let da = { summary };

    //checking whether method is intercept and sending seperated total participant
    // if (method === '12') {
    //   da.summary.totalParticipant = totalParticipant;
    // } else {
    //   da.summary.totalParticipant = actualParticipants;
    // }

    return (
      <div id={`post-trend-chart`}>
        <CustomReportHeader
          method={method === '9' ? true : false}
          isIntercept={method === '12' ? true : false}
          showInterceptLegend={false}
          date={date}
          outcome={outcome}
          evalPlan={evalPlan}
          da={da}
          {...this.props}
        />
        <div className="report-border-container">
          {/* <canvas
            ref={chart => {
              this.chart = chart;
            }}
          /> */}

          <Line
            options={this.state.lineChart.options}
            data={this.state.lineChart.data}
          />
        </div>
        <div className="bottom-info-container">
          <label>1 = Not at all</label>
          <label>10 = Most imagined</label>
        </div>
      </div>
    );
  }
}

//Self Assessed Separate
class SelfAssessedPostTrendChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      empty: false,
      totalParticipant: 0,
      totalResponse: 0,
      activityCount: 0,
      evalPlansCount: '',
      chartCanvas: null,
      programsActivityCount: 0,
      linkedActivityCount: 0,
      lineChart: {
        type: 'line',
        data: {
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          datasets: [],
        },
        options: {
          animation: false,
          responsive: true,
          elements: {
            line: {
              tension: 0.2,
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              custom: function (tooltip) {
                if (!tooltip) return;
                tooltip.displayColors = false;
              },
              callbacks: {
                label: (context) => {
                  if (
                    isEmpty(
                      context.chart.data.datasets[context.datasetIndex].label
                    )
                  )
                    return;
                  return `EvalPlan - ${
                    context.chart.data.datasets[context.datasetIndex].evalPlan
                  }   ${'Activity'} Name - ${
                    context.chart.data.datasets[context.datasetIndex].label
                  }`;
                },
                title: () => {
                  return '';
                },
              },
              displayColors: false,
            },
          },
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              display: true,
              ticks: {
                max: 10,
                min: 1,
                stepSize: 1,
                font: {
                  size: 12,
                  family: 'Inter',
                  weight: '400',
                },
                callback: function (value, index, values) {
                  if (value === 1) {
                    return ['1', 'Not at All'];
                  } else if (value === 10) {
                    return ['10', 'Most Imaginable'];
                  }
                  return value;
                },
              },
              title: {
                display: true,
                // text: 'Evaluated Score',
                font: {
                  size: 14,
                  family: 'Inter',
                },
              },
            },
            y: {
              display: true,
              ticks: {
                min: 0,
                font: {
                  size: 12,
                  family: 'Inter',
                  weight: '400',
                },
              },
              title: {
                display: true,
                text: 'Number of participants evaluated',
                font: {
                  size: 14,
                  family: 'Inter',
                },
              },
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.setState(
      {
        activityCount: 0,
      },
      () => {
        this.createLineChart();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { chartCanvas, lineChart } = this.state;
    if (prevProps.outcome !== this.props.outcome) {
      this.createLineChart();
    }
    if (prevState.chartCanvas !== chartCanvas) {
      this.configureChart(lineChart);
    }
  }

  async createLineChart() {
    let lines = [];
    let { lineChart } = this.state;
    let { participants, type } = this.props;

    let method = '9';

    if (isEmpty(participants)) {
      lineChart.data.datasets = [];
      this.configureChart(lineChart);
    } else {
      try {
        if (type) {
          participants = filter(participants, (o) => {
            return o.name === type;
          });

          if (type && method) {
            const change = map(participants, (m) => {
              const getMethod = find(
                m.byMethod,
                (by) => by.methodId === Number(method)
              );
              if (getMethod) {
                return getMethod;
              }
            });

            if (!isEmpty(change)) participants = compact(change) || [];
          }
        } else {
          participants = [];
        }
        if (isEmpty(participants)) {
          this.setState({
            activityCount: 0,
          });
          return;
        }
        let totalParticipant = 0,
          totalResponse = 0,
          activityCount = 0,
          actualParticipants = 0,
          programsActivityCount = 0,
          linkedActivityCount = 0;

        let evalPlansCount = '';
        participants.forEach((element) => {
          totalResponse += element.totalRespondents;
          actualParticipants = element.totalParticipants;
          programsActivityCount = element.programsActivityCount;
          linkedActivityCount = element.linkedActivityCount;
          evalPlansCount = size(element.dataAll);
          const projectsGroup = groupBy(element.dataAll, (g) => g.projectId);
          const isIntercept = get(element, 'method', null) === 'Intercept';

          activityCount = size(projectsGroup);
          element.dataAll.forEach((entry) => {
            let mean = indexOf(entry.data, 1) + 1;
            const lowerBound = 1,
              upperBound = 10;

            const normalY = (x, mean, stdDev) =>
              Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));

            const getStdDeviation = (lowerBound, upperBound) =>
              (upperBound - lowerBound) / 4;

            const stdDev = getStdDeviation(lowerBound, upperBound);

            const generatePoints = (lowerBound, upperBound, stdDev) => {
              let min = lowerBound - 2 * stdDev;
              let max = upperBound + 2 * stdDev;
              let unit = (max - min) / 100;
              return range(min, max, unit);
            };

            let points = generatePoints(lowerBound, upperBound, stdDev);
            let labels = [];
            for (const axis of points) {
              if (axis >= 1 && axis <= 10) {
                labels.push(axis);
              }
            }
            labels = uniq(labels);

            let yValues = [];
            for (const x of labels) {
              yValues.push({ x, y: normalY(x, mean, stdDev) });
            }

            //straight line points
            let max_yValue = Math.max.apply(
              Math,
              yValues.map(function (o) {
                return o.y;
              })
            );

            let straightLine = [
              { x: mean, y: '0.00' },
              { x: mean, y: max_yValue },
            ];

            const opacity = 1 / evalPlansCount === 1 ? 0.5 : 1 / evalPlansCount;
            if (entry.totalRespondents >= 2) {
              lines.push(
                {
                  data: map(yValues, (data, key) => {
                    return { x: data.x, y: data.y };
                  }),
                  fill: true,
                  backgroundColor: `rgba(253, 143, 88,${opacity}`,
                  borderColor: `rgba(253, 143, 88,${opacity}`,
                  pointRadius: 0,
                  type: 'line',
                },
                {
                  data: straightLine,
                  label: entry.projectName,
                  evalPlan: entry.evalPlan,
                  pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                  type: 'line',
                  backgroundColor: 'rgba(183, 172, 245,0.001)',
                  borderColor: 'rgba(183, 172, 245,0.001)',
                  fill: 'start',
                  pointStyle: 'circle',
                  pointBorderColor: '#998BF2',
                  pointBackgroundColor: '#fff',
                  pointHoverBackgroundColor: '#998BF2',
                  pointHoverBorderColor: '#998BF2',
                }
              );
            } else {
              lines.push({
                label: entry.projectName,
                evalPlan: entry.evalPlan,
                activityCount: entry.activityCount,
                data: map(entry.data, (data, key) => {
                  return { x: key + 1, y: data };
                }),
                fill: true,
                backgroundColor: isIntercept
                  ? 'rgba(211, 211, 211,0.6)'
                  : `rgba(253, 143, 88, ${opacity})`,
                borderColor: isIntercept
                  ? 'rgba(211, 211, 211,0.6)'
                  : `rgba(253, 143, 88, ${opacity})`,
                pointBorderColor: isIntercept
                  ? '#424242'
                  : `rgba(253, 143, 88, ${opacity})`,
                pointBackgroundColor: '#fff',
                pointHoverBackgroundColor: isIntercept
                  ? '#424242'
                  : `rgba(253, 143, 88, ${opacity})`,
                pointHoverBorderColor: `rgba(253, 143, 88, ${opacity})`,
                type: 'line',
              });
            }
            return lines;
          });
        });

        this.setState({
          totalParticipant,
          totalResponse,
          activityCount,
          evalPlansCount,
          actualParticipants,
          programsActivityCount,
          linkedActivityCount,
        });

        if (activityCount !== 0) {
          lineChart.data.datasets = lines;
          await this.setState({ lineChart });
          this.configureChart(lineChart);
        }
      } catch (error) {
        lineChart.data.datasets = [];
        this.configureChart(lineChart);
      }
    }
  }

  configureChart = (dataset) => {
    try {
      if (dataset.data.datasets && dataset.data.datasets.length !== 0) {
        const { updateBase64Img } = this.props;
        const chartCanvas = ReactDOM.findDOMNode(this.chart);
        this.setState({
          chartCanvas: chartCanvas,
        });
        // const lineChart = new Chart(chartCanvas, dataset);
        // updateBase64Img(lineChart.toBase64Image());
        if (chartCanvas) new Chart(chartCanvas, dataset);
        if (updateBase64Img) {
          setTimeout(() => {
            const selfAssessedPostTrendChart = document.getElementById(
              `self-assessed-post-trend-chart`
            );
            if (selfAssessedPostTrendChart) {
              html2canvas(selfAssessedPostTrendChart).then((canvas) => {
                updateBase64Img(canvas.toDataURL());
              });
            }
          }, 1000);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    let {
      totalParticipant,
      totalResponse,
      activityCount,
      evalPlansCount,
      actualParticipants,
      programsActivityCount,
      linkedActivityCount,
    } = this.state;

    let { outcome, evalPlan, date, type, chartTypeLabel } = this.props;

    if (activityCount === 0)
      return <EmptyContainer msg="No data in that method." />;

    // DA DATA CREATION PLACE
    let summary = {
      totalParticipant: totalParticipant,
      totalResponse: totalResponse,
      activityCount: activityCount,
      actualParticipants: actualParticipants,
      programsActivityCount: programsActivityCount,
      linkedActivityCount: linkedActivityCount,
    };
    let da = { summary };

    return (
      <div id={`self-assessed-post-trend-chart`}>
        <CustomReportHeader
          isSelfAssessedMethod={true}
          date={date}
          type={type}
          chartTypeLabel={chartTypeLabel}
          outcome={outcome}
          evalPlan={evalPlan}
          da={da}
          evalPlansCount={evalPlansCount}
          {...this.props}
        />
        <div className="report-border-container">
          {' '}
          <canvas
            ref={(chart) => {
              this.chart = chart;
            }}
          />
          <div className="bottom-info-container">
            <label>1 = Not at all</label>
            <label>10 = Most imagined</label>
          </div>
        </div>
      </div>
    );
  }
}

//Individual - Rate of change heat
export class PostPreSurvey extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      xLabels: [],
      yLabels: [],
      data: [],
      xLabelsVisibility: false,
      yLabelsVisibility: [],
      totalParticipants: 0,
      totalRespondents: 0,
      activityCount: 0,
      evalCount: 0,
      programsActivityCount: 0,
      linkedActivityCount: 0,
    };
  }

  async setData() {
    try {
      let {
        participantsChange,
        type,
        activity,
        method,
        fromDocumentation,
        participantsChangesDocumentation,
      } = this.props;

      let clonedParticipantsChange = cloneDeep(participantsChange);
      if (fromDocumentation) {
        clonedParticipantsChange = participantsChangesDocumentation;
      }
      let flatArr = [];
      if (!activity) {
        if (type) {
          clonedParticipantsChange = filter(clonedParticipantsChange, (o) => {
            return type === o.name;
          });
          if (type && method) {
            if (method === 'all') {
              // Uncomment if using all methods
              // const change = map(participants, m => m.byMethod) || [];
              // if(!isEmpty(change)) participants = flatten(change);

              this.setState({
                totalParticipants:
                  clonedParticipantsChange[0].totalParticipants,
                totalRespondents: clonedParticipantsChange[0].totalRespondents,
                activityCount: clonedParticipantsChange[0].activityCount,
                evalCount: clonedParticipantsChange[0].evalCount,
                programsActivityCount:
                  clonedParticipantsChange[0].programsActivityCount,
                linkedActivityCount:
                  clonedParticipantsChange[0].linkedActivityCount,
              });
            } else {
              const change =
                map(clonedParticipantsChange, (m) => {
                  const getMethod = find(
                    m.byMethod,
                    (by) => by.methodId === Number(method)
                  );

                  if (getMethod) {
                    this.setState({
                      totalParticipants: getMethod.totalParticipants,
                      totalRespondents: getMethod.totalRespondents,
                      activityCount: getMethod.activityCount,
                      evalCount: getMethod.evalCount,
                      programsActivityCount: getMethod.programsActivityCount,
                      linkedActivityCount: getMethod.linkedActivityCount,
                    });
                    return getMethod;
                  }
                }) || [];
              if (!isEmpty(change))
                clonedParticipantsChange = compact(change) || [];
            }
          }
        } else {
          clonedParticipantsChange = [];
        }
        let resultsArr = [];
        if (isEmpty(clonedParticipantsChange)) {
          this.setState({ data: [] });
          return;
        }
        // create array with all the types of participantChange
        clonedParticipantsChange.forEach((item) => {
          resultsArr.push(item.results);
        });

        // make one single array - flat array
        flatArr = flattenDeep(resultsArr);
      } else {
        flatArr = clonedParticipantsChange;
      }

      //group by possible outcomes
      let groupedArr = groupBy(flatArr, 'preResult');
      groupedArr = mapValues(groupedArr, (data) => {
        return groupBy(data, 'postResult');
      });

      //get the total count of each possibilities
      for (var item in groupedArr) {
        for (var value in groupedArr[item]) {
          let total = 0;
          groupedArr[item][value].map((element) => {
            if (
              parseInt(element.preResult, 10) <=
              parseInt(element.postResult, 10)
            ) {
              total = total + parseInt(element.count, 10);
              element.count = total;
            } else {
              element.count = 0;
            }
            return element;
          });
        }
      }
      //arrange the possibilities to get only the total count in one possibility
      let arrangedResults = [];
      for (var item in groupedArr) {
        for (var value in groupedArr[item]) {
          let finalItem = maxBy(groupedArr[item][value], (o) => {
            return o.count;
          });
          arrangedResults.push(finalItem);
        }
      }

      let { xLabels, xLabelsVisibility, yLabelsVisibility } = this.state;
      xLabels = new Array(10).fill(0).map((_, i) => `${i + 1}`);

      //create all possible pre-post value outcomes
      let verticleAxisLabels = [];
      for (var j = 1; j <= 10; j++) {
        for (var i = 1; i <= 10; i++) {
          if (j <= i) {
            verticleAxisLabels.push(j + '-' + i);
          }
        }
      }

      //display only the values which have equal pre-post values
      yLabelsVisibility = verticleAxisLabels.map((item) => {
        let prePostVal = item.split('-');
        if (prePostVal[0] === prePostVal[1]) {
          return item;
        } else {
          return '';
        }
      });
      let totalCount = 0;
      let data; //final data array to be displayed in heat map. 2nd order array
      if (!isEmpty(arrangedResults)) {
        arrangedResults = sortBy(arrangedResults, [{ preResult: 'asc' }]);
        arrangedResults.forEach((item) => {
          let count = parseInt(item.count);
          totalCount = count + totalCount;
        });
        const percentageArr = [];
        arrangedResults.forEach((res, i) => {
          let count = parseInt(res.count);
          res.percentage = (count / totalCount).toFixed(2);
          percentageArr.push((count / totalCount).toFixed(2));
        });
        let maxCount = maxBy(arrangedResults, (o) => {
          return o.percentage;
        });
        let minCount = minBy(arrangedResults, (o) => {
          return o.percentage;
        });
        arrangedResults.forEach((item) => {
          item.per =
            0.09 +
            parseFloat(minCount.percentage) +
            ((1 - 0.09) /
              (parseFloat(maxCount.percentage) -
                parseFloat(minCount.percentage))) *
              (parseFloat(item.percentage) - parseFloat(minCount.percentage));
        });
        data = verticleAxisLabels.reverse().map((item, i) => {
          let prePostVal = item.split('-');
          let existingPossibility = find(arrangedResults, (o) => {
            return (
              o.preResult == prePostVal[0] && o.postResult == prePostVal[1]
            );
          });
          let activityCount = find(arrangedResults, (o, i) => {
            return (o.activityCount =
              clonedParticipantsChange[i].activityCount);
          });
          if (existingPossibility) {
            return xLabels.map((item) => {
              if (
                parseInt(existingPossibility.preResult, 10) <= item &&
                item <= parseInt(existingPossibility.postResult, 10)
              ) {
                return {
                  percentage: existingPossibility.per || 1,
                  preResult: prePostVal[0],
                  postResult: prePostVal[1],
                  count: existingPossibility.count,
                  activityCount: activity ? 0 : activityCount.activityCount,
                };
              } else {
                return item * 0;
              }
            });
          } else {
            return xLabels.map((item) => item * 0);
          }
        });
      } else {
        data = verticleAxisLabels.reverse().map((item, i) => {
          return xLabels.map((item) => item * 0);
        });
      }

      this.setState(
        {
          xLabels: xLabels,
          yLabels: verticleAxisLabels,
          data: data,
          xLabelsVisibility: xLabelsVisibility,
          yLabelsVisibility: yLabelsVisibility.reverse(),
        },
        () => {
          this.configureChart();
        }
      );
    } catch (error) {
      // console.error('PostPreSurvey -> componentDidMount -> error', error);
    }
  }

  configureChart = () => {
    const { updateBase64Img } = this.props;
    if (updateBase64Img) {
      setTimeout(() => {
        const heatChart = document.getElementById('heat-map-chart');
        if (heatChart) {
          html2canvas(heatChart).then((canvas) => {
            updateBase64Img(canvas.toDataURL());
          });
        }
      }, 1000);
    }
  };

  renderCellHover({ title, ...rest }) {
    return (
      <Popup
        className="pop-up"
        content={this.getTitle(rest)}
        trigger={<div {...rest} />}
        disabled={rest.value === 0}
      />
    );
  }

  getTitle(rest) {
    const { data } = this.state;
    const { activity, evalPlan } = this.props;
    let possibleOutcome;

    if (rest.value !== 0) {
      possibleOutcome = find(data[rest.y], (o) => {
        return !isEmpty(o);
      });
      let hoverStr = (
        <p>
          {'pre result: ' + possibleOutcome.preResult + ','}
          <br />
          {'post result: ' + possibleOutcome.postResult + ','}
          <br />
          {'count: ' + possibleOutcome.count + ','}
          <br />
          {!activity ? (
            <>
              {`${evalPlan ? 'evaluation plan' : 'activity'} count: ${
                possibleOutcome.activityCount
              }`}
              <br />
            </>
          ) : null}
        </p>
      );
      return hoverStr;
    } else {
      return 'no response';
    }
  }

  componentWillMount() {
    this.setData();
  }

  componentDidMount() {
    this.setData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      xor(prevProps.participantsChange, this.props.participantsChange)
        .length !== 0
    ) {
      this.setData();
    }
    if (this.props.type !== prevProps.type) {
      this.setData();
    }
    if (this.props.method !== prevProps.method) {
      this.setData();
    }
  }

  render() {
    const {
      xLabels,
      data,
      yLabelsVisibility,
      totalParticipants,
      totalRespondents,
      activityCount,
      evalCount,
      programsActivityCount,
      linkedActivityCount,
    } = this.state;
    const {
      date,
      outcome,
      evalPlan,
      method,
      evaluationObject,
      fromDocumentation,
    } = this.props;

    if (isEmpty(data)) return <EmptyContainer msg="No data in that method." />;

    let summary = {
      totalParticipant: totalParticipants,
      totalResponse: totalRespondents,
      activityCount: activityCount,
      evalCount: evalCount,
      programsActivityCount: programsActivityCount,
      linkedActivityCount: linkedActivityCount,
    };

    // DA DATA CREATION PLACE
    let da = {};
    if (fromDocumentation) {
      let documentationSummary = {
        totalParticipant: evaluationObject.totalParticipants,
        totalResponse: evaluationObject.actualParticipantsEvaluated,
      };
      da.summary = documentationSummary;
    } else {
      da.summary = summary;
    }

    return (
      <div className="heat-map" id="heat-map-chart">
        <CustomReportHeader
          date={date}
          outcome={outcome}
          da={da}
          evalPlan={evalPlan}
          topLegends={false}
          isIntercept={method === '12' ? true : false}
          //single true to documentation
          isDocumentationLegends={fromDocumentation}
          {...this.props}
        />

        <div className="heat-map-container">
          <div className="left">
            <div className="y-axis-label">Pre-Post</div>
          </div>
          <div className="right">
            {data ? (
              <HeatMap
                xLabels={xLabels}
                yLabels={yLabelsVisibility}
                xLabelsLocation={'bottom'}
                yLabelsVisibility={yLabelsVisibility}
                xLabelWidth={100}
                labels={['Title']}
                yLabelWidth={20}
                yLabelTextAlign={'right'}
                data={data}
                cellStyle={(
                  background,
                  value,
                  min,
                  max,
                  data,
                  x,
                  y,
                  height,
                  width
                ) => ({
                  background: `rgb(254, 169, 78, ${value.percentage})`,
                  fontSize: '0.313rem',
                  color: '#000',
                  height: '0.625rem',
                  width: '5vw',
                  padding: '0rem',
                  border: '0.063rem solid #e7e7e7',
                  margin: '0',
                })}
                components={{
                  Cell: (v) => this.renderCellHover(v),
                }}
              />
            ) : (
              <div className="no-data-div">No responses</div>
            )}
            <div className="x-axis-label">
              {/* <div>1 = Not at all</div>
              <div>Evaluated score</div>
              <div>10 = Most imagined</div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//Trend - Rate of change done
var chartdata = {};
class PostPreTrendSurvey extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      empty: true,
      chartSetData: {
        type: 'line',
        data: {
          datasets: [],
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        options: {
          animation: false,
          responsive: true,
          elements: {
            line: {
              tension: 0.2,
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              custom: function (tooltip) {
                if (!tooltip) return;
                tooltip.displayColors = false;
              },
              callbacks: {
                label: (context) => {
                  let resType =
                    context.datasetIndex == 2 ? 'Pre result' : 'Post result';
                  return `${resType} - ${context.formattedValue} ${
                    props.evalPlan ? 'Evaluation Plan' : 'Activity'
                  } Name - ${
                    context.chart.data.datasets[context.datasetIndex].label
                  }`;
                },
                title: () => {
                  return '';
                },
              },
              displayColors: false,
            },
          },
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              display: true,
              ticks: {
                max: 10,
                min: 1,
                stepSize: 1,
                font: {
                  size: 12,
                  family: 'Inter',
                  weight: '400',
                },
                callback: function (value, index, values) {
                  if (value === 1) {
                    return ['1', 'Not at All'];
                  } else if (value === 10) {
                    return ['10', 'Most Imaginable'];
                  }
                  return value;
                },
              },
              title: {
                display: true,
                // text: 'Evaluated Score',
                font: {
                  size: 14,
                  family: 'Inter',
                },
              },
            },
            y: {
              display: true,
              ticks: {
                min: 0,
                font: {
                  size: 12,
                  family: 'Inter',
                  weight: '400',
                },
              },
              title: {
                display: true,
                text: 'Number of participants evaluated',
                font: {
                  size: 14,
                  family: 'Inter',
                },
              },
            },
          },
        },
      },
    };
  }

  chart = null;
  componentDidMount() {
    const { participantsChangePrePost } = this.props;
    if (participantsChangePrePost) {
      this.setalldataTograph(participantsChangePrePost);
    }
  }

  componentDidUpdate() {
    const { participantsChangePrePost, updateBase64Img } = this.props;
    if (participantsChangePrePost && !updateBase64Img) {
      this.setalldataTograph(participantsChangePrePost);
    }
  }

  renderChart() {
    const { participantsChangePrePost } = this.props;
    this.setalldataTograph(participantsChangePrePost);
  }

  async setalldataTograph(participantsChangePrePost) {
    let { chartSetData } = this.state;

    try {
      const da = await this.loadData(participantsChangePrePost);
      if (!isEmpty(da.data)) {
        this.setState({
          empty: false,
        });

        chartSetData.data.datasets = [
          {
            label: da.series[1] ? da.series[1] : 'After Average',
            data: da.data[1],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: '#D16400',
            borderColor: '#D16400',
            fill: true,
            pointStyle: 'circle',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
          },
          {
            label: da.series[3] ? da.series[3] : 'Before Average',
            data: da.data[3],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: '#2F80ED',
            borderColor: '#2F80ED',
            fill: true,
            pointStyle: 'circle',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
          },
          {
            label: da.series[0] ? da.series[0] : 'After Responses',
            data: da.data[0],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: 'rgba(253, 143, 88, 0.5)',
            borderColor: '#F8CCA4',
            fill: 'start',
            pointStyle: 'circle',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
          },
          {
            label: da.series[2] ? da.series[2] : 'Before Responses',
            data: da.data[2],
            pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
            type: 'line',
            backgroundColor: 'rgba(47, 128, 237, 0.5)',
            borderColor: '#97BFF6',
            fill: 'start',
            pointStyle: 'circle',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
          },
        ];
      } else {
        chartSetData.data.datasets = [];
        this.setState({
          empty: true,
        });
      }

      if (da.summary.activityCount !== 0) {
        this.configureChart(chartSetData);
      }
    } catch (error) {
      this.configureChart(chartSetData);
    }
  }

  loadData(participantsChangePrePost) {
    const { method } = this.props;
    var obj = participantsChangePrePost;
    obj.dateMeasured = obj.dateMeasured ? new Date(obj.dateMeasured) : null;
    let object,
      data = [],
      totalParticipant = 0,
      totalResponse = 0,
      evalCount = 0,
      activityCount = 0,
      programsActivityCount = 0,
      linkedActivityCount = 0;
    for (object of obj) {
      if (object.name == this.props.type) {
        if (object.name == this.props.type) {
          totalParticipant = object.totalParticipants;
          totalResponse = object.totalRespondents;
          evalCount = object.evalCount;
          activityCount = object.activityCount;
          programsActivityCount = object.programsActivityCount
            ? object.programsActivityCount
            : 0;
          linkedActivityCount = object.linkedActivityCount
            ? object.linkedActivityCount
            : 0;

          if (method == 'all') {
            data = object.data;
          } else {
            const getMethod = find(
              object.byMethod,
              (by) => by.methodId === Number(method)
            );

            if (getMethod) {
              totalParticipant = getMethod.totalParticipants;
              totalResponse = getMethod.totalRespondents;
              evalCount = getMethod.evalCount;
              activityCount = getMethod.activityCount;
              data = getMethod.data;
              programsActivityCount = getMethod.programsActivityCount;
              linkedActivityCount = getMethod.linkedActivityCount;
            } else {
              activityCount = 0;
              data = [];
              programsActivityCount = 0;
              linkedActivityCount = 0;
            }
          }
        }
      }
    }
    if (obj)
      if (!obj.participantResponse) {
        if (Boolean(obj.cumulative)) {
          obj.participantResponse = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          ];
        } else {
          obj.participantResponse = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          ];
        }
      }
    chartdata = {};
    chartdata = this.formatChartData(data);
    obj.responseTotal = this.responseTotal(data);
    chartdata.labels = [
      'not-at-all',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'most-imaginable',
    ];

    chartdata.colors = [
      '#0868ac',
      { backgroundColor: '#0868ac', borderColor: '#0868ac' },
      '#7bccc4',
      { backgroundColor: '#7bccc4', borderColor: '#7bccc4' },
      '#0868ac',
      '#949FB1',
      '#4D5360',
    ];

    chartdata.summary = {
      totalResponse,
      totalParticipant,
      evalCount,
      activityCount,
      programsActivityCount,
      linkedActivityCount,
    };
    chartdata.onClick = function (points, evt) {};
    chartdata.datasetOverride = [];
    if (chartdata.data) {
      if (chartdata.data.length == 2) {
        chartdata.series = ['Responses', 'Average'];
      }
      if (chartdata.data.length == 4) {
        chartdata.series = [
          'After Responses',
          'After Average',
          'Before Responses',
          'Before Average',
        ];
      }
    }
    return chartdata;
  }

  formatChartData(getdata) {
    if (getdata) {
      var chart = {};
      var dataSet = [];
      var chartData = [];
      var yMax = 0;
      var data = [];

      for (var x = 0; x < getdata.length; x++) {
        if (Array.isArray(getdata[x])) {
          data.push(getdata[x]);
        } else {
          var result = Object.keys(getdata[x]).map((key) => {
            return getdata[x][key];
          });
          if (result.length === 11) {
            result.pop();
          }
          data.push(result);
        }
      }

      for (var x = 0; x < data.length; x++) {
        for (var i = 0; i < data[x].length; i++) {
          if (data[x][i] > yMax) {
            yMax = data[x][i];
          }
        }
      }
      for (var x = 0; x < data.length; x++) {
        for (var i = 0; i < data[x].length; i++) {
          if (data[x][i] > yMax) {
            yMax = data[x][i];
          }
          chartData.push({ x: i + 1, y: data[x][i] });
        }
        dataSet.push(chartData);
        chartData = null;
        chartData = [];
        dataSet.push(this.average(data[x], yMax));
      }

      if (dataSet.length == 2) {
        chart.data = dataSet;
      } else if (dataSet.length == 4) {
        chart.data = dataSet;
      }
      return chart;
    }
  }

  average(data, yMax) {
    var totalParticipants = 0;
    var dataAverage = [];
    var average = 0;
    var total = 0;
    var denominator = 0;
    if (data) {
      for (var i = 0; i < data.length; i++) {
        totalParticipants += data[i] * 1;
      }
      denominator = 10 * totalParticipants;
      for (var i = 0; i < data.length; i++) {
        total += data[i] * (i + 1);
      }
      average = (total / denominator) * 10;
      average = parseFloat(average).toFixed(2);
      if (isNaN(average)) {
        average = 0;
      }
      dataAverage.push({ x: average, y: 0 });
      dataAverage.push({ x: average, y: yMax });
    }
    return dataAverage;
  }

  responseTotal(getdata) {
    var responseTotal = [];
    if (getdata) {
      var numberOfSeries = getdata.length;
      var temp = 0;
      var data = [];

      for (var x = 0; x < getdata.length; x++) {
        if (Array.isArray(getdata[x])) {
          data.push(getdata[x]);
        } else {
          var result = Object.keys(getdata[x]).map(function (key) {
            return getdata[x][key];
          });
          if (result.length === 11) {
            result.pop();
          }
          data.push(result);
        }
      }

      for (var a = 0; a < numberOfSeries; a++) {
        if (data[a]) {
          for (var i = 0; i < data[a].length; i++) {
            temp += data[a][i] * 1;
          }
        }
        responseTotal[a] = temp;
        temp = 0;
      }
    }
    return responseTotal;
  }

  configureChart = (dataset) => {
    try {
      if (!dataset.data.datasets) return;
      if (dataset.data.datasets.length !== 0) {
        const { updateBase64Img } = this.props;
        const chartCanvas = ReactDOM.findDOMNode(this.chart);
        // const lineChart = new Chart(chartCanvas, dataset);
        // updateBase64Img(lineChart.toBase64Image());

        if (chartCanvas) new Chart(chartCanvas, dataset);
        if (updateBase64Img !== undefined) {
          setTimeout(() => {
            const postPreTrendChart =
              document.getElementById(`postPre-trend-chart`);
            if (postPreTrendChart) {
              html2canvas(postPreTrendChart).then((canvas) => {
                updateBase64Img(canvas.toDataURL());
              });
            }
          }, 1000);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    const { empty } = this.state;
    let { outcome, evalPlan, participantsChangePrePost, date, method } =
      this.props;

    // DA DATA CREATION PLACE
    const da = this.loadData(participantsChangePrePost);
    if (empty || da.summary.activityCount === 0)
      return <EmptyContainer msg="No data in that method." />;

    return (
      <div id={`postPre-trend-chart`}>
        <CustomReportHeader
          outcome={outcome}
          evalPlan={evalPlan}
          da={da}
          date={date}
          allLegends={true}
          isIntercept={method === '12' ? true : false}
          {...this.props}
        />
        <div className="report-border-container">
          <canvas
            ref={(chart) => {
              this.chart = chart;
            }}
          />
        </div>
      </div>
    );
  }
}

//Self Assessed Trend
class SelfAssessedPostPreTrend extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      empty: true,
      programsActivityCount: 0,
      linkedActivityCount: 0,
      chartSetData: {
        type: 'line',
        data: {
          datasets: [],
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        options: {
          animation: false,
          responsive: true,
          elements: {
            line: {
              tension: 0.2,
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              custom: function (tooltip) {
                if (!tooltip) return;
                tooltip.displayColors = false;
              },
              callbacks: {
                label: (context) => {
                  if (context.datasetIndex === 0) return;
                  if (
                    isEmpty(
                      context.chart.data.datasets[context.datasetIndex].label
                    )
                  )
                    return;
                  return ` ${'Activity Name :'} ${
                    context.chart.data.datasets[context.datasetIndex].label
                  } , ${'Evaluation Plan Name :'} ${
                    context.chart.data.datasets[context.datasetIndex]
                      .evalPlanName
                  } `;
                },
                title: () => {
                  return '';
                },
              },
              displayColors: false,
            },
          },
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              display: true,
              ticks: {
                max: 10,
                min: 1,
                stepSize: 1,
                font: {
                  size: 12,
                  family: 'Inter',
                  weight: '400',
                },
                callback: function (value, index, values) {
                  if (value === 1) {
                    return ['1', 'Not at All'];
                  } else if (value === 10) {
                    return ['10', 'Most Imaginable'];
                  }
                  return value;
                },
              },
              title: {
                display: true,
                // text: '1 = not at all through to 10 = most imagined',
                font: {
                  size: 14,
                  family: 'Inter',
                },
              },
            },
            y: {
              display: false,
              ticks: { min: 0 },
            },
          },
        },
      },
      totalResponse: 0,
      totalParticipant: 0,
      activityCount: '',
      evalPlansCount: '',
      actualParticipants: 0,
    };
  }

  chart = null;
  componentDidMount() {
    const { participantsChangePrePost } = this.props;
    if (participantsChangePrePost) {
      this.setalldataTograph(participantsChangePrePost);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { outcome } = this.props;
    if (prevProps.outcome !== outcome) {
      this.renderChart();
    }
  }

  renderChart() {
    const { participantsChangePrePost } = this.props;
    this.setalldataTograph(participantsChangePrePost);
  }

  async setalldataTograph(participantsChangePrePost) {
    let { chartSetData } = this.state;

    try {
      const da = await this.loadData(participantsChangePrePost);

      let totalParticipant = 0,
        totalResponse = 0,
        actualParticipants = 0,
        evalPlansCount = '',
        programsActivityCount = 0,
        linkedActivityCount = 0;

      totalParticipant = da.totalParticipants;
      actualParticipants = da.totalParticipants;
      evalPlansCount = da.evalPlansCount;
      programsActivityCount = da.programsActivityCount;
      linkedActivityCount = da.linkedActivityCount;

      let lines = [];
      if (!isEmpty(da.chartdata)) {
        this.setState({
          empty: false,
        });

        let allData = [];

        for (const item of da.chartdata) {
          totalResponse += item.totalRespondents;

          for (const obj of item.data) {
            let index = indexOf(obj, 1) + 1;
            allData.push({
              mean: index,
              projectName: item.projectName,
              evalPlan: item.evalPlan,
              totalResponse: totalResponse,
              responses: item.totalRespondents,
              data: obj,
            });
          }
        }

        let projectBy = groupBy(allData, 'projectName');
        let activityCount = size(projectBy);

        let i = 0;
        for (const obj of allData) {
          const lowerBound = 1,
            upperBound = 10;

          const normalY = (x, mean, stdDev) =>
            Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));

          const getStdDeviation = (lowerBound, upperBound) =>
            (upperBound - lowerBound) / 4;

          const stdDev = getStdDeviation(lowerBound, upperBound);

          const generatePoints = (lowerBound, upperBound, stdDev) => {
            let min = lowerBound - 2 * stdDev;
            let max = upperBound + 2 * stdDev;
            let unit = (max - min) / 100;
            return range(min, max, unit);
          };

          let points = generatePoints(lowerBound, upperBound, stdDev);

          let labels = [];
          for (const axis of points) {
            if (axis >= 1 && axis <= 10) {
              labels.push(axis);
            }
          }
          labels = uniq(labels);

          let yValues = [];
          labels.forEach((x) => {
            yValues.push({ x, y: normalY(x, obj.mean, stdDev) });
          });

          chartSetData.data = {
            labels: labels,
          };

          //straight line points
          let max_yValue = Math.max.apply(
            Math,
            yValues.map(function (o) {
              return o.y;
            })
          );

          let straightLine = [
            { x: obj.mean, y: '0.00' },
            { x: obj.mean, y: max_yValue },
          ];

          const opacity = 1 / evalPlansCount === 1 ? 0.5 : 1 / evalPlansCount;

          if (obj.responses >= 2) {
            lines.push(
              {
                data: yValues,
                type: 'line',
                backgroundColor:
                  i % 2 === 0
                    ? `rgba(253, 143, 88, ${opacity < 0.03 ? 0.04 : opacity})`
                    : `rgba(47, 128, 237, ${opacity})`,
                borderColor:
                  i % 2 === 0
                    ? `rgba(253, 143, 88, ${opacity < 0.03 ? 0.04 : opacity})`
                    : `rgba(47, 128, 237, ${opacity})`,
                fill: 'start',
                pointStyle: 'circle',
                pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                pointHitRadius: 0,
              },
              {
                data: straightLine,
                label: obj.projectName,
                evalPlanName: obj.evalPlan,
                pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                type: 'line',
                backgroundColor: 'rgba(183, 172, 245,0.001)',
                borderColor: 'rgba(183, 172, 245,0.001)',
                fill: 'start',
                pointStyle: 'circle',
                pointBorderColor: i % 2 === 0 ? '#998BF2' : 'B7ACF5',
                pointBackgroundColor: '#fff',
                pointHoverBackgroundColor: i % 2 === 0 ? '#998BF2' : 'B7ACF5',
                pointHoverBorderColor: i % 2 === 0 ? '#998BF2' : 'B7ACF5',
              }
            );
          } else {
            lines.push({
              label: obj.projectName,
              activityCount: activityCount,
              evalPlanName: obj.evalPlan,
              data: map(obj.data, (data, key) => {
                return { x: key + 1, y: data };
              }),
              fill: true,
              backgroundColor:
                i % 2 === 0
                  ? `rgba(253, 143, 88, ${opacity < 0.03 ? 0.04 : opacity})`
                  : `rgba(47, 128, 237, ${opacity < 0.03 ? 0.04 : opacity})`,
              borderColor:
                i % 2 === 0
                  ? `rgba(253, 143, 88, ${opacity < 0.03 ? 0.04 : opacity})`
                  : `rgba(47, 128, 237, ${opacity < 0.03 ? 0.04 : opacity})`,
              pointBorderColor: 'B7ACF5',
              pointBackgroundColor: '#fff',
              pointHoverBackgroundColor: 'B7ACF5',
              pointHoverBorderColor: 'B7ACF5',
              type: 'line',
            });
          }
          i++;
        }

        chartSetData.data.datasets = lines;

        this.setState((prevState) => ({
          totalParticipant,
          totalResponse,
          activityCount,
          evalPlansCount,
          actualParticipants,
          programsActivityCount,
          linkedActivityCount,
          empty: prevState.activityCount === 0 ? true : false,
        }));
      } else {
        chartSetData.data.datasets = [];
        this.setState({
          empty: true,
        });
      }

      if (!this.state.empty) {
        this.configureChart(chartSetData);
      }
    } catch (error) {
      this.configureChart(chartSetData);
    }
  }

  loadData(participantsChangePrePost) {
    let method = '9';
    var obj = participantsChangePrePost;
    let totalParticipants = '',
      evalPlansCount = '',
      programsActivityCount = 0,
      linkedActivityCount = 0;

    obj.dateMeasured = obj.dateMeasured ? new Date(obj.dateMeasured) : null;
    let object,
      data = [];
    for (object of obj) {
      if (object.name == this.props.type) {
        if (object.name == this.props.type) {
          const getMethod = find(
            object.byMethod,
            (by) => by.methodId === Number(method)
          );
          programsActivityCount = object.programsActivityCount
            ? object.programsActivityCount
            : 0;
          linkedActivityCount = object.linkedActivityCount
            ? object.linkedActivityCount
            : 0;
          if (getMethod) {
            totalParticipants = getMethod.totalParticipants;
            evalPlansCount = getMethod.evalCount;
            data = getMethod.data;
            programsActivityCount = getMethod.programsActivityCount;
            linkedActivityCount = getMethod.linkedActivityCount;
          } else {
            data = [];
            programsActivityCount = 0;
            linkedActivityCount = 0;
          }
        }
      }
    }
    if (obj)
      if (!obj.participantResponse) {
        if (Boolean(obj.cumulative)) {
          obj.participantResponse = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          ];
        } else {
          obj.participantResponse = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          ];
        }
      }
    chartdata = {};
    chartdata = data;
    return {
      chartdata,
      totalParticipants: totalParticipants,
      evalPlansCount: evalPlansCount,
      programsActivityCount,
      linkedActivityCount,
    };
  }

  configureChart = (dataset) => {
    if (dataset.data.datasets && dataset.data.datasets.length !== 0) {
      const { updateBase64Img } = this.props;
      const chartCanvas = ReactDOM.findDOMNode(this.chart);
      // const lineChart = new Chart(chartCanvas, dataset);
      // if (updateBase64Img) {
      //   updateBase64Img(lineChart.toBase64Image());
      // }
      new Chart(chartCanvas, dataset);
      if (updateBase64Img) {
        setTimeout(() => {
          const selfAssessedPostPreTrendChart = document.getElementById(
            `self-assessed-post-pre-trend-chart`
          );
          if (selfAssessedPostPreTrendChart) {
            html2canvas(selfAssessedPostPreTrendChart).then((canvas) => {
              updateBase64Img(canvas.toDataURL());
            });
          }
        }, 1000);
      }
    }
  };

  render() {
    const {
      empty,
      totalParticipant,
      totalResponse,
      activityCount,
      evalPlansCount,
      actualParticipants,
      programsActivityCount,
      linkedActivityCount,
    } = this.state;
    if (empty) return <EmptyContainer msg="No data in that method." />;
    let { outcome, evalPlan, date } = this.props;

    //actualParticipant in documentation page is derived here.

    // DA DATA CREATION PLACE
    let da = {};
    let summary = {
      totalParticipant: totalParticipant,
      totalResponse: totalResponse,
      activityCount: activityCount,
      actualParticipants: actualParticipants,
      programsActivityCount: programsActivityCount,
      linkedActivityCount: linkedActivityCount,
    };

    da.summary = summary;

    return (
      <div id={`self-assessed-post-pre-trend-chart`}>
        <div>
          <CustomReportHeader
            outcome={outcome}
            evalPlan={evalPlan}
            da={da}
            date={date}
            allLegends={true}
            evalPlansCount={evalPlansCount}
            isSelfAssessedMethod={true}
            {...this.props}
          />
          <div className="report-border-container">
            {' '}
            <canvas
              ref={(chart) => {
                this.chart = chart;
              }}
            />
            <div className="bottom-info-container">
              <label>1 = Not at all</label>
              <label>10 = Most imagined</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class OutcomesSummary extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      currentTabPre: 0,
      reportOptions: [],
      optionsError: false,
    };
  }

  getOrganizationSectorType() {
    try {
      const { organization, industrySectorTypes } = this.props;
      const type = find(industrySectorTypes, (o) => {
        return o.id == organization.industrySectorTypeId;
      });
      if (isEmpty(type)) {
        return '-';
      } else {
        return type.name;
      }
    } catch (error) {
      return '-';
    }
  }

  componentDidMount() {
    let { type, setChartType } = this.props;
    const chartType = getChartType({
      title: type,
      ...this.props,
    });
    setChartType(chartType);
  }

  componentDidUpdate(prevProps, prevState) {
    const { type, isEnabledIncludeReport, setChartType } = this.props;

    if (prevProps.type !== type && isEnabledIncludeReport) {
      this.getIncludeReportData();
    }
    const chartType = getChartType({
      title: type,
      ...this.props,
    });
    setChartType(chartType);
  }

  getIncludeReportData() {
    const { programIncludedReports } = this.props;
    const outcomeId = this.getOutcomeId();

    let outcomeData = programIncludedReports.filter(
      (item) => item.outcomeId === outcomeId
    );
    let filteredData = outcomeData.map((item) => item.type);

    let uniqueData = map(
      uniq(
        map(filteredData, function (obj) {
          return JSON.stringify(obj);
        })
      ),
      function (obj) {
        return JSON.parse(obj);
      }
    );
    this.setState({ reportOptions: uniqueData });
  }

  postMethods(isSelfAssessed) {
    return compact([
      !isSelfAssessed && {
        menuItem: 'Aggregate',
        render: () => (
          <Tab.Pane>
            <PostSurveyChart {...this.props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Separated',
        render: () => (
          <Tab.Pane>
            {isSelfAssessed ? (
              <SelfAssessedPostTrendChart method="9" {...this.props} />
            ) : (
              <PostTrendSurveyChart {...this.props} />
            )}
          </Tab.Pane>
        ),
      },
    ]);
  }

  postPreMethods(isSelfAssessed) {
    return compact([
      !isSelfAssessed && {
        menuItem: 'Trend - Rate of change',
        render: () => (
          <Tab.Pane>
            <PostPreTrendSurvey {...this.props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: isSelfAssessed
          ? 'Self-Assessed - Rate of Change'
          : 'Individual - Rate of change',
        render: () => (
          <Tab.Pane>
            {isSelfAssessed ? (
              <SelfAssessedPostPreTrend grantAct={true} {...this.props} />
            ) : (
              <PostPreSurvey {...this.props} />
            )}
          </Tab.Pane>
        ),
      },
    ]);
  }

  onTabClick(data, currentTab) {
    this.setState({
      [currentTab === 'currentTab' ? 'currentTabPre' : 'currentTab']: 0,
      [currentTab]: data.activeIndex,
    });
  }

  checkIsSelfAssessedData(outcomeName, chartData) {
    // find outcome data object
    const filteredOutcomeData = find(
      chartData,
      (item) => item.name === outcomeName
    );
    const methodData = get(filteredOutcomeData, 'byMethod', []);

    // check whether contain self assessed data
    const selfAssessedData = find(methodData, (item) => item.methodId === 9);

    return !isEmpty(selfAssessedData);
  }

  checkIsInterceptData(outcomeName, chartData) {
    // find outcome data object
    const filteredOutcomeData = find(
      chartData,
      (item) => item.name === outcomeName
    );
    const methodData = get(filteredOutcomeData, 'byMethod', []);

    // check whether contain self assessed data
    const interceptData = find(methodData, (item) => item.methodId === 12);

    return !isEmpty(interceptData);
  }

  getOptions() {
    const {
      currentActivity,
      chartType,
      type,
      participants,
      participantsChangePrePost,
    } = this.props;

    let options = [];

    if (chartType) {
      if (chartType === 'postChart') {
        options.push(
          { key: 1, value: 'AGGREGATE', text: 'Aggregate' },
          { key: 2, value: 'SEPERATE', text: 'Separated' }
        );
      } else {
        options.push(
          { key: 1, value: 'TREND', text: 'Trend - Rate of change' },
          {
            key: 2,
            value: 'INDIVIDUAL',
            text: 'Individual - Rate of change',
          }
        );
      }

      const participantsResponses =
        chartType === 'postChart' ? participants : participantsChangePrePost;

      // check whether this outcome have any self-assessed data
      if (this.checkIsSelfAssessedData(type, participantsResponses)) {
        options.push({
          key: 3,
          value:
            chartType === 'postChart'
              ? 'SELF-ASSESSED-POST'
              : 'SELF-ASSESSED-PRE',
          text: 'Self assessed',
        });
      }

      // check whether this outcome have any CC Intercept data
      if (
        currentActivity.isCultureCount &&
        this.checkIsInterceptData(type, participantsResponses)
      ) {
        options.push(
          {
            key: 4,
            value: 'INTERCEPT_AGGREGATE',
            text: 'Aggregate - CC Intercept',
          },
          {
            key: 5,
            value: 'INTERCEPT_SINGLE',
            text: 'Separated - CC Intercept',
          }
        );
      }
    }

    return options;
  }

  async addAllIncludedReports(currentActivity, outcomeId, data, isDeleted) {
    const { addMultipleProjectOutcomeCharts } = this.props;
    addMultipleProjectOutcomeCharts(currentActivity.id, outcomeId, data);
  }

  // return outcome id for given outcomes
  getOutcomeId() {
    const { outcomes, type } = this.props;
    const id = outcomes.filter((outcome) => {
      return outcome.title === type;
    })[0].id;
    return id;
  }

  async onChangeOptions(event, data) {
    const { currentActivity } = this.props;
    const outcomeId = this.getOutcomeId();
    const dataValue = data.value;
    this.setState({ reportOptions: dataValue });
    await this.addAllIncludedReports(currentActivity, outcomeId, data.value);
  }

  isEvaluationsAvailable() {
    const { participants, participantsChangePrePost, type, chartType, method } =
      this.props;
    let participantsData;

    if (chartType === 'postChart') {
      participantsData = participants;
    } else if (chartType === 'prePostChart') {
      participantsData = participantsChangePrePost;
    }

    if (participantsData) {
      let participantData;
      if (type) {
        participantData = participantsData.find((f) => f.name === type);
      }

      if (type && method !== 'all') {
        const byMethod = get(participantData, 'byMethod', []);
        participantData = byMethod.find((f) => f.methodId === Number(method));
      }

      const evalCount = participantData ? participantData.evalCount : 0;
      if (evalCount > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  referenceGroup = (props) => {
    const { item, rest } = props;
    return (
      <div
        className={
          rest.isArchived
            ? 'reference-mainText-muted'
            : 'reference-mainText item'
        }
      >
        {' '}
        {!isEmpty(item.crossReferenceLabel) ? item.crossReferenceLabel : '-'}
      </div>
    );
  };

  render() {
    const {
      chartType,
      method,
      isEnabledIncludeReport,
      activityType,
      date,
      outcome,
      chartTypeLabel,
      singleReport,
      isDocumentationLegends = false,
      outcomeSummaryGoalTitle,
      outcomeSummaryReferences,
      dateRangeChosenLabel,
    } = this.props;
    const { currentTab, currentTabPre, optionsError, reportOptions } =
      this.state;

    const isIntercept = method === '12' ? true : false;
    const isSelfAssessed = method === '9' ? true : false;
    const options = isEnabledIncludeReport ? this.getOptions() : [];
    const _isProgram = isProgramActivity(activityType);
    const programTitle = getProgramActivityTitle(activityType);
    const isEvaluationsAvailable = this.isEvaluationsAvailable();
    let isArchived = false;

    return (
      <>
        <OrganizationActivitySummary
          dateRangeChosenLabel={dateRangeChosenLabel}
        />
        <br />
        <Grid className="outcomes-summary-grid" padded>
          {chartType === 'postChart' ? (
            <>
              <CustomSegment title="Outcomes Reported1">
                <Grid.Row className="outcomes-chart-row">
                  <div className="new-label-re">
                    {_isProgram && (
                      <>
                        <br />
                        <p className="program-activity-subheading">
                          {`Results below are generated from  activities funded by ${programTitle}`}
                        </p>
                      </>
                    )}
                  </div>

                  {isEnabledIncludeReport ? (
                    <div className="include-container">
                      <label>Include Graph in Report</label>
                      <Dropdown
                        placeholder="Options"
                        fluid
                        multiple
                        search
                        selection
                        options={options}
                        value={reportOptions}
                        onChange={(event, data) =>
                          this.onChangeOptions(event, data)
                        }
                        error={optionsError}
                        className="includeGraph-dropdown"
                      />
                      {optionsError ? (
                        <div className="invalid-dropdown-input">
                          Select options are required
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="outcomes-summary-chart-div">
                    <div className="legends-bottom-margin report-new">
                      <div className="report-ref-info">
                        {date ? <div>Date Period: {date}</div> : null}
                      </div>

                      <Grid className="report-ref-info">
                        <Grid.Row>
                          <Grid.Column mobile={16} tablet={8} computer={4}>
                            <div className="diff">
                              Outcome Measure : <b>{outcome}</b>
                            </div>
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={8} computer={8}>
                            {' '}
                            <div className="diff">
                              Method : <b>{checkMethodName(method)}</b>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>

                      {singleReport && (
                        <div className="diff">
                          Type : <b>{chartTypeLabel}</b>
                        </div>
                      )}
                      {!isEnabledIncludeReport && (
                        <div className="report-ref-info">
                          Goal : <b>{outcomeSummaryGoalTitle}</b>
                        </div>
                      )}
                    </div>

                    {outcomeSummaryGoalTitle !== 'All' &&
                      outcomeSummaryReferences &&
                      outcomeSummaryReferences.length > 0 && (
                        <div className="d-flex">
                          Reference :
                          <div className="report-ref-see-more">
                            {outcomeSummaryReferences.length < 4 ? (
                              <div>
                                {map(outcomeSummaryReferences, (g, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="reference-mainText item"
                                    >
                                      {g.crossReferenceLabel}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <SeeMore
                                minCount={3}
                                dataSet={outcomeSummaryReferences}
                                CustomComponent={this.referenceGroup}
                                isView={true}
                                rest={{ isArchived }}
                              />
                            )}
                          </div>
                        </div>
                      )}

                    {isDocumentationLegends ? null : isSelfAssessed ? (
                      isEvaluationsAvailable ? (
                        <CustomNotification
                          messageBanner={
                            'Self-assessed evaluation is provided by a single person, usually the activity manager. The assessment is distributed by a standard deviation to spread the single rating into the bell curve.'
                          }
                        />
                      ) : null
                    ) : isIntercept ? null : (
                      <CustomNotification
                        messageBanner={
                          'Evaluations using self assessment and intercept methods cannot be aggregated with other methods. To view self assessed or intercept results, select from the method field above.'
                        }
                      />
                    )}
                    <Tab
                      className="tabs-graph"
                      menu={{ secondary: true, pointing: true }}
                      panes={this.postMethods(isSelfAssessed)}
                      activeIndex={isSelfAssessed ? 0 : currentTab}
                      onTabChange={(e, data) =>
                        this.onTabClick(data, 'currentTab')
                      }
                    />
                  </div>
                </Grid.Row>
              </CustomSegment>
            </>
          ) : null}
          {chartType === 'prePostChart' ? (
            <>
              <Grid.Row className="outcomes-chart-row">
                <div className="new-label-re">
                  Post-Pre Survey Evaluation
                  {_isProgram && (
                    <>
                      <br />
                      <p className="program-activity-subheading">
                        {`Results below are generated from  activities funded by ${programTitle}`}
                      </p>
                    </>
                  )}
                </div>
                {isEnabledIncludeReport ? (
                  <div className="include-container">
                    <label>Include Graph in Report</label>
                    <Dropdown
                      fluid
                      multiple
                      search
                      selection
                      options={options}
                      value={reportOptions}
                      onChange={(event, data) =>
                        this.onChangeOptions(event, data)
                      }
                      error={optionsError}
                      className="includeGraph-dropdown"
                    />
                    {optionsError ? (
                      <div className="invalid-dropdown-input">
                        Select options are required
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <></>
                )}
              </Grid.Row>
              <div className="pre-post-chart-div">
                <div className="outcomes-summary-chart-div">
                  <div className="legends-bottom-margin report-new">
                    <div className="report-ref-info">
                      {date ? <div>Date Period: {date}</div> : null}
                    </div>

                    <Grid className="report-ref-info">
                      <Grid.Row>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                          <div className="diff">
                            Outcome Measure : <b>{outcome}</b>
                          </div>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          {' '}
                          <div className="diff">
                            Method : <b>{checkMethodName(method)}</b>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    {singleReport && (
                      <div className="diff">
                        Type : <b>{chartTypeLabel}</b>
                      </div>
                    )}

                    <div className="report-ref-info">
                      Goal : <b>{outcomeSummaryGoalTitle}</b>{' '}
                    </div>
                  </div>
                </div>

                {outcomeSummaryGoalTitle !== 'All' &&
                  outcomeSummaryReferences &&
                  outcomeSummaryReferences.length > 0 && (
                    <div className="d-flex">
                      Reference :
                      <div className="report-ref-see-more">
                        {outcomeSummaryReferences.length < 4 ? (
                          <div>
                            {map(outcomeSummaryReferences, (g, i) => {
                              return (
                                <div
                                  key={i}
                                  className="reference-mainText item"
                                >
                                  {g.crossReferenceLabel}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <SeeMore
                            minCount={3}
                            dataSet={outcomeSummaryReferences}
                            CustomComponent={this.referenceGroup}
                            isView={true}
                            rest={{ isArchived }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                {isDocumentationLegends ? null : isSelfAssessed ? (
                  isEvaluationsAvailable ? (
                    <CustomNotification
                      messageBanner={
                        'Self-assessed evaluation is provided by a single person, usually the activity manager. The assessment is distributed by a standard deviation to spread the single rating into the bell curve.'
                      }
                    />
                  ) : null
                ) : isIntercept ? null : (
                  <CustomNotification
                    messageBanner={
                      'Evaluations using self assessment and intercept methods cannot be aggregated with other methods. To view self assessed or intercept results, select from the method field above.'
                    }
                  />
                )}
                <Tab
                  className="tabs-graph"
                  menu={{ secondary: true, pointing: true }}
                  onTabChange={(e, data) =>
                    this.onTabClick(data, 'currentTabPre')
                  }
                  activeIndex={isSelfAssessed ? 0 : currentTabPre}
                  panes={
                    isIntercept
                      ? this.postMethods()
                      : this.postPreMethods(isSelfAssessed)
                  }
                />
              </div>
            </>
          ) : null}

          {chartType === undefined ? (
            <div className="no-data-div">Please select an outcome type.</div>
          ) : null}
          {chartType === 'noData' ? (
            <div className="no-data-div">
              No data in that date range or activity type or selected outcome.
            </div>
          ) : null}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    participantsChange: state.report.participantsChange,
    participantsChangePrePost: state.report.participantsChangePrePost,
    participants: state.report.participants,
    organization: state.organization.organization,
    industrySectorTypes: state.extra.industrySectorTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateOutcomeChart,
      addMultipleProjectOutcomeCharts,
    },
    dispatch
  );
};

OutcomesSummary = connect(mapStateToProps, mapDispatchToProps)(OutcomesSummary);

export {
  OutcomesSummary,
  PostSurveyChart,
  PostPreTrendSurvey,
  PostTrendSurveyChart,
  SelfAssessedPostPreTrend,
  SelfAssessedPostTrendChart,
};

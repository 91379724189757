/* eslint-disable react-hooks/exhaustive-deps */
import { stopPropagation } from '../../../../../../utils';
import { Modal, Button } from 'semantic-ui-react';

export const DeactivateExternalLinked = (props) => {
  const {
    confirmDeactivateModel: modal,
    toggleConfirmDeactivateModel: toggle,
    setConfirmedDeactivation: deactivate,
  } = props;

  const confirmDeactivate = () => {
    deactivate(true);
    toggle();
  };

  return (
    <Modal
      open={modal}
      onOpen={() => toggle()}
      onClick={(e) => stopPropagation(e)}
      onFocus={(e) => stopPropagation(e)}
      closeIcon={
        <div className="new-activity-modal-close-icon" onClick={() => toggle()}>
          &times;
        </div>
      }
      size="tiny"
    >
      <Modal.Header>
        <div className="small-modal-header">
          Deactivate External Linked Activity
        </div>
      </Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to deactivate this linked activity which is
          owned by external Organisation?
        </p>
        <div className="no-feature-warning-box w-100">
          <div className="padding-issue ">
            This will be removed from the program linked activity list and
            transfer to the owned activity list as a root level activity (Not a
            linked activity)
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => toggle()}
          className="activity-activation-cancel-btn"
        >
          No
        </Button>
        <Button
          onClick={() => confirmDeactivate()}
          className="new-collective-save-btn"
          content="Yes"
        />
      </Modal.Actions>
    </Modal>
  );
};

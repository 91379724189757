import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import './styles.scss';
function DateRangeChart(props) {
  const {
    startDate,
    endDate,
    datePicker,
    preDefinedDateRanges = false,
  } = props;
  let { minDate = '2018-01-01' } = props;

  minDate = moment(minDate);
  const minYear = moment(minDate).year();

  const getCalendarYearTimestamps = () => {
    let startMonthName = 'January',
      endMonthName = 'December';
    return {
      current: {
        start: moment().month(startMonthName).startOf('month'),
        end: moment().month(endMonthName).endOf('month'),
      },
      last: {
        start: moment()
          .subtract(1, 'year')
          .month(startMonthName)
          .startOf('month'),
        end: moment().subtract(1, 'year').month(endMonthName).endOf('month'),
      },
    };
  };
  const getFinancialYearTimestamps = () => {
    let startMonthName = 'July',
      endMonthName = 'June';

    const currentMonth = new Date().getMonth();
    return {
      current: {
        start:
          currentMonth > 6
            ? moment().month(startMonthName).startOf('month')
            : moment()
                .subtract(1, 'year')
                .month(startMonthName)
                .startOf('month'),
        end:
          currentMonth > 6
            ? moment().add(1, 'year').month(endMonthName).endOf('month')
            : moment().month(endMonthName).endOf('month'),
      },
      last: {
        start:
          currentMonth > 6
            ? moment()
                .subtract(1, 'year')
                .month(startMonthName)
                .startOf('month')
            : moment()
                .subtract(2, 'year')
                .month(startMonthName)
                .startOf('month'),
        end:
          currentMonth > 6
            ? moment().month(endMonthName).endOf('month')
            : moment().subtract(1, 'year').month(endMonthName).endOf('month'),
      },
    };
  };

  const maxYear = parseInt(moment().format('YYYY'), 10) + 5;

  const settings = {
    locale: { format: 'DD-MMM-YYYY' },
    showDropdowns: true,
    minYear: minYear,
    maxYear: maxYear,
  };
  let exist = {};

  if (startDate && endDate) {
    exist.value = `${moment(startDate).format('DD MMM YYYY')} - ${moment(
      endDate
    ).format('DD MMM YYYY')}`;

    settings.startDate = startDate;
    settings.endDate = endDate;
  }

  if (preDefinedDateRanges) {
    settings.ranges = {
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 28 Days': [moment().subtract(27, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
      'This Quarter': [moment().startOf('quarter'), moment()],
      'Last Quarter': [
        moment().startOf('quarter').subtract(3, 'month'),
        moment().startOf('quarter'),
      ],
      'Year to Date': [moment().startOf('year'), moment()],
      'This financial year': [
        getFinancialYearTimestamps().current.start,
        getFinancialYearTimestamps().current.end,
      ],
      'Last financial year': [
        getFinancialYearTimestamps().last.start,
        getFinancialYearTimestamps().last.end,
      ],
      'This calendar year': [
        getCalendarYearTimestamps().current.start,
        getCalendarYearTimestamps().current.end,
      ],
      'Last calendar year': [
        getCalendarYearTimestamps().last.start,
        getCalendarYearTimestamps().last.end,
      ],

      'Since the Start': [minDate, moment()],
    };
  }
  return (
    <DateRangePicker initialSettings={settings} onApply={datePicker}>
      <div className="date-range-chart p-1">
        <input
          {...exist}
          placeholder="DD MM YYYY - DD MM YYYY"
          type="text"
          readOnly
          className="date-range-chart-date"
        />
        <Icon className="calendar" name="calendar alternate outline" />
      </div>
    </DateRangePicker>
  );
}

export default DateRangeChart;

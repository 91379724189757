import * as Yup from 'yup';
import { get } from 'lodash';
import { EMAIL_REG_EX, PHONE_REG_EX } from '../utils/regex';

import {
  isGrantLinkedActivity,
  isGrantProgramActivity,
  isParentProgramActivity,
} from '../utils/activity';
import {
  PARTNER_RELATIONSHIP,
  PARTNER_RELATIONSHIP_VALUES,
  CREATE_LINKED_MANAGED_BY_OPTIONS,
} from '../utils/constant';
const grantIdPattern = /^[a-zA-Z0-9]*$/;

export const getLinkedActivitiesFormData = (
  activity,
  projected,
  activityPlanTypeId,
  parentActivityPlanTypeId,

  currentUserEmail = null
) => {
  // GRANT PROGRAM, FESTIVAL PROGRAM, MULTI PROGRAM
  const isProgramActivity = isParentProgramActivity(activityPlanTypeId);

  if (activity) {
    let formData = {
      primaryObjective: projected
        ? isProgramActivity
          ? activity.primaryParentOutcomes || []
          : activity.primaryOutcomeId || ''
        : isProgramActivity
          ? activity.primaryParentOutcomes || []
          : get(activity, 'objective[0].outcome.id', ''),
      secondaryObjective: projected
        ? isProgramActivity
          ? activity.secondaryParentOutcomes || []
          : activity.secondaryOutcomeId || ''
        : isProgramActivity
          ? activity.secondaryParentOutcomes || []
          : get(activity, 'objective[1].outcome.id', ''),
      primaryObjectiveText: isProgramActivity
        ? activity.primaryParentObjectives || []
        : [],
      secondaryObjectiveText: isProgramActivity
        ? activity.secondaryParentObjectives || []
        : [],
      name: activity.name || '',
      activityNotes: activity.activityNotes,
      firstName: activity.firstName || '',
      grantId: activity.grantId || '',
      surname: get(activity, 'owner.surname', ''),
      email: get(activity, 'owner.email', ''),
      phone: get(activity, 'owner.phone', ''),
      entityName: get(activity, 'owner.organisation.name', ''),
      country: get(activity, 'country', ''),
      address1: get(activity, 'owner.address1', ''),
      address2: get(activity, 'owner.address2', ''),
      suburb: get(activity, 'owner.suburb', ''),
      state: get(activity, 'owner.state', ''),
      postcode: get(activity, 'owner.postcode', ''),
      projectedGrantAmount: get(activity, 'projectedGrantAmount', ''),
      description: get(activity, 'description', ''),
      organisationId: get(activity, 'owner.organisationId', null),
      isExistingUser: get(activity, 'owner.id', null), // if owner id  is not null -> existing user
      relationship: get(
        activity,
        'relationship',
        PARTNER_RELATIONSHIP[0].value
      ),
      managedBy: CREATE_LINKED_MANAGED_BY_OPTIONS[0].value,
      emailConfirmError: activity ? 'confirmedUser' : '',
    };

    // GRANT PROGRAM
    if (isGrantProgramActivity(activityPlanTypeId)) {
      formData.projectedTotalGrantPool =
        parseFloat(activity.projectedTotalGrantPool).toFixed(2) || 0.0;
    } else {
      // Projected Program Contribution
      formData.projectedGrantAmount =
        parseFloat(activity.projectedGrantAmount).toFixed(2) || 0.0;
    }

    //GRANT LINKED
    if (isGrantLinkedActivity(parentActivityPlanTypeId)) {
      formData.grantId = activity.grantId || 0;
    }

    return formData;
  } else {
    let formData = {
      primaryObjective: '',
      secondaryObjective: '',
      name: '',
      activityNotes: '',
      firstName: '',
      surname: '',
      email: currentUserEmail,
      phone: '',
      entityName: '',
      country: '',
      address1: '',
      grantId: '',
      address2: '',
      suburb: '',
      state: '',
      postcode: '',
      projectedGrantAmount: '',
      description: '',
      organisationId: null,
      isExistingUser: false,
      relationship: PARTNER_RELATIONSHIP[0].value,
      emailConfirmError: '',
      managedBy: CREATE_LINKED_MANAGED_BY_OPTIONS[0].value,
    };

    if (isGrantProgramActivity(activityPlanTypeId)) {
      formData.projectedTotalGrantPool = '';
    } else {
      // Projected Program Contribution
      formData.projectedGrantAmount = '';
    }

    if (isGrantLinkedActivity(parentActivityPlanTypeId)) {
      formData.grantId = '';
    }

    return formData;
  }
};

export const linkedActivitiesValidationSchema = (
  isSecondaryPolicy = false,
  activityPlanTypeId,
  parentActivityPlanTypeId,
  isPrimaryCultural = false,
  orgUsersEmails
) =>
  Yup.lazy((values) => {
    let validationSchema = {
      name: Yup.string().required('Activity Name is required!'),
      activityNotes: Yup.string().optional(),
      emailConfirmError: Yup.string().optional(),
    };

    if (
      get(values, 'managedBy', '') === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value
    ) {
      validationSchema = {
        ...validationSchema,
        firstName: Yup.string().required('First Name is required!'),
        surname: Yup.string().required('Last Name is required!'),
        email: Yup.string()
          .required('Activity manager is required!')
          .matches(EMAIL_REG_EX, 'Email is not valid!'),
        phone: Yup.string()
          .required('Phone number is required!')
          .matches(PHONE_REG_EX, 'Phone number is not valid!')
          .min(10, 'Must be a minimum of 10 digits!'),
        managedBy: Yup.string()
          .oneOf(CREATE_LINKED_MANAGED_BY_OPTIONS.map((item) => item.value))
          .required(),
      };
    }

    if (values.managedBy === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value) {
      validationSchema = {
        ...validationSchema,
        email: Yup.string()
          .notOneOf(
            orgUsersEmails,
            'Unable to invite organisation user as external!'
          )
          .required('Activity manager is required!')
          .matches(EMAIL_REG_EX, 'Email is not valid!'),
      };
    }

    const isProgramActivity = isParentProgramActivity(activityPlanTypeId);

    if (isProgramActivity) {
      validationSchema.primaryObjective = Yup.array().required(
        'Primary Objective is required!'
      );
      validationSchema.secondaryObjective =
        isSecondaryPolicy && !isPrimaryCultural
          ? Yup.array().required('Secondary Objective is required!')
          : Yup.array().optional();
    } else {
      validationSchema.primaryObjective = Yup.string().required(
        'Primary Objective is required!'
      );
      validationSchema.secondaryObjective =
        isSecondaryPolicy && !isPrimaryCultural
          ? Yup.string().required('Secondary Objective is required!')
          : Yup.string().optional();
    }

    if (values.relationship !== PARTNER_RELATIONSHIP_VALUES.NETWORK) {
      validationSchema.projectedGrantAmount = Yup.string().required(
        'Program Contribution is required!'
      );
    }

    //GRANT LINKED
    if (isGrantLinkedActivity(parentActivityPlanTypeId)) {
      validationSchema.grantId = Yup.string().optional();
    }

    return Yup.object().shape(validationSchema);
  });

export const linkedActivitiesGuestUserValidation = (
  isSecondaryPolicy = false,
  activityPlanTypeId,
  parentActivityPlanTypeId,
  isPrimaryCultural = false,
  orgUsersEmails = []
) =>
  Yup.lazy((values) => {
    let validationSchema = {
      name: Yup.string().required('Activity Name is required!'),
      email: Yup.string()
        .required('Activity manager is required!')
        .matches(EMAIL_REG_EX, 'Email is not valid!'),
      relationship: Yup.string()
        .oneOf(['IN_KIND', 'FUNDING', 'NETWORK'])
        .required(),
      activityNotes: Yup.string().optional(),
      address2: Yup.string().optional().nullable(),
      description: Yup.string().optional(),
      emailConfirmError: !values.isConfirmedUser
        ? Yup.string().required('Confirmation is required!')
        : Yup.string().optional(),
      managedBy: Yup.string()
        .oneOf(CREATE_LINKED_MANAGED_BY_OPTIONS.map((item) => item.value))
        .required(),
    };

    if (values.managedBy === CREATE_LINKED_MANAGED_BY_OPTIONS[2].value) {
      validationSchema = {
        ...validationSchema,
        email: Yup.string()
          .notOneOf(
            orgUsersEmails,
            'Unable to invite organisation user as external!'
          )
          .required('Activity manager is required!')
          .matches(EMAIL_REG_EX, 'Email is not valid!'),
      };
    }

    if (values.isExistingUser) {
      validationSchema = {
        ...validationSchema,
        firstName: Yup.string().optional().nullable(),
        surname: Yup.string().optional().nullable(),

        phone: Yup.string()
          .optional()
          .matches(PHONE_REG_EX, 'Phone number is not valid!')
          .min(10, 'Must be a minimum of 10 digits!')
          .nullable(),
        entityName: Yup.string().optional().nullable(),
        country: Yup.string().optional().nullable(),
        address1: Yup.string().optional().nullable(),
        suburb: Yup.string().optional().nullable(),
        state: Yup.string().optional().nullable(),
        postcode: Yup.string().optional().nullable(),
      };
    } else {
      validationSchema = {
        ...validationSchema,
        firstName: Yup.string().required('First Name is required!'),
        surname: Yup.string().required('Last Name is required!'),
        phone: Yup.string()
          .required('Phone number is required!')
          .matches(PHONE_REG_EX, 'Phone number is not valid!')
          .min(10, 'Must be a minimum of 10 digits!'),

        entityName: Yup.string().required(
          'Business/ organisation name is required!'
        ),
        country: Yup.string().required('Country is required!'),
        address1: Yup.string().required('Address Line 1 is required!'),
        suburb: Yup.string().required('Suburb is required!'),
        state: Yup.string().required('State is required!'),
        postcode: Yup.string().required('Postcode is required!'),
      };
    }

    const isProgramActivity = isParentProgramActivity(activityPlanTypeId);

    if (isProgramActivity) {
      validationSchema.primaryObjective = Yup.array().required(
        'Primary Objective is required!'
      );

      validationSchema.secondaryObjective =
        isSecondaryPolicy && !isPrimaryCultural
          ? Yup.array().required('Secondary Objective is required!')
          : Yup.array().optional();
    } else {
      validationSchema.primaryObjective = Yup.string().required(
        'Primary Objective is required!'
      );

      validationSchema.secondaryObjective =
        isSecondaryPolicy && !isPrimaryCultural
          ? Yup.string().required('Secondary Objective is required!')
          : Yup.string().optional();
    }

    // GRANT PROGRAM
    // if (isGrantProgramActivity(activityPlanTypeId)) {
    //   validationSchema.projectedTotalGrantPool = Yup.string().required(
    //     'Projected Total Grant Pool is required!'
    //   );
    // } else {
    if (values.relationship !== PARTNER_RELATIONSHIP_VALUES.NETWORK) {
      validationSchema.projectedGrantAmount = Yup.string().required(
        'Program Contribution is required!'
      );
    }
    // }

    //GRANT LINKED
    if (isGrantLinkedActivity(parentActivityPlanTypeId)) {
      validationSchema.grantId = Yup.string()
        .required('Grant ID is required!')
        .matches(grantIdPattern, 'Grant ID is not valid!');

      // need to validate as required organisation address details in guest user
    } else {
      // single linked && Not single grant linked || program activities
    }

    return Yup.object().shape(validationSchema);
  });

export const linkedActivitiesCreateErrorObject = (
  errors,
  guestUser,
  activityPlanTypeId,
  parentActivityPlanTypeId,
  orgUsersEmails = []
) => {
  let formErrors = {};

  formErrors.primaryObjective = errors.primaryObjective
    ? 'primaryObjective'
    : null;
  formErrors.secondaryObjective = errors.secondaryObjective
    ? 'secondaryObjective'
    : null;
  formErrors.name = errors.name ? 'name' : null;
  formErrors.activityNotes = errors.activityNotes ? 'activityNotes' : null;
  if (guestUser) {
    formErrors.surname = errors.surname ? 'firstName' : null;
    formErrors.email = errors.email ? 'email' : null;
    formErrors.phone = errors.phone ? 'phone' : null;
    formErrors.entityName = errors.entityName ? 'entityName' : null;
    formErrors.country = errors.country ? 'country' : null;
    formErrors.address1 = errors.address1 ? 'address1' : null;
    formErrors.address2 = errors.address2 ? 'address2' : null;
    formErrors.suburb = errors.suburb ? 'suburb' : null;
    formErrors.state = errors.entityName ? 'state' : null;
    formErrors.postcode = errors.entityName ? 'postcode' : null;
    formErrors.relationship = errors.relationship ? 'relationship' : null;
    formErrors.managedBy = errors.managedBy ? 'managedBy' : null;
  }

  // GRANT PROGRAM
  if (isGrantProgramActivity(activityPlanTypeId)) {
    formErrors.projectedTotalGrantPool = errors.projectedTotalGrantPool
      ? 'projectedTotalGrantPool'
      : null;
  } else {
    // Projected Program Contribution
    formErrors.projectedGrantAmount = errors.projectedGrantAmount
      ? 'projectedGrantAmount'
      : null;
  }

  //GRANT LINKED
  if (isGrantLinkedActivity(parentActivityPlanTypeId)) {
    formErrors.grantId = errors.grantId ? 'grantId' : null;
  }

  return formErrors;
};

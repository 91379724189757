import React, { Component, Fragment } from 'react';
import thousands from 'thousands';
import { Grid, Message } from 'semantic-ui-react';

export const PostLegends = ({ average = false }) => (
  <div className="legend-labels extra-margin">
    {average ? (
      <Fragment>
        <div className="average-box"></div>
        <div className="text-change">Average</div>
      </Fragment>
    ) : null}
    <div className="response-box"></div>
    <div className="text-change">Response</div>
  </div>
);

export const PostPreLegends = () => (
  <div className="legend-labels post-pre-legend-custom">
    <div className="before-response"></div>
    <div className="text-change"> Before Response</div>
    <div className="after-response"></div>
    <div className="text-change">After Response</div>

    <div className="before-box"></div>
    <div className="text-change">Before Average</div>

    <div className="after-box"></div>
    <div className="text-change">After Average</div>
  </div>
);

export const SelfAssessedPostPreLegends = () => (
  <div className="self-assessed-legends-labels extra-margin">
    <div className="before-response"></div>
    <div className="text-change"> Before Rating</div>
    <div className="after-response"></div>
    <div className="text-change">After Rating</div>
  </div>
);

export const SelfAssessedPostLegends = ({ average = false }) => (
  <div className="self-assessed-legends-labels">
    {average ? (
      <Fragment>
        <div className="after-box"></div>
        <div className="text-change">Average</div>
      </Fragment>
    ) : null}
    <div className="after-response"></div>
    <div className="text-change">Responses</div>
  </div>
);

export const SelfAssessedLegend = ({
  da,
  evalPlansCount,
  documentationSelfAssessed,
  grantAct,
  isProgramActivity,
}) => (
  <Grid>
    <Grid.Row>
      {isProgramActivity && (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <div className="diff">
            Program Activity Count :
            {da.summary.programsActivityCount
              ? ` ${thousands(da.summary.programsActivityCount)}`
              : ` 0`}
          </div>
        </Grid.Column>
      )}
      {!documentationSelfAssessed && (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <div className="diff">
            {documentationSelfAssessed
              ? null
              : isProgramActivity
                ? da.summary.linkedActivityCount
                  ? `Linked Activity Count : ${thousands(
                      da.summary.linkedActivityCount
                    )}`
                  : `Linked Activity Count : 0`
                : da.summary.activityCount
                  ? `Activity Count : ${thousands(da.summary.activityCount)}`
                  : `Activity Count :0`}
          </div>
        </Grid.Column>
      )}

      <Grid.Column mobile={16} tablet={8} computer={4}>
        <div className="diff">{`Evaluation Plan Count : ${evalPlansCount}`}</div>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={8} computer={4}>
        <div className="diff">
          {grantAct ? 'Total participants : ' : 'Total Activity participants: '}
          {''}
          {da.summary.actualParticipants
            ? thousands(parseInt(da.summary.actualParticipants))
            : 0}
        </div>
      </Grid.Column>

      <Grid.Column mobile={16} tablet={8} computer={4}>
        <div className="diff">
          Total Participants Assessed :{' '}
          {da.summary.totalResponse ? thousands(da.summary.totalResponse) : 0}
        </div>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export const Legends = ({
  da,
  evalPlan,
  documentation,
  isSeries,
  isProgramActivity,
}) => (
  <Grid>
    <Grid.Row className="report-activity-count">
      {isProgramActivity && (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <div className="diff">
            Program Activity Count :
            {da.summary.programsActivityCount
              ? ` ${thousands(da.summary.programsActivityCount)}`
              : ` 0`}
          </div>
        </Grid.Column>
      )}

      {evalPlan ? (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <div className="diff">
            {`Evaluation Plan Count : `}
            {da.summary.evalCount ? thousands(da.summary.evalCount) : 0}
          </div>
        </Grid.Column>
      ) : (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <div className="diff">
            {isProgramActivity
              ? da.summary.linkedActivityCount
                ? `Linked Activity Count : ${thousands(
                    da.summary.linkedActivityCount
                  )}`
                : `Linked Activity Count : 0`
              : da.summary.activityCount
                ? `Activity Count : ${thousands(da.summary.activityCount)}`
                : `Activity Count : 0`}
          </div>
        </Grid.Column>
      )}

      <Grid.Column mobile={16} tablet={8} computer={4}>
        <div className="diff">
          {documentation
            ? 'Total Activity Participants : '
            : 'Total Participants : '}
          {da.summary.totalParticipant
            ? thousands(parseInt(da.summary.totalParticipant))
            : 0}
        </div>
      </Grid.Column>

      <Grid.Column mobile={16} tablet={8} computer={4}>
        <div className="diff">
          {isSeries ? 'Total responses : ' : ' Total respondents : '}
          {da.summary.totalResponse ? thousands(da.summary.totalResponse) : 0}
        </div>
      </Grid.Column>

      {!isSeries && (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <div className="diff">
            Respondent Percentage :{' '}
            {da.summary.totalParticipant && da.summary.totalResponse
              ? thousands(
                  Math.round(
                    (da.summary.totalResponse / da.summary.totalParticipant) *
                      100
                  )
                )
              : 0}{' '}
            %
          </div>
        </Grid.Column>
      )}
    </Grid.Row>
  </Grid>
);

export const DocumentationPageIndividualLegends = ({ da, isSeries }) => (
  <div className="counts">
    <div className="diff">
      Participants :{' '}
      {da.summary.totalParticipant
        ? thousands(parseInt(da.summary.totalParticipant))
        : 0}
    </div>
    <div className="diff">
      Respondents :{' '}
      {da.summary.totalResponse ? thousands(da.summary.totalResponse) : 0}
    </div>
    {!isSeries && (
      <div className="diff">
        Respondent Percentage :{' '}
        {da.summary.totalParticipant && da.summary.totalResponse
          ? thousands(
              Math.round(
                (da.summary.totalResponse / da.summary.totalParticipant) * 100
              )
            )
          : 0}{' '}
        %
      </div>
    )}
  </div>
);

export const Intercept = ({ showInterceptLegend = true }) =>
  showInterceptLegend ? (
    <div className="legend-labels extra-margin">
      <div className="average-box-intercept"></div>
      <div className="text-change">Average</div>
      <div className="response-box-intercept"></div>
      <div className="text-change">Response</div>
    </div>
  ) : null;

export const CustomNotification = ({ messageBanner }) => (
  <div className="msg-report">
    <Message color="blue" icon="exclamation circle" content={messageBanner} />
  </div>
);

export class CustomReportHeader extends Component {
  render() {
    let {
      evalPlan,
      da,
      // legendResponse,
      allLegends,
      evalPlansCount,
      isIntercept,
      isSelfAssessedMethod = false,
      documentation,
      documentationSelfAssessed,
      grantAct,
      showInterceptLegend,
      isDocumentationLegends = false,
      isSeries = false,
      activityType,
    } = this.props;

    const programTypes = [
      'grant_program',
      'festival_program',
      'multi_activity_program',
    ];

    const isProgramActivity = programTypes.includes(activityType);

    return (
      <div>
        {/* {isDocumentationLegends ? null : isSelfAssessedMethod ? (
          da.summary.totalResponse >= 2 ? (
            <CustomNotification
              messageBanner={
                'Self-assessed evaluation is provided by a single person, usually the activity manager. The assessment is distributed by a standard deviation to spread the single rating into the bell curve.'
              }
            />
          ) : null
        ) : isIntercept ? null : (
          <CustomNotification
            messageBanner={
              'Evaluations using self assessment and intercept methods cannot be aggregated with other methods. To view self assessed or intercept results, select from the method field above.'
            }
          />
        )} */}

        <div className="report-new">
          {isDocumentationLegends ? (
            <DocumentationPageIndividualLegends da={da} isSeries={isSeries} />
          ) : isSelfAssessedMethod ? (
            <div>
              {isSelfAssessedMethod ? null : isIntercept ? (
                <Intercept showInterceptLegend={showInterceptLegend} />
              ) : (
                <>{allLegends ? <PostPreLegends /> : null}</>
              )}
              <SelfAssessedLegend
                da={da}
                evalPlansCount={evalPlansCount}
                documentationSelfAssessed={documentationSelfAssessed}
                grantAct={grantAct}
                isSeries={isSeries}
                isProgramActivity={isProgramActivity} // isProgramActivity only come from system report
              />
            </div>
          ) : (
            <div>
              {isSelfAssessedMethod || isIntercept ? null : (
                //:  isIntercept ? (
                //   <Intercept showInterceptLegend={showInterceptLegend} />
                // )
                <>{allLegends ? <PostPreLegends /> : null}</>
              )}
              <Legends
                da={da}
                evalPlan={evalPlan}
                documentation={documentation}
                isSeries={isSeries}
                isProgramActivity={isProgramActivity} // isProgramActivity only come from system report
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

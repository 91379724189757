import React, { useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { CustomInput } from './form';
import { findInvitedUser } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_REG_EX } from '../utils/regex';
import { cloneDeep, get, isEmpty } from '../utils/lodash';

// TODO: need to remove EmailResponsePanel from this component and remove hardcore relationship
export const EmailResponsePanel = ({
  data = {},
  clickConfirm,
  isPanelOpen,
}) => {
  if (isEmpty(data.firstName) || !isPanelOpen) return null;
  return (
    <div className="email-response-details">
      <Grid className="activity-summary-grid" padded>
        <Grid.Column>
          <p>The details entered match an existing Takso user.</p>
          <p>
            Name: <strong>{data.name}</strong>
          </p>
          <p>
            Business/Organisation: <strong>{data.organisationName}</strong>
          </p>
          <p className="confirm-btn" onClick={clickConfirm}>
            Confirm
          </p>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export const PartnerEmailVerificationForm = ({
  setFieldValue = null,
  onConfirmation,
  onVerification,
  isUserConfirmed = true, // this is used for checking user confirmation
  activityFeatureRequired = null,
  checkChangeAllow = false, // to check change ownership
  changeOwnershipPayload = {
    toOrganisationId: null,
    fromOrganisationId: null,
    parentActOwnerOrgId: null,
  },
}) => {
  const dispatch = useDispatch();
  const verifyLoading = useSelector(
    (state) => state.activity.findSystemUserLoading
  );
  // gest user email verification
  const [email, setEmail] = useState('');
  const [userResponse, setUserResponse] = useState({
    firstName: null,
    organisation: null,
    isConfirmed: false,
    isVerified: false,
    isCleared: false,
    isExistingUser: false,
  });
  const [isPanelOpen, setPanelOpen] = useState(false);

  const fetchData = async (email) => {
    return new Promise((resolve, reject) => {
      dispatch(findInvitedUser(email))
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          console.error('error: ', error);
          reject(error);
        });
    });
  };

  const handleVerifyEmail = async () => {
    try {
      if (email.match(EMAIL_REG_EX)) {
        const data = await fetchData(email);
        if (!isEmpty(data)) {
          data.name =
            get(data, 'firstName', '') + ' ' + get(data, 'surname', '');
          data.organisationName = get(data, 'organisation.name', '');
          data.userFeatures = get(data, 'userFeatures', []);
        }
        setUserResponse({
          ...data,
          isConfirmed: true,
          isVerified: true,
          isCleared: false,
          isExistingUser: !isEmpty(data) ? true : false,
        });
        onVerification({
          ...data,
          isVerified: true,
          isExistingUser: !isEmpty(data) ? true : false,
        });
        setPanelOpen(true); // Open the panel after successful verification
      }
    } catch (error) {
      console.error('error: ', error);
    }
  };

  // const shouldDisplayEmailResponsePanel = () => {
  //   const {
  //     fromOrganisationId,
  //     toOrganisationId,
  //     parentActOwnerOrgId,
  //   } = changeOwnershipPayload;
  //   return (
  //     toOrganisationId != parentActOwnerOrgId &&
  //     fromOrganisationId != parentActOwnerOrgId &&
  //     checkChangeAllow
  //   );
  // };
  const { fromOrganisationId, toOrganisationId, parentActOwnerOrgId } =
    changeOwnershipPayload;
  const showChangeAllow =
    !checkChangeAllow ||
    (checkChangeAllow &&
      (fromOrganisationId === toOrganisationId ||
        toOrganisationId === parentActOwnerOrgId ||
        fromOrganisationId === parentActOwnerOrgId));

  const featureChecks =
    !activityFeatureRequired ||
    (activityFeatureRequired &&
      !isEmpty(userResponse.userFeatures) &&
      userResponse.userFeatures.includes(activityFeatureRequired));

  const resetUserResponse = async () => {
    setUserResponse({
      firstName: null,
      organisation: null,
      isConfirmed: false,
      isVerified: false,
      isCleared: false,
    });
  };

  const handleClear = async (values) => {
    try {
      resetUserResponse();
      onConfirmation(userResponse);
      setEmail('');
      setPanelOpen(false); // Close the panel
    } catch (error) {
      console.error('error', error);
    }
  };

  const clickConfirm = () => {
    const cloneUserResponse = cloneDeep(userResponse);
    delete cloneUserResponse.isConfirmed;
    setUserResponse({ isConfirmed: false, ...cloneUserResponse });
    onConfirmation(userResponse);
    setPanelOpen(false); // Close the panel
  };

  const RenderResponsePanel = (props) => {
    const isFeatureMet =
      !activityFeatureRequired ||
      (activityFeatureRequired &&
        !isEmpty(userResponse.userFeatures) &&
        userResponse.userFeatures.includes(activityFeatureRequired));

    const { fromOrganisationId, toOrganisationId, parentActOwnerOrgId } =
      changeOwnershipPayload;

    const isChangAllowedMet =
      !checkChangeAllow ||
      (checkChangeAllow &&
        (fromOrganisationId === toOrganisationId ||
          toOrganisationId === parentActOwnerOrgId ||
          fromOrganisationId === parentActOwnerOrgId));

    if (!isChangAllowedMet) {
      return (
        <>
          <div className="no-feature-warning-box w-100">
            <div className="padding-issue ">
              You can not not invite this user to this activity. This
              organisation is not matched
            </div>
          </div>
        </>
      );
    } else if (!isFeatureMet) {
      return (
        <>
          <div className="no-feature-warning-box w-100">
            <div className="padding-issue ">
              Invitation to this activity is not possible for the user in
              question as the activity type is not accessible or available to
              them
            </div>
          </div>
        </>
      );
    } else if (isChangAllowedMet && isFeatureMet) {
      return (
        <EmailResponsePanel
          data={userResponse}
          clickConfirm={() => clickConfirm()}
          isPanelOpen={isPanelOpen}
        />
      );
    }
  };

  return (
    <div>
      <Grid>
        <Grid.Column mobile={16} tablet={10} computer={12}>
          <CustomInput
            id="email"
            name="email"
            placeholder="Enter email address"
            getValue={(value) => {
              setEmail(value);
              resetUserResponse();
            }}
            labelText="Email"
            disabled={false}
            requiredStar
          />
          {isEmpty(userResponse.firstName) && userResponse.isVerified ? (
            <span className="not-verified-email">
              This email does not match an existing account in Takso. Enter
              details below to create a guest account for this person
            </span>
          ) : null}
        </Grid.Column>
        <Grid.Column
          className="center-verification-new"
          mobile={16}
          tablet={3}
          computer={2}
        >
          <Button
            content="Verify"
            type="button"
            size="small"
            className="Verify-Primary-Button"
            onClick={handleVerifyEmail}
            loading={verifyLoading}
          />
        </Grid.Column>
        <Grid.Column
          className="center-verification-new"
          mobile={16}
          tablet={3}
          computer={2}
        >
          <Button
            content="Clear"
            type="button"
            className="Verify-Secondary-Button"
            onClick={() => {
              handleClear();
              setFieldValue('email', '');
            }}
          />
        </Grid.Column>
      </Grid>

      {/* check user has feature to link with link activity */}
      {userResponse.firstName && userResponse.isVerified ? (
        <RenderResponsePanel />
      ) : null}
      {showChangeAllow &&
      userResponse.isVerified &&
      userResponse.isConfirmed &&
      isPanelOpen &&
      featureChecks &&
      !isUserConfirmed ? (
        <>
          <div className="error-msg mb-3">Confirmation is required!</div>
        </>
      ) : null}
    </div>
  );
};

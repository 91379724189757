import './organization.scss';

import React from 'react';

import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Form,
  Grid,
  Icon,
  Image,
  Modal,
  Segment,
} from 'semantic-ui-react';

import { isEmpty, isEqual, map } from '../../../utils/lodash';
import {
  getAustralianStates,
  getIndustrySectorTypes,
  getOrganization,
  updateOrganizationInfo,
  updateOrganizationLogo,
} from '../../../store/actions';
import { Formik } from 'formik';
import {
  CustomInput,
  CustomCheckbox,
  CustomDropdown,
} from '../../../components/form';
//import orgLogo from '../../../assets/org-logo.svg';
// import NavigationPrompt from 'react-router-navigation-prompt';
import {
  getOrgProfileInitialValues,
  profileValidationSchema,
  profileValidationSchemaWithDifferentPostalAddress,
} from '../../../validations/orgProfileValidation';
import { countries } from 'country-list-json';

class OrganizationProfile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      organization: [],
      orgForm: {},
      modalShow: false,
      file: null,
      imgPreView: null,
    };
    this.fileInput = React.createRef();
    this.fileInputRef = React.createRef();
    this.diffAddress = this.diffAddress.bind(this);
  }

  async componentDidMount() {
    const {
      auth,
      getOrganization,
      getAustralianStates,
      getIndustrySectorTypes,
    } = this.props;

    if (!isEmpty(auth.user) && auth.user.organisation) {
      await getOrganization(auth.user.organisation.id);
    }

    await Promise.all([getAustralianStates(), getIndustrySectorTypes()]);

    const {
      organization,
      auth: { user },
    } = this.props;
    this.setState({
      organization: organization.organization,
      orgForm: organization.organization,
    });

    const file =
      user.organisation && user.organisation.logo
        ? user.organisation.logo.documentUrl
        : null;

    this.setState({
      imgPreView: file ? file : null,
    });

    if (
      organization.organization &&
      (organization.organization.postalStreetAddress ||
        organization.organization.postalCitySuburb ||
        organization.organization.postalStateId ||
        organization.organization.postalPostcode)
    ) {
      this.setState({ isChecked: true });
    }
  }

  diffAddress() {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  }

  async onSubmit(values) {
    try {
      const file = this.state.file;
      delete values.diffPostalAddress;

      if (!this.state.isChecked) {
        values.postalPostcode =
          values.postalPostcode !== null
            ? values.postalPostcode.toString()
            : null;

        values.postalStreetAddress = null;
        values.postalStreetAddress2 = null;
        values.postalCitySuburb = null;
        values.postalStateId = null;
        values.postalPostcode = null;
      }

      if (file) {
        await this.props.updateOrganizationLogo(
          this.state.organization.id,
          file
        );
      }
      await this.props.updateOrganizationInfo(values);
      this.form && this.form.reset();
      this.setState({
        modalShow: false,
      });
      this.props.isEditToggle(false);
    } catch (error) {
      console.error('error from updating organization: ', error);
      NotificationManager.error('Oops, Something went wrong!');
    }
  }

  onChange(type, value) {
    if (type === 'logo' && !!value.target.files[0]) {
      this.setState({
        file: value.target.files[0],
        imgPreView: value.target.files[0]
          ? window.URL.createObjectURL(value.target.files[0])
          : null,
      });
    }

    this.setState(
      (prevState) => ({
        orgForm: {
          ...prevState.orgForm,
          [type]: isNaN(value) ? value : Number(value),
        },
      }),
      () => {
        this.isSaved();
      }
    );
  }

  async isSaved() {
    const { organization } = this.props.organization;
    const { orgForm } = this.state;

    if (!isEmpty(orgForm)) {
      let modalShow = null;
      if (isEqual(organization, orgForm)) {
        modalShow = false;
      } else {
        modalShow = true;
      }
      await this.setState({
        modalShow,
      });
      this.props.isEditToggle(this.state.modalShow);
    }
  }

  leaveThePage() {
    this.setState((prevState) => ({
      modalShow: !prevState.modalShow,
    }));
  }

  toggle() {
    this.setState((prevState) => ({
      modalShow: !prevState.modalShow,
    }));
  }

  render() {
    const { isChecked } = this.state;
    const {
      account = false,
      organization: { organization },
    } = this.props;

    let defaultValue = organization;
    // check if stateId set to default 0 by individual account and make it null
    if (organization.stateId === 0) defaultValue.stateId = null;
    if (
      organization.postalStreetAddress ||
      organization.postalCitySuburb ||
      organization.postalStateId ||
      organization.postalPostcode
    ) {
      defaultValue.diffPostalAddress = ['diffPostalAddress'];
    }

    const stateList = this.props.australianStates.map((item, key) => {
      return {
        key,
        text: item.name,
        value: item.id,
        rest: { key: item.id },
      };
    });

    const sectorTypes = this.props.industrySectorTypes.map((item, key) => {
      return {
        key,
        text: item.name,
        value: item.id,
        rest: {
          key: item.id,
        },
      };
    });

    const renderCountries = () =>
      map(countries, (country, key) => {
        return {
          key,
          text: country.name,
          value: country.name,
        };
      });

    return (
      <div>
        {/* <NavigationPrompt when={() => this.state.modalShow}> */}
        {({ onConfirm, onCancel }) => (
          <div className="msg-leave">
            <Modal
              onOpen={() => this.toggle()}
              size="tiny"
              open={true}
              closeIcon={
                <Icon onClick={onCancel} name="close" className="closeicon" />
              }
            >
              <Modal.Header className="warning-header-text">
                Are you sure you want to leave this page?
              </Modal.Header>
              <Modal.Content className="warning-content-text">
                You have unsaved changes. Do you want to leave ?
              </Modal.Content>
              <div className="buttons-stay-leave">
                <Button onClick={onCancel} className="stay">
                  Stay
                </Button>
                <Button onClick={onConfirm} content="Leave" className="leave" />
              </div>
            </Modal>
          </div>
        )}
        {/* </NavigationPrompt> */}

        <Segment
          className="content-segment"
          loading={this.props.organizationLoading}
        >
          <Formik
            enableReinitialize
            initialValues={getOrgProfileInitialValues(organization)}
            onSubmit={(values, { resetForm }) => {
              this.onSubmit(values);
              resetForm({ values: '' });
            }}
            validationSchema={
              isChecked
                ? profileValidationSchemaWithDifferentPostalAddress
                : profileValidationSchema
            }
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Grid columns={2} stackable>
                  <Grid.Row className="org-row">
                    <Grid.Column
                      mobile={16}
                      tablet={6}
                      computer={6}
                      className="org-sub-column"
                    >
                      <CustomInput
                        id="name"
                        name="name"
                        labelText={`${
                          account ? 'Account' : 'Organization'
                        } Name`}
                        getValue={(e) => this.onChange('name', e)}
                        requiredStar
                      />

                      <CustomDropdown
                        id="industrySectorTypeId"
                        name="industrySectorTypeId"
                        labelText="Organization Sector Type"
                        options={sectorTypes}
                        getValue={(e) =>
                          this.onChange('industrySectorTypeId', e)
                        }
                        requiredStar
                      />

                      <CustomInput
                        id="phone"
                        name="phone"
                        labelText="Phone"
                        getValue={(e) => this.onChange('phone', e)}
                        requiredStar
                      />

                      <CustomInput
                        id="domain"
                        name="domain"
                        labelText="Web Domain"
                        getValue={(e) => this.onChange('domain', e)}
                        requiredStar
                      />

                      <CustomInput
                        id="email"
                        name="email"
                        labelText="Contact Email"
                        getValue={(e) => this.onChange('email', e)}
                        requiredStar
                      />

                      <label className="org-profile-pic-label">
                        Organization Logo
                      </label>

                      <div className="org-profile-pic-segment">
                        {/* <div className="org-profile-pic"> */}
                        <Grid.Row>
                          <Grid.Column mobile={16} tablet={16} computer={8}>
                            <span className="org-profile-container">
                              <Image src={this.state.imgPreView} size="small" />
                            </span>
                          </Grid.Column>

                          <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={8}
                            className="org-profile-btn-col"
                          >
                            <div className="org-profile-btn">
                              <label
                                className="org-click-to-upload"
                                onClick={() =>
                                  this.fileInputRef.current.click()
                                }
                              >
                                Click to upload
                              </label>

                              {!!this.state.file ? (
                                <div className="org-profile-pic-name">
                                  <Icon name="attach" /> {this.state.file.name}
                                </div>
                              ) : null}
                              <label className="org-profile-des">
                                Supports jpg, png, gmp formats
                              </label>
                            </div>
                            <input
                              ref={this.fileInputRef}
                              type="file"
                              hidden
                              onChange={(e) => this.onChange('logo', e)}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        {/* </div> */}
                      </div>

                      {isMobile ? null : (
                        <Button
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type="submit"
                          className="Primary-Button"
                        >
                          Save Changes
                        </Button>
                      )}
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      tablet={6}
                      computer={6}
                      className="org-sub-column sub-columnRight"
                    >
                      <CustomDropdown
                        search
                        name="country"
                        id="country"
                        labelText="Country"
                        placeholder="Select country"
                        options={renderCountries()}
                        requiredStar
                      />

                      <CustomInput
                        id="streetAddress"
                        name="streetAddress"
                        labelText="Address Line 1"
                        getValue={(e) => this.onChange('streetAddress', e)}
                        requiredStar
                      />
                      <CustomInput
                        id="streetAddress2"
                        name="streetAddress2"
                        labelText="Address Line 2"
                        getValue={(e) => this.onChange('streetAddress2', e)}
                      />
                      <CustomInput
                        id="citySuburb"
                        name="citySuburb"
                        labelText="City / Suburb"
                        getValue={(e) => this.onChange('citySuburb', e)}
                        requiredStar
                      />

                      <CustomDropdown
                        id="stateId"
                        name="stateId"
                        labelText="Postal State"
                        options={stateList}
                        getValue={(e) => this.onChange('stateId', e)}
                        requiredStar
                      />
                      <CustomInput
                        id="postcode"
                        name="postcode"
                        labelText="Postal Code"
                        getValue={(e) => this.onChange('postcode', e)}
                        requiredStar
                      />
                      <CustomCheckbox
                        className="check-box"
                        id="diffPostalAddress"
                        name="diffPostalAddress"
                        label="Different Postal Address"
                        // value="diffPostalAddress"
                        checked={isChecked}
                        onChange={this.diffAddress}
                      />

                      {isChecked && (
                        <div>
                          <CustomInput
                            id="postalStreetAddress"
                            name="postalStreetAddress"
                            labelText="Postal Address Line 1"
                            getValue={(e) =>
                              this.onChange('postalStreetAddress', e)
                            }
                            requiredStar
                          />

                          <CustomInput
                            id="postalStreetAddress2"
                            name="postalStreetAddress2"
                            labelText="Postal Address Line 2"
                            getValue={(e) =>
                              this.onChange('postalStreetAddress2', e)
                            }
                          />

                          <CustomInput
                            id="postalCitySuburb"
                            name="postalCitySuburb"
                            labelText="Postal City / Suburb"
                            getValue={(e) =>
                              this.onChange('postalCitySuburb', e)
                            }
                            requiredStar
                          />
                          <CustomDropdown
                            id="postalStateId"
                            name="postalStateId"
                            labelText="Postal State"
                            options={stateList}
                            //getValue={e => this.onChange('postalStateId', e)}
                            requiredStar
                          />

                          <CustomInput
                            id="postalPostcode"
                            name="postalPostcode"
                            labelText="Postal Postcode"
                            getValue={(e) => this.onChange('postalPostcode', e)}
                            requiredStar
                          />
                        </div>
                      )}

                      {isMobile ? (
                        <Button
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type="submit"
                          className="Primary-Button"
                        >
                          Save Changes
                        </Button>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          </Formik>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
    organizationLoading: state.organization.loading,
    australianStates: state.extra.australianStates,
    industrySectorTypes: state.extra.industrySectorTypes,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganization,
      getAustralianStates,
      getIndustrySectorTypes,
      updateOrganizationLogo,
      updateOrganizationInfo,
    },
    dispatch
  );
};

OrganizationProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationProfile);

export { OrganizationProfile };

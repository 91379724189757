import {
  Button,
  Dropdown,
  Grid,
  Input,
  Label,
  TextArea,
} from 'semantic-ui-react';
import {
  AggregateChart,
  IndividualRateOfChangeChart,
  SeparateChart,
  TrendRateOfChangeChart,
} from '../../../../components/chartsWidgets';
import { compact, get, isArray, isEmpty } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getOutcomes } from '../../../../store/actions';
import { useSelector } from 'react-redux';
import { aggregateArrays } from '../../../../utils/customFunctions';
import { CSVLink } from 'react-csv';
import { BrowseFile } from '../../../../components';
import Papa from 'papaparse';
import { authMsg, getCommonError } from '../../../../utils';
import html2canvas from 'html2canvas';

const SEPARATE_DATASET = [
  { label: 'activityName', key: 'activityName' },
  { label: 'post1', key: 'post1' },
  { label: 'post2', key: 'post2' },
  { label: 'post3', key: 'post3' },
  { label: 'post4', key: 'post4' },
  { label: 'post5', key: 'post5' },
  { label: 'post6', key: 'post6' },
  { label: 'post7', key: 'post7' },
  { label: 'post8', key: 'post8' },
  { label: 'post9', key: 'post9' },
  { label: 'post10', key: 'post10' },
];

const INDIVIDUAL_RATE_OF_CHANGE_DATASET = [
  { label: 'count', key: 'count' },
  { label: 'postResult', key: 'postResult' },
  { label: 'preResult', key: 'preResult' },
];

const ManualCSVTemplate = (props) => {
  const { chartType } = props;
  const UPLOAD_DATASET =
    chartType === 'SEPARATE'
      ? SEPARATE_DATASET
      : INDIVIDUAL_RATE_OF_CHANGE_DATASET;

  return (
    <span className="upload-template-container">
      <CSVLink
        className="upload-template-link"
        headers={UPLOAD_DATASET}
        data={[]}
        filename={`${chartType.toLowerCase()}-template.csv`}
      >
        <label>Download CSV Template</label>
      </CSVLink>
    </span>
  );
};

export default function ManualOutcomesSummary(props) {
  const [activityCount, setActivityCount] = React.useState(0);
  const [totalParticipants, setTotalParticipants] = React.useState(0);
  const [totalRespondents, setTotalRespondents] = React.useState(0);
  const [dataset, setDataset] = React.useState('');
  const [datasetError, setDatasetError] = React.useState(false);
  const [outcomeType, setOutcomeType] = React.useState('');
  const [savingLoading, setSavingLoading] = React.useState(false);

  const outcomeTypes = useSelector((state) => state.extra.outcomes);
  const outcomeTypeOptions = outcomeTypes.map(({ title }, key) => ({
    key,
    text: title,
    value: title,
  }));

  const { chartType } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getOutcomes());
  }, []);

  React.useEffect(() => {
    setDataset('');
  }, [chartType]);

  React.useEffect(() => {
    if (dataset && !isValidJSON(dataset)) {
      setDatasetError(true);
    } else {
      setDatasetError(false);
    }
  }, [dataset]);

  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  };

  const setCsvFormattedDataset = (results) => {
    try {
      const { chartType } = props;

      const updatedResults = [];
      if (chartType === 'SEPARATE') {
        results.map((result) => {
          const {
            post1,
            post2,
            post3,
            post4,
            post5,
            post6,
            post7,
            post8,
            post9,
            post10,
          } = result;

          const data = [
            post1 ? Number(post1).toFixed(4) : 0,
            post2 ? Number(post2).toFixed(4) : 0,
            post3 ? Number(post3).toFixed(4) : 0,
            post4 ? Number(post4).toFixed(4) : 0,
            post5 ? Number(post5).toFixed(4) : 0,
            post6 ? Number(post6).toFixed(4) : 0,
            post7 ? Number(post7).toFixed(4) : 0,
            post8 ? Number(post8).toFixed(4) : 0,
            post9 ? Number(post9).toFixed(4) : 0,
            post10 ? Number(post10).toFixed(4) : 0,
          ];

          updatedResults.push({
            name: get(result, 'activityName', ''),
            data,
          });
        });
      } else if (chartType === 'INDIVIDUAL_RATE_OF_CHANGE') {
        results.map((result) => {
          updatedResults.push({
            postResult: Number(get(result, 'postResult', 0)),
            preResult: Number(get(result, 'preResult', 0)),
            count: parseInt(get(result, 'count', 0)),
          });
        });
      }

      if (!isEmpty(updatedResults)) {
        setDataset(JSON.stringify(updatedResults));
      } else {
        setDataset('');
      }
    } catch (error) {
      authMsg('error', getCommonError('Invalid CSV format'));
    }
  };

  const uploadFileHandler = (e) => {
    Papa.parse(e.file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setCsvFormattedDataset(results.data);
      },
    });
  };

  const RenderChart = (props) => {
    const { type, data } = props;

    const _dataset = {
      name: get(data, 'outcomeType', ''),
      totalParticipants: get(data, 'totalParticipants', 0),
      activityCount: get(data, 'activityCount', 0),
      evalCount: get(data, 'evalCount', 0),
      programsActivityCount: get(data, 'programsActivityCount', 0),
      linkedActivityCount: get(data, 'linkedActivityCount', 0),
      totalRespondents: get(data, 'totalRespondents', 0),
      results: get(data, 'dataset', []),
      participantResponse: get(data, 'dataset', []),
      data: get(data, 'dataset', []),
      dataAll: get(data, 'dataset', []),
    };

    switch (type) {
      case 'AGGREGATE':
        return <AggregateChart method={1} participants={_dataset} />;

      case 'SEPARATE':
        const formattedData = get(data, 'dataset', [])
          ? compact(get(data, 'dataset', []).map((item) => item.data))
          : [];

        const aggregateSeparateChartData =
          formattedData && formattedData.length > 0
            ? aggregateArrays(formattedData)
            : [];

        return (
          <SeparateChart
            participants={{ ..._dataset, data: aggregateSeparateChartData }}
          />
        );
      case 'TREND_RATE_OF_CHANGE':
        return (
          <TrendRateOfChangeChart
            showLegends={false}
            participantsChangePrePost={_dataset}
          />
        );
      case 'INDIVIDUAL_RATE_OF_CHANGE':
        return <IndividualRateOfChangeChart participantsChange={_dataset} />;
      case 'INTERCEPT_AGGREGATE':
        return <AggregateChart method={12} participants={_dataset} />;
      case 'INTERCEPT_SEPARATE':
        return <SeparateChart method={12} />;
      default:
        return <AggregateChart />;
    }
  };

  const getDatasetPlaceholderText = () => {
    const { chartType } = props;

    switch (chartType) {
      case 'AGGREGATE':
        return '[0, 0, 0, 0, 0, 0, 0, 0, 0, 0]';
      // case 'SEPARATE':
      //   return '[{"data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], "name": "Activity Name"}]';
      case 'TREND_RATE_OF_CHANGE':
        return '[[0, 0, 0, 0, 0, 0, 0, 0, 0, 0],[0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]';
      // case 'INDIVIDUAL_RATE_OF_CHANGE':
      //   return '[{"postResult": 0, "preResult": 0, "count": 0}]';
      case 'INTERCEPT_AGGREGATE':
        return '[0, 0, 0, 0, 0, 0, 0, 0, 0, 0]';

      default:
        return '[0, 0, 0, 0, 0, 0, 0, 0, 0, 0]';
    }
  };

  const downloadChart = async () => {
    try {
      setSavingLoading(true);
      html2canvas(document.getElementById('downloadChartContainer'), {
        scale: 2,
      }).then((canvas) => {
        const qrCodeURL = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
        let aEl = document.createElement('a');
        aEl.href = qrCodeURL;
        aEl.download = `${chartType}_CHART.png`;
        document.body.appendChild(aEl);
        aEl.click();

        setSavingLoading(false);

        document.body.removeChild(aEl);
      });
    } catch (error) {
      setSavingLoading(false);
    }
  };

  return (
    <>
      <Grid>
        <Grid.Column mobile={16} tablet={10} computer={4}>
          <div className="fin-head mt-2 mb-5">
            <label className="input-label">Outcome</label>

            <Dropdown
              className="report-type"
              name="outcomeType"
              onChange={(e, data) => {
                setOutcomeType(data.value);
              }}
              options={outcomeTypeOptions}
              placeholder="Choose an outcome"
              selection
              value={outcomeType}
            />
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={10} computer={4}>
          <div className="fin-head mt-2 mb-5">
            <label className="input-label">Activity Count</label>
            <Input
              id="activityCount"
              name="activityCount"
              value={activityCount}
              placeholder="Enter Activity Count"
              onChange={(e, data) => setActivityCount(data.value)}
            />
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={10} computer={4}>
          <div className="fin-head mt-2 mb-5">
            <label className="input-label">Total Participants</label>
            <Input
              id="totalParticipants"
              name="totalParticipants"
              value={totalParticipants}
              placeholder="Enter Activity Count"
              onChange={(e, data) => setTotalParticipants(data.value)}
            />
          </div>
        </Grid.Column>{' '}
        <Grid.Column mobile={16} tablet={10} computer={4}>
          <div className="fin-head mt-2 mb-5">
            <label className="input-label">Total Respondents</label>
            <Input
              id="totalRespondents"
              name="totalRespondents"
              value={totalRespondents}
              placeholder="Enter Activity Count"
              onChange={(e, data) => setTotalRespondents(data.value)}
            />
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={10} computer={4}>
          <div className="fin-head mt-2 mb-5">
            {chartType === 'SEPARATE' ||
            chartType === 'INDIVIDUAL_RATE_OF_CHANGE' ? (
              <>
                <BrowseFile
                  label="Upload Template"
                  name="strategic"
                  accept=".csv"
                  buttonText="BROWSE"
                  setFile={uploadFileHandler}
                  // loading={this.state.loading}
                  csv
                />
              </>
            ) : (
              <>
                <label className="input-label">Dataset</label>
                <TextArea
                  id="dataset"
                  name="dataset"
                  rows={
                    chartType === 'SEPARATE' ||
                    chartType === 'INDIVIDUAL_RATE_OF_CHANGE'
                      ? 8
                      : 5
                  }
                  value={dataset}
                  placeholder={getDatasetPlaceholderText()}
                  onChange={(e, data) => setDataset(data.value)}
                />

                {datasetError && (
                  <p className="text-error mt-1">
                    Invalid format, Enter following format
                    <br />
                    {getDatasetPlaceholderText()}
                  </p>
                )}
              </>
            )}
          </div>
        </Grid.Column>{' '}
        {(chartType === 'SEPARATE' ||
          chartType === 'INDIVIDUAL_RATE_OF_CHANGE') && (
          <Grid.Column mobile={16} tablet={10} computer={4}>
            <div className="fin-head mt-2 mb-5">
              <>
                <ManualCSVTemplate chartType={chartType} />
              </>
            </div>
          </Grid.Column>
        )}
        <Grid.Column mobile={2} tablet={2} computer={2}>
          <div className="fin-head mt-2 mb-5">
            <Button
              className="mt-2 Primary-Button"
              onClick={() => {
                downloadChart();
              }}
              loading={savingLoading}
            >
              Save Chart
            </Button>
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={10} computer={16}>
          <RenderChart
            type={chartType}
            data={{
              activityCount,
              totalParticipants,
              totalRespondents,
              outcomeType,
              dataset:
                dataset && isValidJSON(dataset) && isArray(JSON.parse(dataset))
                  ? JSON.parse(dataset)
                  : '',
            }}
          />
        </Grid.Column>
      </Grid>
    </>
  );
}

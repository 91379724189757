/* eslint-disable no-eval */
/* eslint-disable eqeqeq */
import React, { PureComponent, useEffect, useState } from 'react';
import {
  Segment,
  Grid,
  Confirm,
  Icon,
  Divider,
  Checkbox,
} from 'semantic-ui-react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkEvaluationMethod, getPageDisable } from '../../../../utils/';
import {
  isParentProgramActivity,
  isSingleAndLinkedActivity,
} from '../../../../utils/activity';
import {
  AvForm,
  AvGroup,
  AvFeedback,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import {
  cloneDeep,
  filter,
  forEach,
  isEmpty,
  sortBy,
  map,
  get,
  compact,
} from '../../../../utils/lodash';

import {
  putActivity,
  postGroupSelected,
  getSelectedGroup,
  updateGroupSelected,
  deleteGroupSelected,
  updateProjectInputs,
} from '../../../../store/actions';
import { CustomTooltip } from '../../../../components';

import '../activity.scss';
import thousands from 'thousands';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

export const OutputMultiOptions = ({ item, currentActivity, actual }) => {
  const [selectedOptionsValue, setSelectedOptions] = useState([]);
  useEffect(() => {
    if (!isEmpty(item)) {
      const optionSelected = compact(
        currentActivity.output
          .flatMap((item) =>
            item.multiOutputs?.map((value) =>
              actual
                ? parseInt(value.actualValue ?? '')
                : parseInt(value.projectedValue ?? '')
            )
          )
          .filter((value) => value !== null && value !== undefined)
      );
      setSelectedOptions(optionSelected);
    }
  }, [item]);

  const dispatch = useDispatch();

  const selectedOptions = async (check, outputFormatId, optionId) => {
    let updatedSelectedOptions = cloneDeep(selectedOptionsValue);
    if (
      check &&
      (!updatedSelectedOptions.includes(optionId) ||
        isEmpty(updatedSelectedOptions))
    ) {
      updatedSelectedOptions.push(optionId);
    } else if (!check && updatedSelectedOptions.includes(optionId)) {
      updatedSelectedOptions = updatedSelectedOptions.filter(
        (item) => item !== optionId
      );
    }
    setSelectedOptions(updatedSelectedOptions);

    const data = {
      outputFormatId: outputFormatId,
      isChecked: check,
      multiOption: true,
    };

    if (actual) {
      data.actualValue = optionId;
    } else {
      data.projectedValue = optionId;
    }
    dispatch(
      putActivity(actual ? 'actualOutput' : 'projectedOutputs', {
        output: [data],
      })
    );
  };

  const renderProjectedValues = () => {
    try {
      const outputObj = currentActivity.output.find(
        (outputItem) => outputItem.outputFormatId === item.outputFormatId
      );
      if (outputObj !== undefined) {
        const projectedValues = compact(
          outputObj.multiOutputs.map((data) => get(data, 'optionName.name', ''))
        );
        return isEmpty(projectedValues) ? '-' : projectedValues.toString();
      }
      return '-';
    } catch (err) {
      console.error('Error');
    }
  };

  return (
    <>
      <div className="multi-checkbox-group">
        {map(item.options, (val, index) => {
          return (
            <Checkbox
              key={index}
              className="check-box"
              label={val.name}
              checked={selectedOptionsValue.includes(val.id)}
              onChange={(e, { checked }) =>
                selectedOptions(checked, item.outputFormatId, val.id)
              }
            />
          );
        })}
      </div>
      {actual && (
        <label className="projected-value">
          Projected {item.label} : {renderProjectedValues()}
        </label>
      )}
    </>
  );
};

class ActivityCheckBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      confirm: false,
    };
    this.onCheckChange = this.onCheckChange.bind(this);
  }

  componentDidMount() {
    const { actual, currentActivity, item } = this.props;
    let value = false;
    const outputObj = currentActivity.output.find(
      (outputItem) => outputItem.outputFormatId === item.outputFormatId
    );
    let checkBoxValue = '0';

    if (outputObj) {
      checkBoxValue = actual ? outputObj.actualValue : outputObj.projectedValue;
    }

    if (checkBoxValue) {
      value = checkBoxValue === '0' || checkBoxValue === '0.00' ? false : true;
    }

    this.setState({
      isChecked: value,
    });
  }

  onCheckChange() {
    const { currentActivity, actual } = this.props;
    if (actual || checkEvaluationMethod(currentActivity.evaluation)) {
      this.setState(
        (prevState) => ({
          isChecked: !prevState.isChecked,
        }),
        () => {
          const { actual, putActivity, item } = this.props;
          putActivity('projectedOutputs', {
            output: [
              {
                outputFormatId: item.outputFormatId,
                [`${actual ? 'actualValue' : 'projectedValue'}`]: this.state
                  .isChecked
                  ? 1
                  : 0,
              },
            ],
          });
        }
      );
    } else {
      this.setState({
        confirm: true,
      });
    }
  }

  close = () => {
    this.setState({ confirm: false });
  };

  onConfirm = () => {
    this.setState({ confirm: false }, () => {
      this.setState(
        (prevState) => ({
          isChecked: !prevState.isChecked,
        }),
        () => {
          const { actual, putActivity, item } = this.props;
          putActivity('projectedOutputs', {
            output: [
              {
                outputFormatId: item.outputFormatId,
                [`${actual ? 'actualValue' : 'projectedValue'}`]: this.state
                  .isChecked
                  ? 1
                  : 0,
              },
            ],
          });
        }
      );
    });
  };

  render() {
    const { isChecked, confirm } = this.state;
    const { actual, item } = this.props;
    return (
      <div>
        <AvCheckboxGroup
          name={`${actual ? 'actualOutput' : 'projectedOutput'}${item.order}`}
          className="firefox-checkbox-color"
          inline
        >
          <AvCheckbox
            label={item.label}
            checked={isChecked}
            onChange={(e) => this.onCheckChange()}
          />
        </AvCheckboxGroup>
        <Confirm
          open={confirm}
          onCancel={this.close}
          onConfirm={() => this.onConfirm()}
          header="Warning!"
          content="You are have made changes to the activity after adding documentation information.  Please review the documentation information to ensure all information is still correct and update any fields affected by changes."
          size="tiny"
        />
      </div>
    );
  }
}

class ActivityOutput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      coreParticipant: false,
      confirm: false,
      applicationCloseDateError: '',
      form: {
        projectedBudget: props.currentActivity.projectedBudget
          ? thousands(props.currentActivity.projectedBudget)
          : '0',
        actualBudget: props.currentActivity.actualBudget
          ? thousands(props.currentActivity.actualBudget)
          : '0',
        reportYearValue: props.currentActivity.reportYear
          ? moment(props.currentActivity.reportYear).format('DD MMM YYYY')
          : '',
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentActivity } = this.props;
    if (prevProps.currentActivity !== currentActivity) {
      this.setState({
        form: {
          ...this.state.form,
          projectedBudget: currentActivity.projectedBudget
            ? thousands(currentActivity.projectedBudget)
            : '0',
          actualBudget: currentActivity.actualBudget
            ? thousands(currentActivity.actualBudget)
            : '0',
        },
      });
    }
  }

  onChange(value, type) {
    let { form } = this.state;
    var isNum = /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/.test(value);
    if (isNum) {
      form[type] = value;
      this.setState({ form });
    } else if (value === '') {
      form[type] = '0';
      this.setState({ form });
    }
  }

  onFocus(value, type) {
    const form = cloneDeep(this.state.form);
    value = value.replace(/,/g, '');
    var isNum = /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/.test(value);
    if (isNum) {
      form[type] = value;
      this.setState({ form });
    } else if (value === '') {
      form[type] = '0';
      this.setState({ form });
    }
  }

  async onBlurInput(type) {
    const { putActivity } = this.props;
    const { form } = this.state;
    if (form[type]) {
      await putActivity('projectedOutputs', {
        [type]: form[type] ? form[type].replace(/,/g, '') : '0',
      });
    }
  }

  onBlurCoreInput(item) {
    try {
      let { currentActivity, putActivity, actual } = this.props;
      const form = cloneDeep(this.state.form);
      let type = `${actual ? 'actualOutput' : 'projectedOutput'}${item.order}`;

      let getUpdateValue = 0;

      if (form[type]) {
        getUpdateValue = form[type].replace(/,/g, '');
      } else {
        const outputObj = currentActivity.output.find(
          (outputItem) => outputItem.outputFormatId === item.outputFormatId
        );
        getUpdateValue = actual
          ? get(outputObj, 'actualValue', '0')
          : get(outputObj, 'projectedValue', '0');
      }

      if (item.type === 'currency' || item.type === 'decimal') {
        getUpdateValue = Number.parseFloat(getUpdateValue).toFixed(2);
      } else {
        const isValidNumber = /^[0-9]+(,[0-9]+)*,?$/.test(getUpdateValue);
        if (!isValidNumber) {
          return;
        }
      }

      form[type] = thousands(getUpdateValue);

      this.setState({ form }, async () => {
        // get format object that inculde a formula
        const formatsWithFormula = await filter(
          currentActivity.eventDetail.outputFormat.format,
          (o) => {
            return o.formula && o.formula !== '';
          }
        );

        if (isEmpty(formatsWithFormula)) {
          if (checkEvaluationMethod(currentActivity.evaluation)) {
            await putActivity('projectedOutputs', {
              output: [
                {
                  outputFormatId: item.outputFormatId,
                  [`${actual ? 'actualValue' : 'projectedValue'}`]:
                    getUpdateValue,
                },
              ],
            });
          } else if (actual) {
            await putActivity('projectedOutputs', {
              [type]: getUpdateValue,
            });
          } else {
            this.setState(
              {
                confirm: true,
              },
              () => {
                this.type = type;
                this.dataSet = {
                  [type]: getUpdateValue,
                };
              }
            );
          }
        } else {
          if (checkEvaluationMethod(currentActivity.evaluation)) {
            await putActivity('projectedOutputs', {
              output: [
                {
                  outputFormatId: item.outputFormatId,
                  [`${actual ? 'actualValue' : 'projectedValue'}`]:
                    getUpdateValue,
                },
              ],
            });
            const formulaObject =
              await this.calculateFormula(formatsWithFormula);
            putActivity('projectedOutputs', formulaObject);
          } else if (actual) {
            await putActivity('projectedOutputs', {
              [type]: getUpdateValue,
            });
            const formulaObject =
              await this.calculateFormula(formatsWithFormula);
            putActivity('projectedOutputs', formulaObject);
          } else {
            this.setState(
              {
                confirm: true,
              },
              () => {
                this.type = type;
                this.dataSet = {
                  [type]: getUpdateValue,
                };
                this.format = formatsWithFormula;
              }
            );
          }
        }
      });
    } catch (error) {
      console.error('onBlurCoreInput -> error', error);
    }
  }

  calculateFormula(formatsWithFormula) {
    try {
      const { actual } = this.props;
      let formulaObject = { output: [] };

      forEach(formatsWithFormula, async (item) => {
        let newFormula = item.formula;
        this.props.currentActivity.eventDetail.outputFormat.format.forEach(
          (item2) => {
            const outputObj = this.props.currentActivity.output.find(
              (outputItem) => outputItem.outputFormatId === item2.outputFormatId
            );
            let getValue = '0';

            if (outputObj) {
              getValue = actual
                ? parseInt(outputObj.actualValue)
                  ? parseInt(outputObj.actualValue)
                  : '0'
                : parseInt(outputObj.projectedValue)
                  ? parseInt(outputObj.projectedValue)
                  : '0';
            }
            if (getValue) {
              newFormula = newFormula.replace(
                new RegExp('\\b' + item2.name + '\\b', 'g'),
                getValue
              );
            }
          }
        );
        const value = eval(newFormula);
        formulaObject.output.push({
          outputFormatId: item.outputFormatId,
          [`${actual ? 'actualValue' : 'projectedValue'}`]: value ? value : '0',
        });
      });
      return formulaObject;
    } catch (error) {
      // console.error('calculateFormula -> error', error);
      throw error;
    }
  }

  getReadOnly(item) {
    if (item.formula && item.formula !== '') return true;
    return item.readonly;
  }

  getValues(actual, currentActivity, item) {
    const { form } = this.state;
    // what is item.id
    const type = `${actual ? 'actualOutput' : 'projectedOutput'}${item.order}`;
    const outputObj = currentActivity.output.find(
      (outputItem) => outputItem.outputFormatId === item.outputFormatId
    );
    let itemValue = actual
      ? get(outputObj, 'actualValue', '0')
      : get(outputObj, 'projectedValue', '0');
    let getValue;

    if (form[type]) {
      getValue = form[type];
    } else {
      if (item.type === 'date') {
        getValue = itemValue;
      } else {
        getValue = thousands(itemValue) ? thousands(itemValue) : '0';
      }
    }

    if (item.type !== 'currency') {
      getValue = getValue ? getValue : 0;
      if (getValue) getValue = getValue.replace(/\.00$/, '');
    }

    if (item.name === 'totalParticipants' && actual) {
      //pass the calculated actual participant for the total participant input
      getValue = thousands(currentActivity.actualParticipants);
    }

    if (item.type === 'date') {
      let _getValue = moment(getValue, 'DD MMM YYYY');
      let formattedDate = _getValue.isValid()
        ? _getValue.format('DD MMM YYYY')
        : ''; // Handle invalid dates
      getValue = formattedDate;
    }

    return getValue;
  }

  getProjectedValues(item) {
    const { form } = this.state;
    const { currentActivity } = this.props;
    const type = `${'projectedOutput'}${item.order}`;

    const outputObj = currentActivity.output.find(
      (outputItem) => outputItem.outputFormatId === item.outputFormatId
    );
    let getValue = '0';

    if (outputObj) {
      getValue = form[type]
        ? form[type]
        : thousands(outputObj.projectedValue)
          ? thousands(outputObj.projectedValue)
          : '0';
    }

    if (item.type !== 'currency') {
      getValue = getValue ? getValue : 0;
      if (getValue) getValue = getValue.replace(/\.00$/, '');
    }

    let results = `${'Projected '}${item.label} : ${getValue}`;

    return results;
  }

  getInputType(type, currencyIconName = 'dollar sign') {
    switch (type) {
      case 'currency':
        return (
          <InputGroupAddon addonType="prepend">
            <Icon circular inverted name={currencyIconName} color="grey" />
          </InputGroupAddon>
        );
      case 'percentage':
        return <InputGroupAddon addonType="prepend">%</InputGroupAddon>;
      default:
        break;
    }
  }

  getToolTipData = (input, helpTips, actual) => {
    let key = null;
    switch (input.name) {
      case 'sessions':
        actual ? (key = '67') : (key = '43');
        break;
      case 'totalParticipants':
        actual ? (key = '163') : (key = '47');
        break;
      case 'receptiveParticipantsFree':
        actual ? (key = '69') : (key = '45');
        break;
      case 'receptiveParticipantsPaid':
        actual ? (key = '72') : (key = '48');

        break;
      case 'activeParticipants':
        actual ? (key = '70') : (key = '46');
        break;
      case 'attendanceTicketed':
        key = '';
        break;
      case 'newWorksAcquired':
        actual ? (key = '159') : (key = '139');
        break;
      case 'activeParticipantsPaying':
        actual ? (key = '186') : (key = '138');
        break;
      case 'activeParticipantsPaid':
        actual ? (key = '155') : (key = '137');
        break;
      case 'activeParticipantsFree':
        actual ? (key = '154') : (key = '185');
        break;

      case 'totalHours':
        actual ? (key = '189') : (key = '178');

        break;
      case 'receptiveParticipantsPaying':
        actual ? (key = '162') : (key = '179');
        break;

      case 'itemsAccessed':
        actual ? (key = '157') : (key = '180');
        break;

      case 'numberBorrowers':
        actual ? (key = '158') : (key = '181');
        break;

      case 'turnoverRate':
        actual ? (key = '188') : (key = '182');
        break;

      default:
        break;
    }
    return { data: helpTips, key };
  };

  renderInputs(actual, currentActivity) {
    const { putActivity, user, helpTips } = this.props;
    const { applicationCloseDateError } = this.state;
    if (isEmpty(currentActivity.eventDetail)) return null;
    const inputs =
      sortBy(currentActivity.eventDetail.outputFormat.format, (o) => {
        return o.order;
      }) || [];

    const currencyIconName = get(
      currentActivity,
      'currencyType.iconName',
      'dollar sign'
    );

    if (isEmpty(inputs)) return null;
    return (
      <>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          className="outputs-col"
        >
          <div className="outputs-col-div">
            <label className="income-title">
              {!actual ? 'Projected ' : ''}Activity Data
            </label>
          </div>
        </Grid.Column>
        {map(inputs, (item, i) => {
          switch (item.type) {
            case 'checkbox':
              return (
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={6}
                  className="outputs-col "
                  key={i}
                >
                  <div className="outputs-col-div act-data-checkbox">
                    <ActivityCheckBox
                      item={item}
                      actual={actual}
                      currentActivity={currentActivity}
                      putActivity={(type, data) => putActivity(type, data)}
                    />
                  </div>
                </Grid.Column>
              );
            case 'date':
              return (
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={6}
                  className="outputs-col"
                  key={i}
                >
                  <div className="outputs-col-div">
                    <div className="income-div">
                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={{
                          data: helpTips,
                          key: '112',
                        }}
                        position="top left"
                      >
                        <label>{item.label}</label>
                      </CustomTooltip>
                      <form autoComplete="off">
                        <div className="date-range-container">
                          <DateRangePicker
                            initialSettings={{
                              drops: 'auto',
                              autoApply: true,
                              singleDatePicker: true,
                              showDropdowns: true,
                              startDate: new Date(),
                              minYear: 2017,
                              maxYear:
                                parseInt(moment().format('YYYY'), 10) + 10,
                            }}
                            onApply={(event, value) =>
                              this.handleActivityCloseDateChange(
                                item,
                                value,
                                actual
                              )
                            }
                          >
                            <div className="date-range-chart p-1">
                              <input
                                value={this.getValues(
                                  actual,
                                  currentActivity,
                                  item
                                )}
                                placeholder="DD-MM-YYYY"
                                type="text"
                                readOnly
                                className="date-range-chart-date"
                              />
                              <Icon name="calendar alternate outline" />
                            </div>
                          </DateRangePicker>
                        </div>
                      </form>
                      <div
                        className={
                          applicationCloseDateError
                            ? 'error-msg-div'
                            : 'error-msg-hidden'
                        }
                      >
                        {applicationCloseDateError}
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              );
            case 'multi-checkbox':
              if (isEmpty(item.options)) return null;

              return (
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  className="outputs-col"
                >
                  <div className="multi-checkbox-container">
                    <div>
                      <label>{item.label}</label>
                    </div>
                    <OutputMultiOptions
                      item={item}
                      currentActivity={currentActivity}
                      actual={actual}
                      {...this.props}
                    />
                  </div>
                </Grid.Column>
              );
            default:
              const isDecimalAllowed =
                item.type === 'currency' || item.type === 'decimal';
              return (
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={6}
                  className="outputs-col"
                  key={i}
                >
                  <div className="outputs-col-div">
                    <div className="income-div">
                      <CustomTooltip
                        state={get(user, 'options.tips')}
                        contentObject={this.getToolTipData(
                          item,
                          helpTips,
                          actual
                        )}
                        position="bottom left"
                        wide="very"
                      >
                        <label>{item.label}</label>
                      </CustomTooltip>
                      <AvGroup>
                        <InputGroup
                          className={
                            item.type === 'currency' ||
                            item.type === 'percentage'
                              ? 'icon-inputs-custom icon-input-filed'
                              : 'withoutIcon-inputs'
                          }
                        >
                          {this.getInputType(item.type, currencyIconName)}
                          <AvField
                            // type="number"
                            className={
                              item.type === 'currency' ||
                              item.type === 'percentage'
                                ? 'avfield-projected-outputs-custom'
                                : 'avfield-projected-outputs'
                            }
                            name={`${
                              actual ? 'actualOutput' : 'projectedOutput'
                            }${item.order}`}
                            errorMessage={
                              isDecimalAllowed
                                ? 'Only numbers are accepted'
                                : 'Only numbers without decimal points are accepted'
                            }
                            validate={{
                              pattern: {
                                value: isDecimalAllowed
                                  ? '[0-9]+(,[0-9]+)*,?$'
                                  : '^[0-9]+(,[0-9]+)*,?$',
                              },
                            }}
                            readOnly={this.getReadOnly(item)}
                            disabled={this.getReadOnly(item)}
                            value={this.getValues(
                              actual,
                              currentActivity,
                              item
                            )}
                            onFocus={(e) =>
                              this.onFocus(
                                e.target.value,
                                `${
                                  actual ? 'actualOutput' : 'projectedOutput'
                                }${item.order}`
                              )
                            }
                            onChange={(e) =>
                              this.onChange(
                                e.target.value,
                                `${
                                  actual ? 'actualOutput' : 'projectedOutput'
                                }${item.order}`
                              )
                            }
                            onBlur={() => this.onBlurCoreInput(item)}
                          />
                        </InputGroup>
                      </AvGroup>
                      {actual && (
                        <label className="projected-value">
                          {this.getProjectedValues(item)}
                        </label>
                      )}
                    </div>
                  </div>
                </Grid.Column>
              );
          }
        })}
      </>
    );
  }

  close = (type) => {
    this.setState({ confirm: false }, () => {
      const { currentActivity } = this.props;
      const getValue = currentActivity[type] ? currentActivity[type] : '0';
      this.setState({
        form: { [type]: getValue },
      });
    });
  };

  onConfirm = (dataSet, format) => {
    const { putActivity } = this.props;
    this.setState({ confirm: false }, async () => {
      await putActivity('projectedOutputs', dataSet);
      if (!isEmpty(format)) {
        const formulaObject = this.calculateFormula(format);
        putActivity('projectedOutputs', formulaObject);
      }
    });
  };

  coreParRereder() {
    this.setState(
      {
        coreParticipant: true,
      },
      () => {
        this.setState({
          coreParticipant: false,
        });
      }
    );
  }

  async handleActivityCloseDateChange(item, value, actual) {
    const date = moment(value.startDate).format('DD MMM YYYY');

    const { putActivity } = this.props;
    await putActivity('projectedOutputs', {
      output: [
        {
          outputFormatId: item.outputFormatId,
          [`${actual ? 'actualValue' : 'projectedValue'}`]: date,
        },
      ],
    });
  }

  handleReportYear = async (event, value) => {
    const isValidDate = moment(value.startDate, 'DD-MMM-YYYY', true).isValid();
    if (isValidDate) {
      const { putActivity } = this.props;
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          reportYearValue: value.startDate.format('DD MMM YYYY'),
        },
      }));
      await putActivity('projectedOutputs', {
        reportYear: value.startDate,
      });
    }
  };

  render() {
    const { confirm, form: forms } = this.state;
    const { actual, currentActivity, user, helpTips, actPut, currentState } =
      this.props;

    const actualTotalCost =
      currentActivity && currentActivity.parentId
        ? +currentActivity.actualGrantAmount +
          +currentActivity.actualBudget -
          currentActivity.actualCost
        : +currentActivity.actualBudget - currentActivity.actualCost;
    const projectedTotalCost =
      currentActivity && currentActivity.parentId
        ? +currentActivity.projectedGrantAmount +
          +currentActivity.projectedBudget -
          currentActivity.projectedCost
        : +currentActivity.projectedBudget - currentActivity.projectedCost;

    const form = {
      totalCost: actual
        ? actualTotalCost
          ? actualTotalCost.toFixed(2)
          : '0'
        : projectedTotalCost
          ? projectedTotalCost.toFixed(2)
          : '0',
    };
    if (actPut.activityData) return null;

    let projectedFinalResults =
      currentActivity && currentActivity.parentId
        ? +currentActivity.projectedGrantAmount +
          +currentActivity.projectedBudget -
          currentActivity.projectedCost
        : +currentActivity.projectedBudget - currentActivity.projectedCost;

    projectedFinalResults = projectedFinalResults.toFixed(2);

    const pageDisabled = getPageDisable(
      currentActivity,
      user,
      currentState,
      actual
    );
    const currencyIconName = get(
      currentActivity,
      'currencyType.iconName',
      'dollar sign'
    );

    return (
      <Segment
        className="outputs-segment"
        disabled={
          actual
            ? currentActivity.statusId == 3 || pageDisabled
              ? true
              : false
            : currentActivity.statusId >= 2 || pageDisabled
              ? true
              : false
        }
      >
        <Confirm
          open={confirm}
          onCancel={() => this.close(this.type)}
          onConfirm={() => this.onConfirm(this.dataSet, this.format)}
          header="Warning!"
          content="You are have made changes to the activity after adding documentation information.  Please review the documentation information to ensure all information is still correct and update any fields affected by changes."
          size="tiny"
        />
        <AvForm ref={(c) => (this.form = c)}>
          <Grid>
            <Grid.Row className="outputs-row icon-input-filed ">
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={6}
                className="outputs-col"
              >
                <div className="outputs-col-div">
                  <div className="income-div">
                    <CustomTooltip
                      state={get(user, 'options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: !actual ? '39' : '63',
                      }}
                      position="bottom left"
                      wide="very"
                    >
                      <label>Other Income</label>
                    </CustomTooltip>
                    <AvGroup>
                      <InputGroup className="icon-inputs-custom icon-input-filed">
                        <InputGroupAddon addonType="prepend">
                          <Icon
                            circular
                            inverted
                            name={currencyIconName}
                            color="grey"
                          />
                        </InputGroupAddon>
                        <AvField
                          className="avfield-income"
                          // type="number"
                          name={actual ? 'actualBudget' : 'projectedBudget'}
                          value={
                            actual ? forms.actualBudget : forms.projectedBudget
                          }
                          errorMessage="Only numbers are accepted"
                          validate={{
                            pattern: {
                              value: '[0-9]+(,[0-9]+)*,?$',
                            },
                          }}
                          onChange={(e) =>
                            this.onChange(
                              e.target.value,
                              `${actual ? 'actualBudget' : 'projectedBudget'}`
                            )
                          }
                          onFocus={(e) =>
                            this.onFocus(
                              e.target.value,
                              `${actual ? 'actualBudget' : 'projectedBudget'}`
                            )
                          }
                          onBlur={() =>
                            this.onBlurInput(
                              `${actual ? 'actualBudget' : 'projectedBudget'}`
                            )
                          }
                        />
                      </InputGroup>
                    </AvGroup>
                    {actual && (
                      <label className="projected-value">
                        Projected Income : {forms.projectedBudget}
                      </label>
                    )}
                  </div>
                </div>
                {/* {currentActivity.activityPlanTypeId === 1 && <hr />} */}
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={8}
                computer={6}
                className="outputs-col"
              >
                <div className="outputs-col-div">
                  <div className="income-div">
                    <CustomTooltip
                      state={get(user, 'options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: !actual ? '95' : '',
                      }}
                      position="bottom left"
                      wide="very"
                    >
                      <label>
                        {`${actual ? 'Actual' : 'Projected'} ${
                          isParentProgramActivity(
                            currentActivity.activityPlanTypeId
                          )
                            ? 'program'
                            : 'activity'
                        } final result`}
                      </label>
                    </CustomTooltip>
                    <AvGroup>
                      <InputGroup className="icon-inputs-custom icon-input-filed">
                        <InputGroupAddon addonType="prepend">
                          <Icon
                            circular
                            inverted
                            name={currencyIconName}
                            color="grey"
                          />
                        </InputGroupAddon>
                        <AvField
                          type="text"
                          name="finalResult"
                          className={
                            form.totalCost < 0
                              ? 'error-text avfield-income '
                              : 'avfield-income '
                          }
                          value={thousands(form.totalCost)}
                          disabled
                          readOnly
                        />
                        <AvFeedback>This field is invalid</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                    {actual && (
                      <label className="projected-value">
                        Projected activity final result :{' '}
                        {thousands(projectedFinalResults)}
                      </label>
                    )}
                  </div>
                </div>
                {/* {currentActivity.activityPlanTypeId === 1 && <hr />} */}
              </Grid.Column>
            </Grid.Row>
            {isParentProgramActivity(currentActivity.activityPlanTypeId) && (
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={6}
                  className="outputs-col"
                >
                  <div className="outputs-col-div">
                    <div className="income-div">
                      <label>Reporting Date</label>
                      <div className="date-range-container">
                        <DateRangePicker
                          initialSettings={{
                            drops: 'auto',
                            autoApply: true,
                            singleDatePicker: true,
                            showDropdowns: true,
                            startDate: new Date(),
                            minYear: 2017,
                            maxYear: parseInt(moment().format('YYYY'), 10) + 10,
                          }}
                          onApply={this.handleReportYear}
                          value={forms.reportYearValue}
                        >
                          <div className="date-range-chart p-1">
                            <input
                              placeholder="DD-MM-YYYY"
                              type="text"
                              readOnly
                              value={forms.reportYearValue}
                              className="date-range-chart-date"
                            />
                            <Icon name="calendar alternate outline" />
                          </div>
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                  {/* {currentActivity.activityPlanTypeId === 1 && <hr />} */}
                </Grid.Column>
              </Grid.Row>
            )}

            {isSingleAndLinkedActivity(currentActivity.activityPlanTypeId) || // single + linked activities
            (currentActivity.statusId === 1 &&
              isParentProgramActivity(currentActivity.activityPlanTypeId)) ? (
              <>
                <Divider section></Divider>
                <Grid.Row className="outputs-row">
                  {this.renderInputs(actual, currentActivity)}
                </Grid.Row>
              </>
            ) : null}
          </Grid>
        </AvForm>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putActivity,
      postGroupSelected,
      getSelectedGroup,
      updateGroupSelected,
      deleteGroupSelected,
      updateProjectInputs,
    },
    dispatch
  );
};

ActivityOutput = connect(mapStateToProps, mapDispatchToProps)(ActivityOutput);

export { ActivityOutput };
